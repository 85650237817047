import { Injectable } from '@angular/core';
import { HttpRequest, HttpErrorResponse, } from '@angular/common/http';
import { BadRequestHandler, GlobalMessageService, GlobalMessageType, HttpResponseStatus } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class CustomBadRequestHandlerInterceptor extends BadRequestHandler {

  override responseStatus = HttpResponseStatus.BAD_REQUEST;

  constructor(globalMessageService: GlobalMessageService) {
    super(globalMessageService);
  }

  /**
 * Handles a bad password for a given HTTP request at login.
 * Overrides the original spartacus handleBadPassword to handle The
 * custom blocked user check response delivered by hybris extended
 * login controller.
 *
 * @param {HttpRequest<any>} HTTP request object.
 * @param {HttpErrorResponse} response of the HTTP request
 * @returns {void}
 */
  protected override handleBadPassword(request: HttpRequest<any>, response: HttpErrorResponse): void {
    if (
        response.error?.error === 'invalid_grant' &&
        request.body?.get('grant_type') === 'password'
    ) {
      let translationKey = '';

      switch (response.error.error_description) {
        case 'login.disabled.flag.message': //custom description error to identify the diabled login of the users BUnit.
          translationKey = 'httpHandlers.disabledLogin';
          break;
        default:
          translationKey = 'httpHandlers.badRequestPleaseLoginAgain';
          break;
      }

      this.globalMessageService.add(
        { key: translationKey },
        GlobalMessageType.MSG_TYPE_ERROR
      );
      this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_CONFIRMATION);
    }
  }
}
