import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KurzTermsAndConditionsComponent } from './kurz-terms-and-conditions/kurz-terms-and-conditions.component';
import { KurzTermsOfUseComponent } from './kurz-terms-of-use/kurz-terms-of-use.component';
import { KurzPrivacyPolicyComponent } from './kurz-privacy-policy/kurz-privacy-policy.component';
import { SharedKurzLegalDocumentModule } from './shared-kurz-legal-document/shared-kurz-legal-document.module';



@NgModule({
  declarations: [
    KurzTermsAndConditionsComponent,
    KurzTermsOfUseComponent,
    KurzPrivacyPolicyComponent
  ],
  imports: [
    CommonModule,
    SharedKurzLegalDocumentModule
  ],
  exports: [
    SharedKurzLegalDocumentModule
  ]
})
export class KurzLegalDocumentModule { }
