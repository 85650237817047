import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { getEndpointAndParamsFromUrl } from '@util/functions/strings';
import { KurzCartService } from '../services/kurz-cart.service';

const coolDownTime: number = 3000;

@Injectable({
  providedIn: 'root'
})
export class CartExistenceInterceptor implements HttpInterceptor {

  private static endpoint: string = 'users/current/carts';
  private isCoolingDown: boolean = false;

  constructor(
    private readonly kurzCartService: KurzCartService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(this.isRequestingCarts(request)) {
      return next.handle(request)
        .pipe(
          catchError(error => this.handleCartsRequestError(error)),
          tap(res => this.checkCartExistence(res)),
        );
    } else {
      return next.handle(request);
    }
  }

  private isRequestingCarts(request: HttpRequest<unknown>): boolean {
    const requestEndpoint = getEndpointAndParamsFromUrl(request.url).endpoint;
    return request.method === 'GET' && requestEndpoint.startsWith(CartExistenceInterceptor.endpoint);
  }

  private handleCartsRequestError(error: HttpErrorResponse) {
    console.error('Error while requesting cart', error?.error);
    this.ensureCartExistence();
    return throwError(error);
  }

  private checkCartExistence(res: any):void {
    if(res instanceof HttpResponse && res.body?.carts?.length === 0) {
      this.ensureCartExistence();
    }
  }

  private ensureCartExistence(): void {
    if(!this.isCoolingDown) {
      this.isCoolingDown = true;
      this.kurzCartService.testIfCartExistOrCreateNew();
      setTimeout(() => this.isCoolingDown = false, coolDownTime);
    }
  }

}


