import { NgModule } from '@angular/core';
import { provideConfig, RoutingConfig } from '@spartacus/core';
import { OnNavigateConfig } from '@spartacus/storefront';

@NgModule({
  providers: [
    provideConfig(<OnNavigateConfig>{
      enableResetViewOnNavigate: {
        active: true,
        ignoreQueryString: false
      }
    })
  ],
  exports: []
})
/**
 * Resets the View (with other words: it scrolls up) if the user navigates (router.navigate...()) only if more than just the query string ha updated
 * (for example: <app-tab-bar> will change the url if you switch to another tab but wont reset the view because it only changes the query parameter)
 */
export class CustomOnNavigateModule { }
