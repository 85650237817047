import { NgModule } from '@angular/core';
import { MetaTagConfigModule, provideConfig } from '@spartacus/core';


@NgModule({
  providers: [
    provideConfig({
      pageMeta: {
        resolvers: [
          {
            property: 'description',
            method: 'resolveDescription',
            disabledInCsr: false,
          },
          {
            property: 'title',
            method: 'resolveTitle',
            disabledInCsr: false,
          },
        ],
      }
    })
  ],
  exports: []
})
export class CustomMetaConfigModule extends MetaTagConfigModule { }
