import { NgModule } from '@angular/core';
import { OccConfig, provideConfig } from '@spartacus/core';
import { environment } from '../../../environments/environment';


@NgModule({
  declarations: [],
  imports: [
  ],
  providers: [
    provideConfig({
      context: {
        baseSite: [...environment.allowedBaseSites],
        urlParameters: ['baseSite', 'language', 'currency'],
        currency: ['EUR', 'USD', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF', 'CNY', 'SEK', 'NZD', 'MXN', 'SGD', 'HKD', 'NOK', 'KRW', 'TRY', 'RUB', 'INR', 'BRL', 'ZAR'],
      }
    } as OccConfig),
  ],
  exports: []
})
export class CustomContextModule { }
