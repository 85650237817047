import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { IconModule } from '@util/components/icon/icon.module';
import { KurzAddressModule } from '../../kurz-components/shared/kurz-address/kurz-address.module';
import { CustomFooterNavigationComponent } from './custom-footer-navigation.component';
import { CustomOverFooterInformationComponent } from './custom-over-footer-information/custom-over-footer-information.component';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';

@NgModule({
  declarations: [
    CustomFooterNavigationComponent,
    CustomOverFooterInformationComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    GenericLinkModule,
    KurzAddressModule,
    I18nModule,
    ActivateElementModule,
    // NavigationModule, // needed for <cx-navigation-ui>,
  ],
  exports: [
    CustomOverFooterInformationComponent
  ]
})
export class CustomFooterNavigationModule { }
