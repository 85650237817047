<div class="account-manager-menu-container">
  <ng-container *ngIf="injectedData.firstName && injectedData.lastName; else noManagerTemplate" >
    <p class="help-text">{{ 'helpMenu.helpText' | cxTranslate }}</p>
    <div class="account-manager-container">
      <ng-container
        [ngTemplateOutlet] = "injectedData.profilePicture ?  profilePictureTemplate : noProfilePictureTemplate"
        [ngTemplateOutletContext] = "{manager :injectedData}"
      >
      </ng-container>

      <div class="contact-information-container">
        <p>
          <strong>
            {{ injectedData!.firstName }} {{ injectedData!.lastName }}
          </strong>
        </p>
        <ul>
          <li *ngIf="injectedData.contactNumber">
            <app-icon
              [iconType]="'CALL'"
              iconSize="SMALL"
            ></app-icon>
            {{ injectedData.contactNumber }}
          </li>
          <li *ngIf="injectedData.email">
            <app-icon
              [iconType]="'ENVELOPE'"
              iconSize="SMALL"
            ></app-icon>
            <a [href]="'mailto:' + injectedData.email">{{ injectedData.email }}</a>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>

  <ng-template #profilePictureTemplate let-manager="manager">
    <img [src]="manager.profilePicture.url" alt="Manager profile picture" />
  </ng-template>

  <ng-template #noProfilePictureTemplate let-manager="manager">
    <div class="manager-initials-avatar">
      {{manager!.firstName[0] + manager!.lastName[0]}}
    </div>
  </ng-template>


  <ng-template #noManagerTemplate>
    {{ 'helpMenu.noAccountManagerHint' | cxTranslate }}
  </ng-template>
</div>
