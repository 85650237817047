import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

export interface KurzShopContextElements {
  baseSite: string;
  language: string;
  currencyIso: string;
}

const statusSuccess = 200;

@Injectable()
export class StatusCodeHttpInterceptor implements HttpInterceptor {

  static _behaviorSubjectsMap = new Map<number, BehaviorSubject<HttpEvent<any> | HttpErrorResponse>>();

  logRequests = false;
  logSuccessResponses = false;
  logErrors = false;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.logRequests) {
      console.log('%c', 'color:#d8bd00;font-size:1.2rem;font-weight:bold', 'StatusCodeHttpInterceptor request', req);
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse, caught) => {
        if (this.logErrors) {
          console.log('%c', 'color:red;font-size:1.2rem;font-weight:bold', 'StatusCodeHttpInterceptor error response', err);
        }
        const code = err?.status;
        if (code) {
          const sub = StatusCodeHttpInterceptor.get(code);
          sub.next(err);
        }
        return throwError(err);
      }),
      map((event: HttpEvent<any>) => {
        let clone: HttpEvent<any> = event;

        if (event?.type === HttpEventType.Response) {

          if (this.logSuccessResponses) {
            console.log('%c', 'color:green;font-size:1.2rem;font-weight:bold', 'StatusCodeHttpInterceptor success response', event);
          }

          const code = event?.status;
          if (code) {
            const sub = StatusCodeHttpInterceptor.get(code);
            sub.next(event);
          }

        }

        return clone;
      }
      ));
  }

  public static getRedirectHeaders(): Observable<KurzShopContextElements> {
    return StatusCodeHttpInterceptor.register(statusSuccess)
      .pipe(
        filter(response => this.needsRedirect(response)),
        map(response => this.getRedirectHeadersFromResponse(response)),
      );
  }

  private static needsRedirect(response: any): boolean {
    return response?.headers && response.headers.get('kurz_redirect');
  }

  private static getRedirectHeadersFromResponse(response: any):KurzShopContextElements {
    const base = response.headers.get('redirected_base_site');
    const lang = response.headers.get('redirected_language');
    const currency = response.headers.get('redirected_currency');
    return {baseSite: base, language: lang, currencyIso: currency};
  }

  static register(code: number): Observable<HttpEvent<any> | HttpErrorResponse> {
    let sub = StatusCodeHttpInterceptor.get(code);
    return sub.asObservable();
  }

  private static get(code: number): BehaviorSubject<HttpEvent<any> | HttpErrorResponse> {

    let sub = StatusCodeHttpInterceptor._behaviorSubjectsMap.get(code);

    if (!sub) {
      StatusCodeHttpInterceptor._behaviorSubjectsMap.set(code, new BehaviorSubject<any>(null));
      sub = StatusCodeHttpInterceptor._behaviorSubjectsMap.get(code);
    }

    return sub;
  }


}

