import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import * as i1$1 from '@angular/router';
import { RouterModule } from '@angular/router';
import * as i2$1 from '@spartacus/core';
import { provideDefaultConfig, Config, HttpErrorHandler, HttpResponseStatus, GlobalMessageType, provideDefaultConfigFactory } from '@spartacus/core';
import * as i2 from '@spartacus/storefront';
import { BREAKPOINT, PAGE_LAYOUT_HANDLER, HamburgerMenuModule, PageLayoutComponent, CmsPageGuard } from '@spartacus/storefront';
import * as i1 from '@spartacus/product-configurator/common';
import { ConfiguratorRouter, CommonConfiguratorModule } from '@spartacus/product-configurator/common';
import { take, map, switchMap, filter, distinctUntilChanged, expand, catchError, tap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import * as i1$2 from '@angular/common/http';
import { HttpErrorResponse, HttpResponse, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BehaviorSubject, timer, throwError } from 'rxjs';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class CpqConfiguratorPageLayoutHandler {
  static {
    this.templateName = 'CpqConfigurationTemplate';
  }
  static {
    this.sectionHeaderDisplayOnly = 'headerDisplayOnly';
  }
  static {
    this.sectionNavigationDisplayOnly = 'navigationDisplayOnly';
  }
  static {
    this.sectionHeader = 'header';
  }
  static {
    this.sectionNavigation = 'navigation';
  }
  constructor(configuratorRouterExtractorService, breakpointService, layoutConfig, commonConfiguratorUtilsService) {
    this.configuratorRouterExtractorService = configuratorRouterExtractorService;
    this.breakpointService = breakpointService;
    this.layoutConfig = layoutConfig;
    this.commonConfiguratorUtilsService = commonConfiguratorUtilsService;
  }
  handle(slots$, pageTemplate, section) {
    if (pageTemplate === CpqConfiguratorPageLayoutHandler.templateName && section === CpqConfiguratorPageLayoutHandler.sectionHeader) {
      this.compileRouterAndResolution().pipe(take(1)).subscribe(cont => {
        slots$ = slots$.pipe(map(slots => this.getHeaderSlots(slots, cont)));
      });
    } else if (pageTemplate === CpqConfiguratorPageLayoutHandler.templateName && section === CpqConfiguratorPageLayoutHandler.sectionNavigation) {
      this.compileRouterAndResolution().pipe(take(1)).subscribe(cont => {
        slots$ = slots$.pipe(map(slots => this.getNavigationSlots(slots, cont)));
      });
    }
    return slots$;
  }
  compileRouterAndResolution() {
    return this.configuratorRouterExtractorService.extractRouterData().pipe(switchMap(routerData => this.breakpointService.isUp(BREAKPOINT.lg).pipe(map(isLargeResolution => ({
      isLargeResolution: isLargeResolution,
      routerData
    })))));
  }
  getHeaderSlots(slots, cont) {
    if (cont.routerData.pageType === ConfiguratorRouter.PageType.CONFIGURATION) {
      const extendedSlots = ['PreHeader'];
      extendedSlots.push(...slots);
      return extendedSlots;
    } else if (cont.routerData.displayOnly) {
      if (cont.isLargeResolution) {
        return this.commonConfiguratorUtilsService.getSlotsFromLayoutConfiguration(this.layoutConfig, CpqConfiguratorPageLayoutHandler.templateName, CpqConfiguratorPageLayoutHandler.sectionHeaderDisplayOnly, BREAKPOINT.lg);
      } else {
        return this.commonConfiguratorUtilsService.getSlotsFromLayoutConfiguration(this.layoutConfig, CpqConfiguratorPageLayoutHandler.templateName, CpqConfiguratorPageLayoutHandler.sectionHeaderDisplayOnly, BREAKPOINT.xs);
      }
    } else {
      return slots;
    }
  }
  getNavigationSlots(slots, cont) {
    if (cont.routerData.pageType === ConfiguratorRouter.PageType.OVERVIEW && cont.routerData.displayOnly) {
      if (cont.isLargeResolution) {
        return this.commonConfiguratorUtilsService.getSlotsFromLayoutConfiguration(this.layoutConfig, CpqConfiguratorPageLayoutHandler.templateName, CpqConfiguratorPageLayoutHandler.sectionNavigationDisplayOnly, BREAKPOINT.lg);
      } else {
        return this.commonConfiguratorUtilsService.getSlotsFromLayoutConfiguration(this.layoutConfig, CpqConfiguratorPageLayoutHandler.templateName, CpqConfiguratorPageLayoutHandler.sectionNavigationDisplayOnly, BREAKPOINT.xs);
      }
    } else {
      return slots;
    }
  }
  static {
    this.ɵfac = function CpqConfiguratorPageLayoutHandler_Factory(t) {
      return new (t || CpqConfiguratorPageLayoutHandler)(i0.ɵɵinject(i1.ConfiguratorRouterExtractorService), i0.ɵɵinject(i2.BreakpointService), i0.ɵɵinject(i2.LayoutConfig), i0.ɵɵinject(i1.CommonConfiguratorUtilsService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CpqConfiguratorPageLayoutHandler,
      factory: CpqConfiguratorPageLayoutHandler.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CpqConfiguratorPageLayoutHandler, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ConfiguratorRouterExtractorService
  }, {
    type: i2.BreakpointService
  }, {
    type: i2.LayoutConfig
  }, {
    type: i1.CommonConfiguratorUtilsService
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 *  Contains the layout configuration for the CPQ configurator pages. This configuration is
 *  optional as of version 4.2, and reduces the components that are rendered in the header section.
 *  It needs to be explicitly imported, otherwise the default configuration
 *  from CpqConfiguratorInteractiveModule is active
 */
class CpqConfiguratorLayoutModule {
  static {
    this.ɵfac = function CpqConfiguratorLayoutModule_Factory(t) {
      return new (t || CpqConfiguratorLayoutModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CpqConfiguratorLayoutModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfig({
        layoutSlots: {
          CpqConfigurationTemplate: {
            header: {
              lg: {
                slots: ['SiteLogo', 'CpqConfigExitButton', 'MiniCart']
              },
              xs: {
                slots: ['SiteLogo', 'CpqConfigExitButton', 'MiniCart']
              }
            },
            headerDisplayOnly: {
              lg: {
                slots: ['SiteContext', 'SiteLinks', 'SiteLogo', 'SearchBox', 'SiteLogin', 'MiniCart', 'NavigationBar']
              },
              xs: {
                slots: ['PreHeader', 'SiteLogo', 'SearchBox', 'MiniCart']
              }
            },
            navigation: {
              lg: {
                slots: []
              },
              slots: ['CpqConfigMenu']
            },
            navigationDisplayOnly: {
              lg: {
                slots: []
              },
              xs: {
                slots: ['SiteLogin', 'NavigationBar', 'SiteContext', 'SiteLinks']
              }
            },
            lg: {
              slots: ['CpqConfigHeader', 'CpqConfigBanner', 'CpqConfigMenu', 'CpqConfigContent', 'CpqConfigOverviewBanner', 'CpqConfigOverviewContent', 'CpqConfigBottombar']
            },
            slots: ['CpqConfigHeader', 'CpqConfigBanner', 'CpqConfigContent', 'CpqConfigOverviewBanner', 'CpqConfigOverviewContent', 'CpqConfigBottombar']
          }
        }
      }), {
        provide: PAGE_LAYOUT_HANDLER,
        useExisting: CpqConfiguratorPageLayoutHandler,
        multi: true
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CpqConfiguratorLayoutModule, [{
    type: NgModule,
    args: [{
      providers: [provideDefaultConfig({
        layoutSlots: {
          CpqConfigurationTemplate: {
            header: {
              lg: {
                slots: ['SiteLogo', 'CpqConfigExitButton', 'MiniCart']
              },
              xs: {
                slots: ['SiteLogo', 'CpqConfigExitButton', 'MiniCart']
              }
            },
            headerDisplayOnly: {
              lg: {
                slots: ['SiteContext', 'SiteLinks', 'SiteLogo', 'SearchBox', 'SiteLogin', 'MiniCart', 'NavigationBar']
              },
              xs: {
                slots: ['PreHeader', 'SiteLogo', 'SearchBox', 'MiniCart']
              }
            },
            navigation: {
              lg: {
                slots: []
              },
              slots: ['CpqConfigMenu']
            },
            navigationDisplayOnly: {
              lg: {
                slots: []
              },
              xs: {
                slots: ['SiteLogin', 'NavigationBar', 'SiteContext', 'SiteLinks']
              }
            },
            lg: {
              slots: ['CpqConfigHeader', 'CpqConfigBanner', 'CpqConfigMenu', 'CpqConfigContent', 'CpqConfigOverviewBanner', 'CpqConfigOverviewContent', 'CpqConfigBottombar']
            },
            slots: ['CpqConfigHeader', 'CpqConfigBanner', 'CpqConfigContent', 'CpqConfigOverviewBanner', 'CpqConfigOverviewContent', 'CpqConfigBottombar']
          }
        }
      }), {
        provide: PAGE_LAYOUT_HANDLER,
        useExisting: CpqConfiguratorPageLayoutHandler,
        multi: true
      }]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const defaultCpqInteractiveRoutingConfig = {
  routing: {
    routes: {
      configureCLOUDCPQCONFIGURATOR: {
        paths: ['configure/cpq/:ownerType/entityKey/:entityKey']
      }
    }
  }
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Takes care of the interactive configuration process (the user enters new attribute values and navigates through the configuration).
 * Provides routing, assignment of ng components to CMS components and assignment of CMS components to the layout slots
 */
class CpqConfiguratorInteractiveModule {
  static {
    this.ɵfac = function CpqConfiguratorInteractiveModule_Factory(t) {
      return new (t || CpqConfiguratorInteractiveModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CpqConfiguratorInteractiveModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfig(defaultCpqInteractiveRoutingConfig)],
      imports: [RouterModule.forChild([{
        // We can neither omit the path nor set to undefined
        // @ts-ignore
        path: null,
        data: {
          cxRoute: 'configureCLOUDCPQCONFIGURATOR'
        },
        component: PageLayoutComponent,
        canActivate: [CmsPageGuard]
      }]), HamburgerMenuModule, CpqConfiguratorLayoutModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CpqConfiguratorInteractiveModule, [{
    type: NgModule,
    args: [{
      imports: [RouterModule.forChild([{
        // We can neither omit the path nor set to undefined
        // @ts-ignore
        path: null,
        data: {
          cxRoute: 'configureCLOUDCPQCONFIGURATOR'
        },
        component: PageLayoutComponent,
        canActivate: [CmsPageGuard]
      }]), HamburgerMenuModule, CpqConfiguratorLayoutModule],
      providers: [provideDefaultConfig(defaultCpqInteractiveRoutingConfig)]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const defaultCpqOverviewRoutingConfig = {
  routing: {
    routes: {
      configureOverviewCLOUDCPQCONFIGURATOR: {
        paths: ['configure-overview/cpq/:ownerType/entityKey/:entityKey/displayOnly/:displayOnly', 'configure-overview/cpq/:ownerType/entityKey/:entityKey']
      }
    }
  }
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Takes care of the configuration overview that visualizes the attribute value assignments that have been done already in a condensed, read-only form.
 * The end-user can switch between the interactive view and this overview.
 * Provides routing, assignment of ng components to CMS components and assignment of CMS components to the layout slots.
 * Some of the ng components on this view (tab bar, price summary and addToCart button) are shared between the interactive view and the overview.
 */
class CpqConfiguratorOverviewModule {
  static {
    this.ɵfac = function CpqConfiguratorOverviewModule_Factory(t) {
      return new (t || CpqConfiguratorOverviewModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CpqConfiguratorOverviewModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfig(defaultCpqOverviewRoutingConfig)],
      imports: [RouterModule.forChild([{
        // We can neither omit the path nor set to undefined
        // @ts-ignore
        path: null,
        component: PageLayoutComponent,
        data: {
          cxRoute: 'configureOverviewCLOUDCPQCONFIGURATOR'
        },
        canActivate: [CmsPageGuard]
      }]), CpqConfiguratorLayoutModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CpqConfiguratorOverviewModule, [{
    type: NgModule,
    args: [{
      imports: [RouterModule.forChild([{
        // We can neither omit the path nor set to undefined
        // @ts-ignore
        path: null,
        component: PageLayoutComponent,
        data: {
          cxRoute: 'configureOverviewCLOUDCPQCONFIGURATOR'
        },
        canActivate: [CmsPageGuard]
      }]), CpqConfiguratorLayoutModule],
      providers: [provideDefaultConfig(defaultCpqOverviewRoutingConfig)]
    }]
  }], null, null);
})();

/**
 * @deprecated since 2211.25. Not needed for commerce based CPQ orchestration (which is the default communication flavour).
 * Refer to configuration setting ConfiguratorCoreConfig.productConfigurator.cpqOverOcc = true.
 * The other flavour (performing direct calls from composable storefront to CPQ) is technically no longer supported.
 */
class CpqAccessLoaderService {
  constructor(http, occEndpointsService, userIdService) {
    this.http = http;
    this.occEndpointsService = occEndpointsService;
    this.userIdService = userIdService;
  }
  getCpqAccessData() {
    return this.userIdService.takeUserId(true).pipe(switchMap(userId => this.http.get(this.occEndpointsService.buildUrl('getCpqAccessData', {
      urlParams: {
        userId: userId
      }
    }))));
  }
  static {
    this.ɵfac = function CpqAccessLoaderService_Factory(t) {
      return new (t || CpqAccessLoaderService)(i0.ɵɵinject(i1$2.HttpClient), i0.ɵɵinject(i2$1.OccEndpointsService), i0.ɵɵinject(i2$1.UserIdService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CpqAccessLoaderService,
      factory: CpqAccessLoaderService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CpqAccessLoaderService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$2.HttpClient
  }, {
    type: i2$1.OccEndpointsService
  }, {
    type: i2$1.UserIdService
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class CpqConfiguratorAuthConfig {
  static {
    this.ɵfac = function CpqConfiguratorAuthConfig_Factory(t) {
      return new (t || CpqConfiguratorAuthConfig)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CpqConfiguratorAuthConfig,
      factory: function CpqConfiguratorAuthConfig_Factory(t) {
        let r = null;
        if (t) {
          r = new (t || CpqConfiguratorAuthConfig)();
        } else {
          r = i0.ɵɵinject(Config);
        }
        return r;
      },
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CpqConfiguratorAuthConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useExisting: Config
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated since 2211.25. Not needed for commerce based CPQ orchestration (which is the default communication flavour).
 * Refer to configuration setting ConfiguratorCoreConfig.productConfigurator.cpqOverOcc = true.
 * The other flavour (performing direct calls from composable storefront to CPQ) is technically no longer supported.
 */
class CpqAccessStorageService {
  constructor(cpqAccessLoaderService, authService, config) {
    this.cpqAccessLoaderService = cpqAccessLoaderService;
    this.authService = authService;
    this.config = config;
    this.EXPIRED_TOKEN = {
      accessToken: 'INVALID DUMMY',
      accessTokenExpirationTime: 0,
      endpoint: ''
    };
    this._cpqAccessDataError = false;
  }
  ngOnDestroy() {
    this.currentCpqAccessSubscription?.unsubscribe();
    this.currentAuthServiceSubscription?.unsubscribe();
    this._cpqAccessDataErrorSubscription?.unsubscribe();
  }
  getCpqAccessData() {
    if (!this.cpqAccessData$ || this._cpqAccessDataError) {
      this.initCpqAccessData();
    }
    return this.cpqAccessData$;
  }
  /**
   * Renews the current access data. All subscribers of getCachedCpqAccessData()
   * will receive the new data. Will only have an effect, if there are any subscribers
   * and the user is logged in.
   */
  renewCpqAccessData() {
    // only force token refresh if initialized.
    if (this.cpqAccessData$) {
      this.stopAutoFetchingCpqAccessData();
      this._cpqAccessData$.next(this.EXPIRED_TOKEN); // invalidate cache
      this.authService.isUserLoggedIn().pipe(take(1)) // get current login state
      .subscribe(loggedIn => {
        // only fetch new token if user is logged in.
        if (loggedIn) {
          this.startAutoFetchingCpqAccessData();
        }
      });
    }
  }
  initCpqAccessData() {
    this._cpqAccessData$ = new BehaviorSubject(this.EXPIRED_TOKEN);
    this._cpqAccessDataError = false;
    this._cpqAccessDataErrorSubscription?.unsubscribe();
    this._cpqAccessDataErrorSubscription = this._cpqAccessData$.subscribe({
      error: () => this._cpqAccessDataError = true
    });
    this.cpqAccessData$ = this._cpqAccessData$.pipe(
    // Never expose expired tokens - either cache was invalidated with expired token,
    // or the cached one expired before a new one was fetched.
    filter(data => !this.isTokenExpired(data)));
    this.currentAuthServiceSubscription?.unsubscribe(); // cancel subscriptions created for old
    this.currentAuthServiceSubscription = this.authService.isUserLoggedIn().pipe(distinctUntilChanged()) // only react if user login status changes
    .subscribe(loggedIn => {
      if (loggedIn) {
        // user logged in - start/stop to ensure token is refreshed
        this.stopAutoFetchingCpqAccessData();
        this.startAutoFetchingCpqAccessData();
      } else {
        // user logged out - cancel token fetching
        this.stopAutoFetchingCpqAccessData();
        this._cpqAccessData$.next(this.EXPIRED_TOKEN); // invalidate cache
      }
    });
  }
  stopAutoFetchingCpqAccessData() {
    this.currentCpqAccessSubscription?.unsubscribe();
  }
  startAutoFetchingCpqAccessData() {
    this.currentCpqAccessSubscription = this.cpqAccessLoaderService.getCpqAccessData().pipe(expand(data => timer(this.fetchNextTokenIn(data)).pipe(switchMap(() => this.cpqAccessLoaderService.getCpqAccessData())))).subscribe(this._cpqAccessData$); // also propagate errors
  }
  fetchNextTokenIn(data) {
    const authSettings = this.config.productConfigurator.cpq?.authentication;
    if (authSettings) {
      // we schedule a request to update our cache some time before expiration
      let fetchNextIn = data.accessTokenExpirationTime - Date.now() - authSettings.tokenExpirationBuffer;
      if (fetchNextIn < authSettings.tokenMinValidity) {
        fetchNextIn = authSettings.tokenMinValidity;
      } else if (fetchNextIn > authSettings.tokenMaxValidity) {
        fetchNextIn = authSettings.tokenMaxValidity;
      }
      return fetchNextIn;
    } else {
      throw new Error('CPQ authentication configuration not present');
    }
  }
  isTokenExpired(tokenData) {
    const authSettings = this.config.productConfigurator.cpq?.authentication;
    if (authSettings) {
      return Date.now() > tokenData.accessTokenExpirationTime - authSettings.tokenExpirationBuffer;
    } else {
      throw new Error('CPQ authentication configuration not present');
    }
  }
  static {
    this.ɵfac = function CpqAccessStorageService_Factory(t) {
      return new (t || CpqAccessStorageService)(i0.ɵɵinject(CpqAccessLoaderService), i0.ɵɵinject(i2$1.AuthService), i0.ɵɵinject(CpqConfiguratorAuthConfig));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CpqAccessStorageService,
      factory: CpqAccessStorageService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CpqAccessStorageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: CpqAccessLoaderService
  }, {
    type: i2$1.AuthService
  }, {
    type: CpqConfiguratorAuthConfig
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * This header attribute shall be used to mark any request made to the CPQ System.
 * The presence of it enables this interceptor to actually intercept
 * this request and to decorate it with the authentication related attributes.
 */
const MARKER_HEADER_CPQ_CONFIGURATOR = 'x-cpq-configurator';
/**
 * @deprecated since 2211.25. Not needed for commerce based CPQ orchestration (which is the default communication flavour).
 * Refer to configuration setting ConfiguratorCoreConfig.productConfigurator.cpqOverOcc = true.
 * The other flavour (performing direct calls from composable storefront to CPQ) is technically no longer supported.
 */
class CpqConfiguratorRestInterceptor {
  constructor(cpqAccessStorageService) {
    this.cpqAccessStorageService = cpqAccessStorageService;
    this.HEADER_ATTR_CPQ_SESSION_ID = 'x-cpq-session-id';
    this.HEADER_ATTR_CPQ_NO_COOKIES = 'x-cpq-disable-cookies';
    /**
     * Although CPQ API is stateless and can work without session id, it's recommended to always append the CPQ session id to any request.
     * It enables CPQ load balancer to redirect the request always to the same node, so that configuration related data is already in memory
     * and does not need to be reloaded from DB. This can have a significant impact on performance nd reduce load in the CPQ system.
     */
    this.cpqSessionId = null;
  }
  intercept(request, next) {
    if (!request.headers.has(MARKER_HEADER_CPQ_CONFIGURATOR)) {
      return next.handle(request);
    }
    return this.cpqAccessStorageService.getCpqAccessData().pipe(take(1),
    // avoid request being re-executed when token expires
    switchMap(cpqData => {
      return next.handle(this.enrichHeaders(request, cpqData)).pipe(catchError(errorResponse => {
        return this.handleError(errorResponse, next, request);
      }), tap(response => this.extractCpqSessionId(response)));
    }));
  }
  handleError(errorResponse, next, request) {
    if (errorResponse instanceof HttpErrorResponse) {
      if (errorResponse.status === 403) {
        this.cpqSessionId = null;
        this.cpqAccessStorageService.renewCpqAccessData();
        return this.cpqAccessStorageService.getCpqAccessData().pipe(take(1), switchMap(newCpqData => {
          return next.handle(this.enrichHeaders(request, newCpqData)).pipe(tap(response => this.extractCpqSessionId(response)));
        }));
      }
    }
    return throwError(() => errorResponse); //propagate error
  }
  extractCpqSessionId(response) {
    if (response instanceof HttpResponse || response instanceof HttpErrorResponse) {
      if (response.headers.has(this.HEADER_ATTR_CPQ_SESSION_ID)) {
        this.cpqSessionId = response.headers.get(this.HEADER_ATTR_CPQ_SESSION_ID);
      }
    }
  }
  enrichHeaders(request, cpqData) {
    let newRequest = request.clone({
      url: cpqData.endpoint + request.url,
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + cpqData.accessToken,
        [this.HEADER_ATTR_CPQ_NO_COOKIES]: 'true'
      })
    });
    if (this.cpqSessionId) {
      newRequest = newRequest.clone({
        setHeaders: {
          [this.HEADER_ATTR_CPQ_SESSION_ID]: this.cpqSessionId
        }
      });
    }
    return newRequest;
  }
  static {
    this.ɵfac = function CpqConfiguratorRestInterceptor_Factory(t) {
      return new (t || CpqConfiguratorRestInterceptor)(i0.ɵɵinject(CpqAccessStorageService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CpqConfiguratorRestInterceptor,
      factory: CpqConfiguratorRestInterceptor.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CpqConfiguratorRestInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: CpqAccessStorageService
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated since 2211.25. Not needed for commerce based CPQ orchestration (which is the default communication flavour).
 * Refer to configuration setting ConfiguratorCoreConfig.productConfigurator.cpqOverOcc = true.
 * The other flavour (performing direct calls from composable storefront to CPQ) is technically no longer supported.
 */
const defaultCpqConfiguratorAuthConfig = {
  productConfigurator: {
    cpq: {
      authentication: {
        tokenExpirationBuffer: 10000,
        tokenMaxValidity: 24 * 60 * 60 * 1000,
        tokenMinValidity: 5000 // five seconds
      }
    }
  }
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * @deprecated since 2211.25. Not needed for commerce based CPQ orchestration (which is the default communication flavour).
 * Refer to configuration setting ConfiguratorCoreConfig.productConfigurator.cpqOverOcc = true.
 * The other flavour (performing direct calls from composable storefront to CPQ) is technically no longer supported.
 */
class CpqConfiguratorInterceptorModule {
  static {
    this.ɵfac = function CpqConfiguratorInterceptorModule_Factory(t) {
      return new (t || CpqConfiguratorInterceptorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CpqConfiguratorInterceptorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: CpqConfiguratorRestInterceptor,
        multi: true
      }, provideDefaultConfig(defaultCpqConfiguratorAuthConfig)],
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CpqConfiguratorInterceptorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: CpqConfiguratorRestInterceptor,
        multi: true
      }, provideDefaultConfig(defaultCpqConfiguratorAuthConfig)]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Exposes the CPQ aspects that we need to load eagerly, like page mappings, routing
 * and interceptor related entities
 */
class CpqConfiguratorRootModule {
  static {
    this.ɵfac = function CpqConfiguratorRootModule_Factory(t) {
      return new (t || CpqConfiguratorRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CpqConfiguratorRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfig({
        routing: {
          protected: true
        }
      })],
      imports: [CpqConfiguratorInteractiveModule, CpqConfiguratorOverviewModule, CpqConfiguratorInterceptorModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CpqConfiguratorRootModule, [{
    type: NgModule,
    args: [{
      imports: [CpqConfiguratorInteractiveModule, CpqConfiguratorOverviewModule, CpqConfiguratorInterceptorModule],
      //force early login
      providers: [provideDefaultConfig({
        routing: {
          protected: true
        }
      })]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const PRODUCT_CONFIGURATOR_RULEBASED_FEATURE = 'productConfiguratorRulebased';
class ConfiguratorBadRequestHandler extends HttpErrorHandler {
  constructor(globalMessageService) {
    super(globalMessageService);
    this.globalMessageService = globalMessageService;
    this.responseStatus = HttpResponseStatus.BAD_REQUEST;
  }
  getPriority() {
    return 0 /* Priority.NORMAL */;
  }
  hasMatch(errorResponse) {
    return super.hasMatch(errorResponse) && this.isRelatedToProductConfigurator(errorResponse);
  }
  handleError(_request, response) {
    this.getIllegalStateErrorsRelatedToProductConfigurator(response).forEach(({
      message
    }) => {
      this.handleIllegalArgumentIssues(message);
    });
  }
  handleIllegalArgumentIssues(message) {
    if (this.isIllegalStateErrorRelatedToMakeToStock(message)) {
      this.globalMessageService.add({
        key: 'configurator.httpHandlers.makeToStockBaseProductIssue'
      }, GlobalMessageType.MSG_TYPE_ERROR);
    }
  }
  isNotEmpty(errors) {
    return errors?.length > 0;
  }
  isIllegalStateErrorRelatedToMakeToStock(message) {
    const discountMask = /base product is defined as 'make-to-stock'/;
    return message.match(discountMask) !== null;
  }
  isIllegalStateErrorRelatedToProductConfigurator(message) {
    return message && this.isIllegalStateErrorRelatedToMakeToStock(message);
  }
  getIllegalStateErrorsRelatedToProductConfigurator(response) {
    return (response?.error?.errors ?? []).filter(error => error.type === 'IllegalStateError').filter(error => this.isIllegalStateErrorRelatedToProductConfigurator(error.message));
  }
  isRelatedToProductConfigurator(response) {
    return this.isNotEmpty(this.getIllegalStateErrorsRelatedToProductConfigurator(response));
  }
  static {
    this.ɵfac = function ConfiguratorBadRequestHandler_Factory(t) {
      return new (t || ConfiguratorBadRequestHandler)(i0.ɵɵinject(i2$1.GlobalMessageService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ConfiguratorBadRequestHandler,
      factory: ConfiguratorBadRequestHandler.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConfiguratorBadRequestHandler, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i2$1.GlobalMessageService
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const cmsComponents = ['ConfiguratorForm', 'ConfiguratorOverviewForm', 'ConfiguratorOverviewMenu', 'ConfiguratorUpdateMessage', 'ConfiguratorAddToCartButton', 'ConfiguratorMenu', 'ConfiguratorGroupTitle', 'ConfiguratorOverviewBanner', 'ConfiguratorPrevNext', 'ConfiguratorPriceSummary', 'ConfiguratorProductTitle', 'ConfiguratorTabBar', 'ConfiguratorExitButton', 'ConfiguratorVariantCarousel', 'CpqConfiguratorConflictAndErrorMessagesComponent', 'ConfiguratorOverviewFilterButton', 'ConfiguratorOverviewFilter', 'ConfiguratorOverviewSidebar'];
// TODO: Inline this factory when we start releasing Ivy compiled libraries
function defaultProductConfiguratorRulebasedComponentsConfig() {
  const config = {
    featureModules: {
      [PRODUCT_CONFIGURATOR_RULEBASED_FEATURE]: {
        cmsComponents
      }
    }
  };
  return config;
}
/**
 * Contains feature module configuration
 */
class RulebasedConfiguratorRootFeatureModule {
  static {
    this.ɵfac = function RulebasedConfiguratorRootFeatureModule_Factory(t) {
      return new (t || RulebasedConfiguratorRootFeatureModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: RulebasedConfiguratorRootFeatureModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfigFactory(defaultProductConfiguratorRulebasedComponentsConfig)]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RulebasedConfiguratorRootFeatureModule, [{
    type: NgModule,
    args: [{
      imports: [],
      providers: [provideDefaultConfigFactory(defaultProductConfiguratorRulebasedComponentsConfig)]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const defaultRulebasedRoutingConfig = {
  routing: {
    routes: {
      configureCPQCONFIGURATOR: {
        paths: ['configure/vc/:ownerType/entityKey/:entityKey']
      },
      configureCPQCONFIGURATOR_READ_ONLY: {
        paths: ['configure-overview/vc/:ownerType/entityKey/:entityKey/displayOnly/true']
      },
      configureOverviewCPQCONFIGURATOR: {
        paths: ['configure-overview/vc/:ownerType/entityKey/:entityKey/displayOnly/:displayOnly', 'configure-overview/vc/:ownerType/entityKey/:entityKey']
      }
    }
  }
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Provides the default cx routing configuration for the rulebased configurator
 */
class RulebasedConfiguratorRoutingModule {
  static forRoot() {
    return {
      ngModule: RulebasedConfiguratorRoutingModule,
      providers: [provideDefaultConfig(defaultRulebasedRoutingConfig)]
    };
  }
  static {
    this.ɵfac = function RulebasedConfiguratorRoutingModule_Factory(t) {
      return new (t || RulebasedConfiguratorRoutingModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: RulebasedConfiguratorRoutingModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RulebasedConfiguratorRoutingModule, [{
    type: NgModule,
    args: [{}]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 *  Contains the layout configuration for the interactive configuration page. This configuration is
 *  optional as of version 4.2, and reduces the components that are rendered in the header section.
 *  It needs to be explicitly imported, otherwise the default configuration
 *  from VariantConfiguratorInteractiveModule is active
 */
class VariantConfiguratorInteractiveLayoutModule {
  static {
    this.ɵfac = function VariantConfiguratorInteractiveLayoutModule_Factory(t) {
      return new (t || VariantConfiguratorInteractiveLayoutModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: VariantConfiguratorInteractiveLayoutModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfig({
        layoutSlots: {
          VariantConfigurationTemplate: {
            header: {
              lg: {
                slots: ['PreHeader', 'SiteLogo', 'VariantConfigExitButton', 'MiniCart']
              },
              xs: {
                slots: ['PreHeader', 'SiteLogo', 'VariantConfigExitButton', 'MiniCart']
              }
            },
            navigation: {
              lg: {
                slots: []
              },
              slots: ['VariantConfigMenu']
            },
            lg: {
              slots: ['VariantConfigHeader', 'VariantConfigMenu', 'VariantConfigContent', 'VariantConfigBottombar', 'VariantConfigVariantCarousel']
            },
            slots: ['VariantConfigHeader', 'VariantConfigContent', 'VariantConfigBottombar', 'VariantConfigVariantCarousel']
          }
        }
      })]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VariantConfiguratorInteractiveLayoutModule, [{
    type: NgModule,
    args: [{
      providers: [provideDefaultConfig({
        layoutSlots: {
          VariantConfigurationTemplate: {
            header: {
              lg: {
                slots: ['PreHeader', 'SiteLogo', 'VariantConfigExitButton', 'MiniCart']
              },
              xs: {
                slots: ['PreHeader', 'SiteLogo', 'VariantConfigExitButton', 'MiniCart']
              }
            },
            navigation: {
              lg: {
                slots: []
              },
              slots: ['VariantConfigMenu']
            },
            lg: {
              slots: ['VariantConfigHeader', 'VariantConfigMenu', 'VariantConfigContent', 'VariantConfigBottombar', 'VariantConfigVariantCarousel']
            },
            slots: ['VariantConfigHeader', 'VariantConfigContent', 'VariantConfigBottombar', 'VariantConfigVariantCarousel']
          }
        }
      })]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Takes care of the interactive configuration process (the user enters new attribute values and navigates through the configuration).
 * Provides routing, assignment of ng components to CMS components and assignment of CMS components to the layout slots
 */
class VariantConfiguratorInteractiveModule {
  static {
    this.ɵfac = function VariantConfiguratorInteractiveModule_Factory(t) {
      return new (t || VariantConfiguratorInteractiveModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: VariantConfiguratorInteractiveModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [RouterModule.forChild([{
        // We can neither omit the path nor set to undefined
        // @ts-ignore
        path: null,
        data: {
          cxRoute: 'configureCPQCONFIGURATOR'
        },
        component: PageLayoutComponent,
        canActivate: [CmsPageGuard]
      }]), HamburgerMenuModule, VariantConfiguratorInteractiveLayoutModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VariantConfiguratorInteractiveModule, [{
    type: NgModule,
    args: [{
      imports: [RouterModule.forChild([{
        // We can neither omit the path nor set to undefined
        // @ts-ignore
        path: null,
        data: {
          cxRoute: 'configureCPQCONFIGURATOR'
        },
        component: PageLayoutComponent,
        canActivate: [CmsPageGuard]
      }]), HamburgerMenuModule, VariantConfiguratorInteractiveLayoutModule]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class VariantConfiguratorPageLayoutHandler {
  static {
    this.templateName = 'VariantConfigurationOverviewTemplate';
  }
  static {
    this.sectionDisplayOnlyName = 'headerDisplayOnly';
  }
  constructor(configuratorRouterExtractorService, breakpointService, layoutConfig, commonConfiguratorUtilsService) {
    this.configuratorRouterExtractorService = configuratorRouterExtractorService;
    this.breakpointService = breakpointService;
    this.layoutConfig = layoutConfig;
    this.commonConfiguratorUtilsService = commonConfiguratorUtilsService;
  }
  handle(slots$, pageTemplate, section) {
    if (pageTemplate === VariantConfiguratorPageLayoutHandler.templateName && section === 'header') {
      this.configuratorRouterExtractorService.extractRouterData().pipe(take(1)).subscribe(routerData => {
        if (routerData.displayOnly) {
          slots$ = slots$.pipe(switchMap(() => this.breakpointService.isUp(BREAKPOINT.lg)), map(isLargeResolution => {
            if (isLargeResolution) {
              return this.commonConfiguratorUtilsService.getSlotsFromLayoutConfiguration(this.layoutConfig, VariantConfiguratorPageLayoutHandler.templateName, VariantConfiguratorPageLayoutHandler.sectionDisplayOnlyName, BREAKPOINT.lg);
            } else {
              return this.commonConfiguratorUtilsService.getSlotsFromLayoutConfiguration(this.layoutConfig, VariantConfiguratorPageLayoutHandler.templateName, VariantConfiguratorPageLayoutHandler.sectionDisplayOnlyName, BREAKPOINT.xs);
            }
          }));
        }
      });
    }
    return slots$;
  }
  static {
    this.ɵfac = function VariantConfiguratorPageLayoutHandler_Factory(t) {
      return new (t || VariantConfiguratorPageLayoutHandler)(i0.ɵɵinject(i1.ConfiguratorRouterExtractorService), i0.ɵɵinject(i2.BreakpointService), i0.ɵɵinject(i2.LayoutConfig), i0.ɵɵinject(i1.CommonConfiguratorUtilsService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: VariantConfiguratorPageLayoutHandler,
      factory: VariantConfiguratorPageLayoutHandler.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VariantConfiguratorPageLayoutHandler, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ConfiguratorRouterExtractorService
  }, {
    type: i2.BreakpointService
  }, {
    type: i2.LayoutConfig
  }, {
    type: i1.CommonConfiguratorUtilsService
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 *  Contains the layout configuration for the overview configuration page. This configuration is
 *  optional as of version 4.2, and reduces the components that are rendered in the header section.
 *  It needs to be explicitly imported, otherwise the default configuration
 *  from VariantConfiguratorOverviewModule is active
 */
class VariantConfiguratorOverviewLayoutModule {
  static {
    this.ɵfac = function VariantConfiguratorOverviewLayoutModule_Factory(t) {
      return new (t || VariantConfiguratorOverviewLayoutModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: VariantConfiguratorOverviewLayoutModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfig({
        layoutSlots: {
          VariantConfigurationOverviewTemplate: {
            header: {
              slots: ['SiteLogo', 'VariantConfigOverviewExitButton', 'MiniCart']
            },
            headerDisplayOnly: {
              lg: {
                slots: ['SiteContext', 'SiteLinks', 'SiteLogo', 'SearchBox', 'SiteLogin', 'MiniCart', 'NavigationBar']
              },
              xs: {
                slots: ['PreHeader', 'SiteLogo', 'SearchBox', 'MiniCart']
              }
            },
            lg: {
              slots: ['VariantConfigOverviewHeader', 'VariantConfigOverviewBanner', 'VariantConfigOverviewNavigation', 'VariantConfigOverviewContent', 'VariantConfigOverviewBottombar']
            },
            slots: ['VariantConfigOverviewHeader', 'VariantConfigOverviewBanner', 'VariantConfigOverviewFilterButton', 'VariantConfigOverviewContent', 'VariantConfigOverviewBottombar']
          }
        }
      }), {
        provide: PAGE_LAYOUT_HANDLER,
        useExisting: VariantConfiguratorPageLayoutHandler,
        multi: true
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VariantConfiguratorOverviewLayoutModule, [{
    type: NgModule,
    args: [{
      providers: [provideDefaultConfig({
        layoutSlots: {
          VariantConfigurationOverviewTemplate: {
            header: {
              slots: ['SiteLogo', 'VariantConfigOverviewExitButton', 'MiniCart']
            },
            headerDisplayOnly: {
              lg: {
                slots: ['SiteContext', 'SiteLinks', 'SiteLogo', 'SearchBox', 'SiteLogin', 'MiniCart', 'NavigationBar']
              },
              xs: {
                slots: ['PreHeader', 'SiteLogo', 'SearchBox', 'MiniCart']
              }
            },
            lg: {
              slots: ['VariantConfigOverviewHeader', 'VariantConfigOverviewBanner', 'VariantConfigOverviewNavigation', 'VariantConfigOverviewContent', 'VariantConfigOverviewBottombar']
            },
            slots: ['VariantConfigOverviewHeader', 'VariantConfigOverviewBanner', 'VariantConfigOverviewFilterButton', 'VariantConfigOverviewContent', 'VariantConfigOverviewBottombar']
          }
        }
      }), {
        provide: PAGE_LAYOUT_HANDLER,
        useExisting: VariantConfiguratorPageLayoutHandler,
        multi: true
      }]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Takes care of the configuration overview that visualizes the attribute value assignments that have been done already in a condensed, read-only form.
 * The end-user can switch between the interactive view and this overview.
 * Provides routing, assignment of ng components to CMS components and assignment of CMS components to the layout slots.
 * Some of the ng components on this view (tab bar, price summary and addToCart button) are shared between the interactive view and the overview.
 */
class VariantConfiguratorOverviewModule {
  static {
    this.ɵfac = function VariantConfiguratorOverviewModule_Factory(t) {
      return new (t || VariantConfiguratorOverviewModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: VariantConfiguratorOverviewModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [RouterModule.forChild([{
        // We can neither omit the path nor set to undefined
        // @ts-ignore
        path: null,
        component: PageLayoutComponent,
        data: {
          cxRoute: 'configureOverviewCPQCONFIGURATOR'
        },
        canActivate: [CmsPageGuard]
      }]), VariantConfiguratorOverviewLayoutModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VariantConfiguratorOverviewModule, [{
    type: NgModule,
    args: [{
      imports: [RouterModule.forChild([{
        // We can neither omit the path nor set to undefined
        // @ts-ignore
        path: null,
        component: PageLayoutComponent,
        data: {
          cxRoute: 'configureOverviewCPQCONFIGURATOR'
        },
        canActivate: [CmsPageGuard]
      }]), VariantConfiguratorOverviewLayoutModule]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Exposes the root modules that we need to load statically. Contains page mappings, route configurations
 * and feature configuration
 */
class RulebasedConfiguratorRootModule {
  static forRoot() {
    return {
      ngModule: RulebasedConfiguratorRootModule
    };
  }
  static {
    this.ɵfac = function RulebasedConfiguratorRootModule_Factory(t) {
      return new (t || RulebasedConfiguratorRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: RulebasedConfiguratorRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: HttpErrorHandler,
        useExisting: ConfiguratorBadRequestHandler,
        multi: true
      }],
      imports: [CommonModule, CommonConfiguratorModule, RulebasedConfiguratorRootFeatureModule, VariantConfiguratorInteractiveModule, VariantConfiguratorOverviewModule, RulebasedConfiguratorRoutingModule.forRoot()]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RulebasedConfiguratorRootModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, CommonConfiguratorModule, RulebasedConfiguratorRootFeatureModule, VariantConfiguratorInteractiveModule, VariantConfiguratorOverviewModule, RulebasedConfiguratorRoutingModule.forRoot()],
      providers: [{
        provide: HttpErrorHandler,
        useExisting: ConfiguratorBadRequestHandler,
        multi: true
      }]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ConfiguratorBadRequestHandler, CpqAccessLoaderService, CpqAccessStorageService, CpqConfiguratorAuthConfig, CpqConfiguratorInteractiveModule, CpqConfiguratorInterceptorModule, CpqConfiguratorLayoutModule, CpqConfiguratorOverviewModule, CpqConfiguratorRestInterceptor, CpqConfiguratorRootModule, MARKER_HEADER_CPQ_CONFIGURATOR, PRODUCT_CONFIGURATOR_RULEBASED_FEATURE, RulebasedConfiguratorRootFeatureModule, RulebasedConfiguratorRootModule, RulebasedConfiguratorRoutingModule, VariantConfiguratorInteractiveLayoutModule, VariantConfiguratorInteractiveModule, VariantConfiguratorOverviewLayoutModule, VariantConfiguratorOverviewModule, VariantConfiguratorPageLayoutHandler, defaultProductConfiguratorRulebasedComponentsConfig };
