import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { ButtonModule } from '@util/components/button/button.module';
import { IconButtonModule } from '@util/components/icon-button/icon-button.module';
import { IconModule } from '@util/components/icon/icon.module';
import { MenuModule } from '@util/components/menu/menu.module';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';
import { CustomLanguageSelectorModule } from '../custom-language-selector/custom-language-selector.module';
import { CustomNavigationComponent } from './custom-navigation.component';
import { ServicesCustomNavigationInsertComponent } from './menu-inserts/services/services-custom-navigation-insert.component';
import { UserFeaturesCustomNavigationInsertComponent } from './menu-inserts/user-features/user-features-custom-navigation-insert.component';
import { UserCustomNavigationInsertComponent } from './menu-inserts/user/user-custom-navigation-insert.component';
import { GenericLinkModule } from '@spartacus/storefront';


@NgModule({
  declarations: [
    CustomNavigationComponent,
    UserCustomNavigationInsertComponent,
    ServicesCustomNavigationInsertComponent,
    UserFeaturesCustomNavigationInsertComponent
  ],
  imports: [
    CommonModule,
    IconButtonModule,
    ButtonModule,
    IconModule,
    MenuModule,
    I18nModule,
    ActivateElementModule,
    GenericLinkModule,
    CustomLanguageSelectorModule
  ],
  exports: [
    CustomNavigationComponent
  ]
})
export class CustomNavigationModule { }
