var bulkPricingTable = {
	quantity: "Quantity",
	price: "Price",
	discount: "Discount"
};
var bulkPricing = {
	bulkPricingTable: bulkPricingTable
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const en = {
    bulkPricing,
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const bulkPricingTranslations = {
    en,
};
// expose all translation chunk mapping for bulkPricing feature
const bulkPricingTranslationChunksConfig = {
    bulkPricing: ['bulkPricingTable'],
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { bulkPricingTranslationChunksConfig, bulkPricingTranslations };

