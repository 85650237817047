import { AfterViewInit, Component, ElementRef, OnDestroy, inject } from '@angular/core';
import { UtilCustomCSSPropertyService } from '@util/services/util-custom-css-property.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { extractDiscountsAndSurchargesFromCart } from 'src/app/custom/functions/kurz-cart-entry.functions';
import { KurzCart, KurzDetailedCart } from 'src/app/custom/kurz-components/shared/types/kurz-cart.interface';
import { KurzCartService } from 'src/app/custom/services/kurz-cart.service';

@Component({
  selector: 'app-custom-mini-cart-overview',
  templateUrl: './custom-mini-cart-overview.component.html',
  styleUrls: ['./custom-mini-cart-overview.component.scss']
})
export class CustomMiniCartOverviewComponent implements AfterViewInit, OnDestroy {

  protected kurzCartService = inject(KurzCartService);
  private utilCustomCSSPropertyService = inject(UtilCustomCSSPropertyService);
  private elementRef = inject(ElementRef);

  priceDetailsKnown$ = new BehaviorSubject<boolean>(false);

  activeCart$: Observable<KurzCart> = this.kurzCartService.getActiveCart()
  .pipe(
    tap(cart => {
      const obj = extractDiscountsAndSurchargesFromCart(cart as KurzDetailedCart);
      this.priceDetailsKnown$.next((obj.kurzDiscounts.length > 0 || obj.kurzSurcharges.length > 0));
    })
  );

  private _mutationObserver: MutationObserver;

  ngAfterViewInit(): void {

    const host = this.elementRef.nativeElement as HTMLElement;

    this._mutationObserver = new MutationObserver(_ => {
      const topBar = host?.querySelector('.overview-top-bar');
      const topBarHeight = topBar?.getBoundingClientRect().height || 100;
      this.utilCustomCSSPropertyService.setValue('--_topBarHeight', topBarHeight + 'px', host);
    });

    this._mutationObserver.observe(host, {
      subtree: true,
      childList: true
    });

  }

  ngOnDestroy() {
    this._mutationObserver.disconnect();
  }

}
