import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from '@spartacus/storefront';
import { AppComponent } from './app.component';
import { SpartacusModule } from './spartacus/spartacus.module';
import { CustomModule } from './custom/custom.module';
import { KurzOmnipresentContactButtonModule } from './custom/kurz-components/shared/kurz-omnipresent-contact-button/kurz-omnipresent-contact-button.module';
import { StatusCodeHttpInterceptorModule } from '@util/iterceptors/status-code-http-interceptor/status-code-http-interceptor.module';
import { ProductSearchHttpInterceptorModule } from './custom/kurz-components/kurz-product-list/product-search-http-interceptor/product-search-http.interceptor.module';
import { DevModule } from './dev/dev.module';
import { ActiveCartService } from '@spartacus/cart/base/core';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    EffectsModule.forRoot([]),
    SpartacusModule,
    //
    CustomModule,
    KurzOmnipresentContactButtonModule,
    StatusCodeHttpInterceptorModule,
    ProductSearchHttpInterceptorModule,
    // TODO: remove this before project is finished
    DevModule
  ],
  providers: [
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    Meta,
    ActiveCartService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
