

<div style="margin-top: 2ch; width: clamp(400px, calc(95vw - 20px), 1000px);">

  <div [dntest.f1]="'world'" dntest.f2="'and beyond'" dntest="Hello">
    <p>SUBPAGES LINKS</p>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/button'">BUTTONS</cx-generic-link>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/custom-tree'">CUSTOM TREE - PROJECT FOLDER STRUCTURE</cx-generic-link>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/config-belt'">CONFIG BELT EXAMPLE</cx-generic-link>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/date-picker'">DATE PICKER</cx-generic-link>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/dropdown'">DROPDOWN EXAMPLE</cx-generic-link>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/differently-styled-value'">DIFFERENTLY STYLED VALUES EXAMPLES</cx-generic-link>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/grid'">GRID EXAMPLE</cx-generic-link>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/menu'">MENU EXAMPLE</cx-generic-link>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/modal'">MODAL EXAMPLE</cx-generic-link>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/omnipresent-form-group'">OMNIPRESENT FORM GROUP EXAMPLE</cx-generic-link>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/price-calc'">Price Calc</cx-generic-link>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/tab-bar'">TAB-BAR</cx-generic-link>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/toasts'">TOASTS & GALLERY (COMPLETE SCREEN)</cx-generic-link>
  </div>
  <div>
    <cx-generic-link [url]="'/dev/upload-files'">UPLOAD FILES</cx-generic-link>
  </div>

</div>

<div class="all-icons" style="margin-top: 1ch; width: 800px; display:flex; flex-wrap: wrap; gap:5px;">
  <app-kurz-country-autocomplete
    (select)="selectCountry($event, 'Pos 1')"
    formGroupName="mock"
    required
  ></app-kurz-country-autocomplete>

  @if (isMockInvalid$ | async) {
    <div>is required !!!</div>
  }

</div>

<div class="all-icons" style="margin-top: 1ch; width: 800px; display:flex; flex-wrap: wrap; gap:5px;">
  <ng-container *ngFor="let type of kurzIconTypeArray">
    <app-icon
      [iconType]="type"
      iconSize="EXTRA_LARGE"
      [kurzTooltip]="type"
    ></app-icon>
  </ng-container>
</div>

<div style="margin-top: 2ch; width: 500px; display: flex; gap: 1ch;">

  <app-input
    label="Testbox"
    cancelBrowserAutocomplete
    [(value)]="inputText"
    [iconType]="'custom'"
    customIconType="SEARCH"
    (custom)="customIconAction($event)"
    [colorTheme]="['SECONDARY']"
  ></app-input>

  <app-input
    label="Testbox 2"
    [(value)]="inputText"
    [renderingData]="inputRenderingData"
  ></app-input>

</div>


<div style="margin-top: 1ch;">
  <app-checkbox-group #whichTest="app-checkbox-group" [dataSource]="rad" ></app-checkbox-group>
  <app-input label="Test Number" [value]="whichTest.value" [textonly]="true"></app-input>
  <app-checkbox [label]="'InputValueDebouncer Boolean'" [(checked)]="booleanDebouncer.bindableValue"></app-checkbox>
</div>

<div style="margin-top: 2ch; width: clamp(400px, calc(95vw - 20px), 1000px);">
  <app-panel
    title="Hello World"
    toggleButtonPosition="left"
    [customPanelPartObject]="panelObject"
  >
    <p>Some Content</p>
    <div style="margin-top: 2ch; display: flex; flex-direction: column; gap: 2ch;">

      <app-icon-button
        [iconType]="KurzIconType.CART"
        [size]="KurzIconSize.EXTRA_SMALL"
        [superscript]="messageText"
        [colorTheme]="['NONE', 'DANGER']"
      ></app-icon-button>

      <app-icon-button
        [iconType]="KurzIconType.BRUSH"
        [size]="KurzIconSize.SMALL"
        [superscript]="messageText"
        [colorTheme]="['PRIMARY', 'SUCCESS']"
      ></app-icon-button>

      <app-icon-button
        [iconType]="KurzIconType.MY_DETAILS"
        [colorTheme]="['SECONDARY', 'BASE']"
        [size]="KurzIconSize.MEDIUM"
        superscript="left"
        (activateElement)="toggleLeft()"
      ></app-icon-button>

      <app-icon-button
        [iconType]="KurzIconType.CURRENCY"
        [colorTheme]="['BASE', 'PRIMARY']"
        [size]="KurzIconSize.LARGE"
        superscript="right"
        (activateElement)="toggleRight()"
      ></app-icon-button>

      <app-icon-button
        [iconType]="KurzIconType.PDF"
        [size]="KurzIconSize.EXTRA_LARGE"
        [superscript]="messageText"
        [colorTheme]="['BASE_LIGHT', 'ACCENT']"
      ></app-icon-button>

    </div>
  </app-panel>
</div>

<div style="margin-top: 2ch; width: clamp(400px, calc(95vw - 20px), 800px);">

  <app-textarea
    label="Message Text"
    [(value)]="messageText"
  ></app-textarea>
</div>

<div style="font-size: 32px; margin-top: 2ch; width: clamp(400px, calc(95vw - 20px), 1000px);">
  <app-checkbox
    (checkedChange)="makeVacation($event)"
    [label]="'Want to make a vacation?'"
    [displayAsRadioButton]="false"
    appCheckedValidator
    formGroupName="checkboxTest"
    name="vacation"
  ></app-checkbox>
</div>

<div *ifFormGroupValid="'checkboxTest'">
  Vacation - yaaaaay
</div>

<div style="margin-top: 2ch; width: clamp(800px, calc(95vw - 20px), 1200px); display: grid; grid-template-columns: max-content; gap: 1ch;">

  <div>
    <app-input label="Filter" (debounce)="filterCheckboxCustomTreeDataSource($event)"></app-input>
  </div>
  <app-custom-tree [dataSource]="checkboxCustomTreeDataSource"></app-custom-tree>

</div>

<div style="margin-top: 2ch; width: clamp(50px, calc(95vw - 20px), 200px);">
  <ng-template let-text #infoBlock>
    <p>{{text}}</p>
    <i><b>- Info-Generator</b></i>
  </ng-template>
  <app-icon
    iconType="INFO"
    iconSize="EXTRA_LARGE"
    [colorTheme]="['DANGER', 'SUCCESS']"
    kurzTooltip="Das ist eine dringende Info"
    [kurzTooltipTemplate]="infoBlock"
    [kurzTooltipPosition]="['top', 'top-right', 'right']"
    [superscript]="messageText"
  ></app-icon>
</div>

<div style="margin-top: 2ch; width: clamp(400px, calc(95vw - 20px), 600px);">

  <app-adaptive-template-server [layout]="adaptiveTemplateLayout">
    <ng-template adaptiveTemplateRef="albert"><p>Albert</p></ng-template>
    <ng-template adaptiveTemplateRef="berta"><p>Berta</p></ng-template>
    <ng-template adaptiveTemplateRef="message">
      <div>
        <p>
          <app-input label="Message Text Copy" [value]="messageText" [textonly]="true"></app-input>
        </p>
      </div>

    </ng-template>
  </app-adaptive-template-server>

</div>

