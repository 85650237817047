
@if (loggedIn$ | async) {

  <app-adaptive-template-server [layout]="adaptiveTemplateLayout">

    <ng-template adaptiveTemplateRef="iconButton">
      <app-icon-button
        iconType="CART"
        [colorTheme]="['NONE','PRIMARY']"
        size="MEDIUM"
        (activateElement)="goToCart()"
        (mouseenter)="mouseEnter($event)"
        [superscript]="(totalItems$ | async) > 0 ? (totalItems$ | async) : ''"
        [disabled]="(onCartPage$ | async) || (onCheckoutReviewOrderPage$ | async)"
      ></app-icon-button>
    </ng-template>

    <ng-template adaptiveTemplateRef="labelButton">
      <app-button
        [label]="'cart.mini.' + (labelKey | async) | cxTranslate"
        buttonSize="SMALL"
        iconType="CART"
        (activateElement)="goToCart()"
        (mouseenter)="mouseEnter($event)"
        [colorTheme]="['ACCENT', 'BASE']"
        [superscript]="(totalItems$ | async) > 0 ? (totalItems$ | async) : ''"
        [disabled]="(onCartPage$ | async) || (onCheckoutReviewOrderPage$ | async)"
      ></app-button>
    </ng-template>

  </app-adaptive-template-server>

  <div [ngClass]="['mini-cart-overview-backdrop', (open$ | async) ? 'open' : 'closed']">

    <div [ngClass]="['mini-cart-overview-container', (open$ | async) ? 'open' : 'closed']">

      <app-sliding-content [expanded]="open$ | async" block keepRendering>
        <div [ngClass]="['mini-cart-overview', (open$ | async) ? 'open' : 'closed']">
          <app-custom-mini-cart-overview></app-custom-mini-cart-overview>
        </div>
      </app-sliding-content>

    </div>

  </div>

} @else {

  <app-adaptive-template-server [layout]="adaptiveTemplateLayout">

    <ng-template adaptiveTemplateRef="iconButton">
      <app-icon-button
        iconType="LOGIN"
        [colorTheme]="['NONE','PRIMARY']"
        size="MEDIUM"
        (activateElement)="goToLogin()"
        (mouseenter)="mouseEnterLogin($event)"
        [superscript]="(totalItems$ | async) > 0 ? (totalItems$ | async) : ''"
        [disabled]="(onCartPage$ | async) || (onCheckoutReviewOrderPage$ | async)"
      ></app-icon-button>
    </ng-template>

    <ng-template adaptiveTemplateRef="labelButton">
      <app-button
        [label]=" 'common.login' | cxTranslate"
        buttonSize="SMALL"
        iconType="LOGIN"
        (activateElement)="goToLogin()"
        (mouseenter)="mouseEnterLogin($event)"
        [colorTheme]="['ACCENT', 'BASE']"
        [superscript]="(totalItems$ | async) > 0 ? (totalItems$ | async) : ''"
        [disabled]="(onCartPage$ | async) || (onCheckoutReviewOrderPage$ | async)"
      ></app-button>
    </ng-template>

  </app-adaptive-template-server>

  <div [ngClass]="['mini-cart-overview-backdrop', (open$ | async) ? 'open' : 'closed']">

    <div [ngClass]="['mini-cart-overview-container', (open$ | async) ? 'open' : 'closed']">

      <app-sliding-content [expanded]="open$ | async" block keepRendering>
        <div [ngClass]="['mini-cart-overview', 'mini-cart-sliding-content-login', (open$ | async) ? 'open' : 'closed']">

          <app-custom-login-form hideCountrySelector></app-custom-login-form>

        </div>
      </app-sliding-content>

    </div>

  </div>

}
