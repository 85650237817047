import {NgModule} from '@angular/core';
import {BasePageMetaResolver, provideConfig} from '@spartacus/core';
import {CustomBaseMetaResolver} from '../services/custom-base-meta.resolver';


// https://sap.github.io/spartacus-docs/lazy-loading-guide/#configuration-of-lazy-loaded-modules

@NgModule({
  declarations: [],
  providers: [
    //
    provideConfig({
      featureModules: {
        // Homepage
        'kurzWelcomeImageComponent': {
          module: () => import('../kurz-components/kurz-hompage/kurz-welcome-image/kurz-welcome-image.module').then(m => m.KurzWelcomeImageModule),
          cmsComponents: [
            'KurzWelcomeImageComponent', // I assume that it should be 'TripleComponent' instead of 'KurzWelcomeImageComponent'
            'TripleComponent'
          ]
        },
        'kurzHomepageIndustriesComponent': {
          module: () => import('../kurz-components/kurz-hompage/kurz-hompage-industries/kurz-hompage-industries.module').then(m => m.KurzHompageIndustriesModule),
          cmsComponents: ['KurzHomepageIndustriesComponent']
        },
        'kurzHomepageBottomComponent': {
          module: () => import('../kurz-components/kurz-hompage/kurz-homepage-bottom/kurz-homepage-bottom.module').then(m => m.KurzHomepageBottomModule),
          cmsComponents: ['KurzHomepageBottomComponent']
        },
        'customSiteLogoComponent': {
          module: () => import('../custom-components/custom-site-logo/custom-site-logo.module').then(m => m.CustomSiteLogoModule),
          cmsComponents: ['CustomSiteLogoComponent']
        },
        'kurzCategoryNavigation': {
          module: () => import('../kurz-components/kurz-category-navigation/kurz-category-navigation.module').then(m => m.KurzCategoryNavigationModule),
          cmsComponents: [
            'CategoryNavigationComponent'
          ]
        },
        'KurzCountrySelectorSlotComponent': {
          module: () => import('../kurz-components/shared/kurz-country-selector-slot/kurz-country-selector-slot.module').then(m => m.KurzCountrySelectorSlotModule),
          cmsComponents: [
            'KurzCountrySelectorSlotComponent'
          ]
        },
        'customPreHeaderComponent': {
          module: () => import('../custom-components/custom-pre-header/custom-pre-header.module').then(m => m.CustomPreHeaderModule),
          cmsComponents: [
            'CustomPreHeaderComponent'
          ]
        },
        'ReturningCustomerLoginComponent': {
          module: () => import('../custom-components/custom-login-form/custom-login-form.module').then(m => m.CustomLoginFormModule),
          cmsComponents: [
            'CustomLoginFormComponent'
          ]
        },
        'ResetPasswordComponent': {
          module: () => import('../custom-components/custom-reset-password/custom-reset-password.module').then(m => m.CustomResetPasswordModule),
          cmsComponents: [
            'CustomResetPasswordComponent'
          ]
        },
        'ResetUpdateComponent': {
          module: () => import('../custom-components/custom-update-password/custom-update-password.module').then(m => m.CustomUpdatePasswordModule),
          cmsComponents: [
            'CustomUpdatePasswordComponent'
          ]
        },
        //
        'standardCutSizes': {
          module: () => import('../kurz-components/kurz-standard-cut-sizes/kurz-standard-cut-sizes.module').then(m => m.KurzStandardCutSizesModule),
          cmsComponents: [
            'KurzStandardCutSizesItemComponent',
            'KurzStandardCutSizesContainerComponent',
          ]
        },
        //
        'videoTutorials': {
          module: () => import('../kurz-components/kurz-video-tutorials/kurz-video-tutorials.module').then(m => m.KurzVideoTutorialsModule),
          cmsComponents: [
            'KurzVideoTutorialsContainerComponent',
            'KurzVideoTutorialListItemComponent',
          ]
        },
        //
        'myAccount': {
          module: () => import('../kurz-components/kurz-my-account/kurz-my-account.module').then(m => m.KurzMyAccountModule),
          cmsComponents: [
            'KurzMyAccountComponent',
            'KurzMyProfileComponent',
            'KurzDeliveryAddressComponent',
            'KurzDeliveryConditionComponent',
            'KurzPaymentAddressComponent',
            'KurzPaymentConditionComponent',
            'KurzManageUsersComponent',
            'KurzManageUsersListComponent',
            'KurzPricingUnitsComponent'
          ]
        },
        'productDetails': {
          module: () => import('../kurz-components/kurz-product-details/kurz-product-details.module').then(m => m.KurzProductDetailsModule),
          cmsComponents: [
            'KurzProductOrderFormComponent',
            'KurzProductTabBarComponent'
          ]
        },
        'checkoutReviewOrder': {
          module: () => import('../kurz-components/kurz-checkout-review-order/kurz-checkout-review-order.module').then(m => m.KurzCheckoutReviewOrderModule),
          cmsComponents: [
            'KurzCheckoutReviewOrderComponent'
          ]
        },
        'orderConfirmation': {
          module: () => import('../kurz-components/kurz-order-confirmation/kurz-order-confirmation.module').then(m => m.KurzOrderConfirmationModule),
          cmsComponents: [
            'KurzOrderConfirmationComponent'
          ]
        },
        'cart': {
          module: () => import('../kurz-components/kurz-cart/kurz-cart.module').then(m => m.KurzCartModule),
          cmsComponents: [
            'KurzCartHeaderComponent',
            'KurzCartComponent'
          ]
        },
        'savedCarts': {
          module: () => import('../kurz-components/kurz-saved-carts/kurz-saved-carts.module').then(m => m.KurzSavedCartsModule),
          cmsComponents: [
            'KurzSavedCartsComponent',
          ]
        },
        'pricelist': {
          module: () => import('../kurz-components/kurz-pricelist/kurz-pricelist.module').then(m => m.KurzPricelistModule),
          cmsComponents: [
            'KurzPriceListComponent'
          ]
        },
        'contracts': {
          module: () => import('../kurz-components/kurz-contracts/kurz-contracts.module').then(m => m.KurzContractsModule),
          cmsComponents: [
            'KurzContractsComponent'
          ]
        },
        'stockSale': {
          module: () => import('../kurz-components/kurz-stock-sale/kurz-stock-sale.module').then(m => m.KurzStockSaleModule),
          cmsComponents: [
            'KurzStockSaleComponent',
            'KurzStockSaleFilterComponent'
          ]
        },
        'orderDetails': {
          module: () => import('../kurz-components/kurz-order-history/kurz-order-details/kurz-order-details.module').then(m => m.KurzOrderDetailsModule),
          cmsComponents: [
            'KurzOrderDetailsComponent',
          ]
        },
        'orderHistory': {
          module: () => import('../kurz-components/kurz-order-history/kurz-order-history.module').then(m => m.KurzOrderHistoryModule),
          cmsComponents: [
            'AccountOrderHistoryComponent'
          ],
        },
        'productList': {
          module: () => import('../kurz-components/kurz-product-list/kurz-product-list.module').then(m => m.KurzProductListModule),
          cmsComponents: [
            'CMSProductListComponent'
          ],
        },
        'impressum': {
          module: () => import('../kurz-components/kurz-impressum/kurz-impressum.module').then(m => m.KurzImpressumModule),
          cmsComponents: [
            'ImpressumDECompoment'
          ],
        },
        'quickOrder': {
          module: () => import('../kurz-components/kurz-quick-order/kurz-quick-order.module').then(m => m.KurzQuickOrderModule),
          cmsComponents: [
            'KurzQuickOrderComponent',
            'KurzQuickOrderHeaderComponent'
          ],
        },
        'MultiHeadingComponent': {
          module: () => import('../kurz-components/kurz-hompage/kurz-multi-heading-component/kurz-multi-heading.module').then(m => m.KurzMultiHeadingModule),
          cmsComponents: [
            'KurzMultiHeadingComponent'
          ]
        },
        'LastVisitedProductsComponent': {
          module: () => import('../kurz-components/kurz-hompage/kurz-last-visited-products/kurz-last-visited-products.module').then(m => m.KurzLastVisitedProductsModule),
          cmsComponents: [
            'LastVisitedProductsComponent'
          ]
        },
        'PromotedProductsComponent': {
          module: () => import('../kurz-components/kurz-hompage/kurz-promoted-products/kurz-promoted-products.module').then(m => m.KurzPromotedProductsModule),
          cmsComponents: [
            'PromotedProductsComponent'
          ]
        },
        'ProductReferencesComponent': {
          module: () => import('../custom-components/custom-product-references/custom-product-references.module').then(m => m.CustomProductReferencesModule),
          cmsComponents: [
            'ProductReferencesComponent'
          ]
        },
        'KurzShopMessageComponent': {
          module: () => import('../kurz-components/kurz-shop-message/kurz-shop-message.module').then(m => m.KurzShopMessageModule),
          cmsComponents: [
            'KurzShopMessageComponent'
          ]
        },
        'DWTripleComponent': {
          module: () => import('../kurz-components/kurz-workflow-solutions/kurz-workflow-solutions.module').then(m => m.KurzWorkflowSolutionsModule),
          cmsComponents: [
            'KurzWorkflowSolutionsCard'
          ]
        },
        'MultiBannerComponent': {
          module: () => import('../kurz-components/kurz-workflow-solutions/kurz-workflow-solutions.module').then(m => m.KurzWorkflowSolutionsModule),
          cmsComponents: [
            'KurzWorkflowSolutionsHomepage'
          ]
        },
        'HomepageVideoComponentContainer': {
          module: () => import('../kurz-components/kurz-hompage/kurz-videos/kurz-videos.module').then(m => m.KurzVideosModule),
          cmsComponents: [
            'KurzVideos'
          ]
        },
        'TradeFairContainer': {
          module: () => import('../kurz-components/kurz-hompage/kurz-trade-fairs/kurz-trade-fairs.module').then(m => m.KurzTradeFairsModule),
          cmsComponents: [
            'TradeFairContainer',
          ]
        },
        'productFinder': {
          module: () => import('../kurz-components/kurz-product-finder/kurz-product-finder.module').then(m => m.KurzProductFinderModule),
          cmsComponents: [
            'ProductFinderComponent',
          ]
        },
        'ProductComparisonComponent': {
          module: () => import('../kurz-components/kurz-product-comparison/kurz-product-comparison.module').then(m => m.KurzProductComparisonModule),
          cmsComponents: [
            'ProductComparisonComponent'
          ]
        },
        'RecosysPageComponent': {
          module: () => import('../kurz-components/kurz-recosys/kurz-recosys.module').then(m => m.KurzRecosysModule),
          cmsComponents: [
            'RecosysPageComponent'
          ]
        }
      }
    }),
    //
    {
      provide: BasePageMetaResolver,
      useExisting: CustomBaseMetaResolver
    }
  ]
})
export class CustomLazyLoadingCMSComponentsModule { }
