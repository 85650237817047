import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomFooterNavigationModule } from './custom-footer-navigation/custom-footer-navigation.module';
import { CustomMiniCartModule } from './custom-mini-cart/custom-mini-cart.module';
import { CustomNavigationModule } from './custom-navigation/custom-navigation.module';
import { CustomSearchboxModule } from './custom-searchbox/custom-searchbox.module';
import { CustomSiteContextSelectorModule } from './custom-site-context-selector/custom-site-context-selector.module';

@NgModule({
  imports: [
    CommonModule,
    CustomNavigationModule,
    CustomFooterNavigationModule,
    CustomSiteContextSelectorModule,
    CustomMiniCartModule,
    CustomSearchboxModule
  ],
  providers: [],
  declarations: [],
})
export class CustomComponentsModule { }
