import { Injectable } from '@angular/core';
import { ProtectedRoutesService } from '@spartacus/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class KurzProtectedRoutesService extends ProtectedRoutesService {

  /**
   * @todo TODO find a way to avoid the use of hard coded strings - maybe reading routes (this Service should have everything needed for this task) // NOSONAR
   * @param urlSegments
   * @returns
   */
  override isUrlProtected(urlSegments: string[]): boolean {

    const protectedRouteFirstSeqments = ['quick-order', 'stock-sale', 'my-details', 'my-account', 'price-list', 'contracts', 'cart', 'checkout'];
    const startSeqment = urlSegments?.[0];

    if (protectedRouteFirstSeqments.includes(startSeqment)) {
      return true;
    }

    return false;
  }

}
