import { Component, OnInit } from '@angular/core';
import { AuthService } from '@spartacus/core';
import { MenuComponentOptions } from '@util/components/menu/menu.component';
import { combineLatest, Observable, of, } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { KurzMyAccountService } from 'src/app/custom/services/kurz-my-account.service';
import { KurzAccountManager } from '../types/kurz-user.interface';
import { KurzOmnipresentContactInsertComponent } from './kurz-omnipresent-contact-insert/kurz-omnipresent-contact-insert.component';
import { environment } from 'src/environments/environment';
import { getUrlOfSingleMedia } from '@util/functions/strings';

@Component({
  selector: 'app-kurz-omnipresent-contact-button',
  templateUrl: './kurz-omnipresent-contact-button.component.html',
  styleUrls: ['./kurz-omnipresent-contact-button.component.scss']
})
export class KurzOmnipresentContactButtonComponent implements OnInit {

  public menuOptions$: Observable<MenuComponentOptions | null>;

  constructor(
    private kurzMyAccountService: KurzMyAccountService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.menuOptions$ = combineLatest([this.authService.isUserLoggedIn(), this.kurzMyAccountService.getProfile()])
      .pipe(
        map(([isLogged, profile]) => (!isLogged || !profile)),
        distinctUntilChanged(),
        switchMap(isInactive => this.setMenuOptions(isInactive)),
      );
  }

  private setMenuOptions(isInactive: boolean): Observable<MenuComponentOptions> {
    if (isInactive) {
      return of(null);
    } else {
      return this.kurzMyAccountService.getUserAccountManager()
        .pipe(
          map(manager => this.getMenuOptions(manager))
        );
    }
  }

  private getMenuOptions(manager: KurzAccountManager): MenuComponentOptions {
    if (manager && manager.profilePicture) {
      manager.profilePicture.url = getUrlOfSingleMedia(manager?.profilePicture?.url);
    }

    return {
      inserts: [
        {
          component: KurzOmnipresentContactInsertComponent,
          data: manager || {}
        },
      ],
      menuCSSPosition: 'relative',
      menuCSSDisplay: 'block',
      closesOnOutsideClick: true,
      popup: true
    };

  }


}

