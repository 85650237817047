/* eslint-disable @angular-eslint/component-max-inline-declarations */
import { Component, Injector, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BasicMenuInsertComponent } from '@util/components/menu/basic-menu-insert.component';
import { NavigationNode } from '@spartacus/storefront';


@Component({
  templateUrl: './services-custom-navigation-insert.component.html',
  styleUrls: ['./services-custom-navigation-insert.component.scss']
})
export class ServicesCustomNavigationInsertComponent extends BasicMenuInsertComponent<{node$: Observable<NavigationNode>;}> implements OnInit, OnDestroy {

  private routerEventSubscription: Subscription;

  constructor(@Optional() injector: Injector, private router: Router) {
    super(injector);
  }

  ngOnInit(): void {
    this.routerEventSubscription = this.router.events.subscribe(re => {
      if (re instanceof ActivationStart) {
        this.menuRef?.close();
      }
    });
  }

  ngOnDestroy() {
    this.routerEventSubscription?.unsubscribe();
  }

}

