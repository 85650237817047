import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Directive, Injectable, Input, NgModule, OnChanges, SimpleChanges } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nextTranslationService, Occ, RoutingConfig, provideConfig } from '@spartacus/core';
import { CmsPageGuard, IconModule as CxIconModule, GenericLinkModule } from '@spartacus/storefront';
import { AdaptiveTemplateServerModule } from '@util/components/adaptive-template-server/adaptive-template-server.module';
import { ButtonModule } from '@util/components/button/button.module';
import { CheckboxGroupModule } from '@util/components/checkbox-button-group/checkbox-group.module';
import { CheckboxModule } from '@util/components/checkbox/checkbox.module';
import { CustomTreeModule } from '@util/components/custom-tree/custom-tree.module';
import { DropdownModule } from '@util/components/dropdown/dropdown.module';
import { IconButtonModule } from '@util/components/icon-button/icon-button.module';
import { IconModule } from '@util/components/icon/icon.module';
import { InputModule } from '@util/components/input/input.module';
import { LinkButtonModule } from '@util/components/link-button/link-button.module';
import { PanelModule } from '@util/components/panel/panel.module';
import { BaseFormValidatorsModule } from '@util/components/shared/base-form/base-form-validators/base-form-validators.module';
import { TextareaModule } from '@util/components/textarea/textarea.module';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';
import { GetVarModule } from '@util/directives/get-var/get-var.module';
import { IfFormGroupValidModule } from '@util/directives/if-form-group-valid/if-form-group-valid.module';
import { KurzTooltipModule } from '@util/directives/kurz-tooltip/kurz-tooltip.module';
import { CustomLanguageSelectorModule } from '../custom/custom-components/custom-language-selector/custom-language-selector.module';
import { KurzCountryAutocompleteModule } from '../custom/kurz-components/shared/kurz-country-autocomplete/kurz-country-autocomplete.module';
import { KurzOmnipresentContactButtonModule } from '../custom/kurz-components/shared/kurz-omnipresent-contact-button/kurz-omnipresent-contact-button.module';
import { KurzUnitPipesModule } from '../custom/pipes/kurz-unit/kurz-unit-pipes.module';
import { DevCheckboxRenderingComponent } from './dev-checkbox-rendering/dev-checkbox-rendering.component';
import { DevConfigService } from './dev-config.service';
import { DevHttpInterceptor, SpartacusPagesResponseBody } from './dev-http.interceptor';
import { DevComponent } from './dev.component';
import { KurzOccProductSearchPage } from '../custom/kurz-components/kurz-product-finder/kurz-product-search-page.types';
import { KurzProduct } from '../custom/kurz-components/shared/types/kurz-product.interface';
import { KurzStockLevelResponse } from '../custom/kurz-components/shared/kurz-stock-level/kurz-stock-level.types';


@Injectable()
class DevI18nextTranslationService extends I18nextTranslationService {
  protected override getFallbackValue(key: string): string {
    return `[${key}]`;
  }
}

@Directive({
  selector: '[dntest], [dntest.f1], [dntest.f2]',
  // no need to add 'f2' to the inputs[] because we can also use the @Input() decorator
  inputs: ['dntest', 'f1', 'dntest.f1', 'dntest.f2'],
})
export class DotNotationTestDirective implements OnChanges {

  // must be the selector infront of the dot
  protected _thisSelector = 'dntest';

  set dntest(value: string) {
    console.log('DotNotationTestDirective @Input() dntest = ', value);
  }

  set f1(value: string) {
    console.log('DotNotationTestDirective @Input() f1 = ', value);
  }

  @Input()
  set f2(value: string) {
    console.log('DotNotationTestDirective @Input() f2 = ', value);
  }

  /** For @Input changes with the dot notation */
  ngOnChanges(changes: SimpleChanges) {

    Object.keys(changes).forEach(key => {
      if (key.includes('.')) {
        const parts = key.split('.');
        const wantedKey = parts.length === 2 && parts[0] === this._thisSelector ? parts[1] : void 0;
        if (wantedKey) {
          const val = changes[key].currentValue;
          this[wantedKey] = val;
        }
      }
    });
  }
}


@NgModule({
  declarations: [
    DevComponent,
    DevCheckboxRenderingComponent,
    DotNotationTestDirective
  ],
  imports: [
    CommonModule,
    GenericLinkModule,
    RouterModule.forChild([
      {
        path: 'dev',
        canActivate: [CmsPageGuard],
        component: DevComponent,
      },
      {
        path: 'dev/button',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/button/button-dev.module').then(m => m.ButtonDevModule)
      },
      {
        path: 'dev/config-belt',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/config-belt/config-belt-dev.module').then(m => m.ConfigBeltDevModule)
      },
      {
        path: 'dev/custom-tree',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/custom-tree/custom-tree-dev.module').then(m => m.CustomTreeDevModule)
      },
      {
        path: 'dev/date-picker',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/date-picker/date-picker-dev.module').then(m => m.DatePickerDevModule)
      },
      {
        path: 'dev/dropdown',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/dropdown/dropdown-dev.module').then(m => m.DropdownDevModule)
      },
      {
        path: 'dev/differently-styled-value',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/differently-styled-value/differently-styled-value-dev.module').then(m => m.DifferentlyStyledValueDevModule)
      },
      {
        path: 'dev/grid',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/grid/grid-dev.module').then(m => m.GridDevModule)
      },
      {
        path: 'dev/menu',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/menu/menu-dev.module').then(m => m.MenuDevModule)
      },
      {
        path: 'dev/modal',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/modal/modal-dev.module').then(m => m.ModalDevModule)
      },
      {
        path: 'dev/omnipresent-form-group',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/omnipresent-form-group/omnipresent-form-group-dev.module').then(m => m.OmnipresentFormGroupDevModule)
      },
      {
        path: 'dev/price-calc',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/price-calc/price-calc-dev.module').then(m => m.PriceCalcDevModule)
      },
      {
        path: 'dev/tab-bar',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/tab-bar/tab-bar-dev.module').then(m => m.TabBarDevModule)
      },
      {
        path: 'dev/toasts',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/toasts/toasts-dev..module').then(m => m.ToastsDevModule)
      },
      {
        path: 'dev/upload-files',
        canActivate: [CmsPageGuard],
        loadChildren: () => import('./sub-pages/upload-files/upload-files-dev.module').then(m => m.UploadFilesDevModule)
      }
    ]),

    // Modules, which will be tested, are imported here:
    IconButtonModule,
    ButtonModule,
    CheckboxModule,
    CheckboxGroupModule,
    CxIconModule,
    IconModule,
    InputModule,
    TextareaModule,
    GetVarModule,
    PanelModule,
    AdaptiveTemplateServerModule,
    KurzTooltipModule,
    ActivateElementModule,
    LinkButtonModule,
    CustomLanguageSelectorModule,
    CustomTreeModule,
    DropdownModule,
    KurzUnitPipesModule,
    BaseFormValidatorsModule,
    IfFormGroupValidModule,
    KurzOmnipresentContactButtonModule,

    // testing
    KurzCountryAutocompleteModule
  ],
  providers: [
    provideConfig(<RoutingConfig>{
      routing: {
        routes: {
          dev: {
            paths: ['dev'],
            protected: false
          },
          devUploadFiles: {
            paths: ['dev/upload-files'],
            protected: false
          },
          devDatePicker: {
            paths: ['dev/date-picker'],
            protected: false
          }
        }
      }
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DevHttpInterceptor,
      multi: true
    },
    {
      provide: I18nextTranslationService,
      useClass: DevI18nextTranslationService
    },
    DevConfigService
  ]
})
/**
 * This module, its only component DevComponent and its static routes exist with
 * the sole purpose for easier testing and is NOT importet in the final project (import in AppModule)
 */
export class DevModule {
  constructor() {


    // contracts wcms mocks
    DevHttpInterceptor.addSlotsComponentsReassurement(
      {
        destinguishes: { name: /(c|C)ontract/gm },
        changes: [
          // {path: 'template', value: 'ContractsPageTemplate'}
        ]
      },
      [
        {
          position: 'BodyContent',
          name: 'Body Content Slot for Contracts',
          reassuredComponents: [
            { flexType: 'KurzContractsComponent' }
          ]
        }
      ]
    );

    // stock sales wcms mocks
    DevHttpInterceptor.addSlotsComponentsReassurement(
      {
        destinguishes: { name: /[\s]*stock[\s]*sale/i },
        changes: [
          { path: 'template', value: 'StockSalePageTemplate' }
        ]
      },
      [
        {
          position: 'BodyContent',
          name: 'Body Content Slot for Stock Sale',
          reassuredComponents: [
            { flexType: 'KurzStockSaleComponent' }
          ]
        }
      ]
    );

    // wcms mocks
    DevHttpInterceptor.addSlotsComponentsReassurement(
      {
        destinguishes: { uid: 'cartPage' },
        changes: [{
          path: 'template',
          value: 'CartPageTemplate'
        }]
      },
      [
        {
          position: 'BottomHeaderSlot',
          name: 'Homepage Bottom Header Slot',
          reassuredComponents: [
            { flexType: 'KurzCartHeaderComponent', name: 'Cart Header Component' },
          ]
        },
        // one of these two slots (TopContent, EmptyCartMiddleContent) gets excluded from the CartPageTemplateLayout
        // by Spartacus' CartPageLayoutHandler, depending on the totalItems
        {
          position: 'TopContent',
          slotId: 'TopContent-cartPage',
          reassuredComponents: [
            { flexType: 'KurzCartComponent', name: 'Cart Component' }
          ]
        },
        {
          position: 'EmptyCartMiddleContent',
          slotId: 'EmptyCartMiddleContent-cartPage',
          reassuredComponents: [
            { flexType: 'KurzCartComponent', name: 'Cart Component' }
          ]
        }
      ]
    );

    // wcms mocks
    DevHttpInterceptor.addSlotsComponentsReassurement(
      { destinguishes: { template: '(ProductListPageTemplate|SearchResultsListPageTemplate)' } },
      [
        {
          position: 'ProductLeftRefinements',
          name: 'Refinements',
          slotId: 'ProductLeftRefinements',
          reassuredComponents: [{
            flexType: 'ProductRefinementComponent'
          }
          ]
        }
      ]
    );

    // product search manipulation
    DevHttpInterceptor.addResponseInterception('/products/search', (body, req) => {

      if (req?.url?.includes('/products/search')) {

        const bodyIsPSP = (arg: any): arg is KurzOccProductSearchPage => { return !!arg; };

        if (bodyIsPSP(body)) {
          if (Array.isArray(body.breadcrumbs) && body.breadcrumbs.length === 0) {
            body.breadcrumbs = void 0;
            console.warn('Spartacus expects the 1st item of the breadcrumb array to exist and to contain information of categoryName');
          }

          // const facets = body.facets;
          // // --
          // const finishingProcessCode = 'kurzFinishingProcess';
          // const finishingProcessFacetIndex = facets.findIndex(facet => facet.code === finishingProcessCode);
          // const mockFinishProcessFacet: KurzOccProductSearchPage['facets'][0] = {
          //   category: true,
          //   code: finishingProcessCode,
          //   multiSelect: true,
          //   name: 'Veredelungsverfahren',
          //   values: [
          //     {name: 'Digital', count: 10, query: {query: {value: ':name-asc:allCategories:baseProductClassification:finishingProcess:Digital'}, url: ''}, selected: false},
          //     {name: 'Heißprägen', count: 16, query: {query: {value: ':name-asc:allCategories:baseProductClassification:finishingProcess:Heißprägen'}, url: ''}, selected: false},
          //     {name: 'Heißprägen/Allgemein', count: 7, query: {query: {value: ':name-asc:allCategories:baseProductClassification:finishingProcess:Heißprägen%2FAllgemein'}, url: ''}, selected: false},
          //     {name: 'Heißprägen/Bogen', count: 4, query: {query: {value: ':name-asc:allCategories:baseProductClassification:finishingProcess:Heißprägen%2FBogen'}, url: ''}, selected: false},
          //     {name: 'Heißprägen/Rolle', count: 5, query: {query: {value: ':name-asc:allCategories:baseProductClassification:finishingProcess:Heißprägen%2FRolle'}, url: ''}, selected: false},
          //   ]
          // };
          // body.facets.splice(finishingProcessFacetIndex, 1, mockFinishProcessFacet);
          // // --
          // const substrateCode = 'substrate';
          // const substrateFacet = facets.find(facet => facet.code === substrateCode);
          // substrateFacet?.values.forEach(facetValue => {
          //   if (!facetValue['facetImage'] && facetValue.name?.indexOf('/') < 0) {
          //     const img: KurzFacetValue['facetImage'] = {
          //       alt: 'test image',
          //       url: getTestImageSrc(50, 50)
          //     };
          //     facetValue['facetImage'] = img;
          //   }
          // });
          // // --
          // const basicColorCode = 'kurzBasicColor';
          // const basicColorFacetIndex = facets.findIndex(facet => facet.code === basicColorCode);
          // const mockBasicColorFacetFacet: KurzOccProductSearchPage['facets'][0] = {
          //   category: true,
          //   code: basicColorCode,
          //   multiSelect: true,
          //   name: 'Basic Farben',
          //   values: [
          //     {name: 'gold', originalId: 'GOLD',  count: 10, query: {query: {value: ':name-asc:allCategories:baseProductClassification:kurzBasicColor:gold'}, url: ''}, selected: false},
          //     {name: 'transparent', originalId: 'TRANSPARENT',  count: 10, query: {query: {value: ':name-asc:allCategories:baseProductClassification:kurzBasicColor:gold'}, url: ''}, selected: false},
          //     {name: 'multi', originalId: 'MULTICOLORED',  count: 10, query: {query: {value: ':name-asc:allCategories:baseProductClassification:kurzBasicColor:gold'}, url: ''}, selected: false},
          //   ]
          // };
          // body.facets.splice(basicColorFacetIndex, 1, mockBasicColorFacetFacet);
          // // --
        }

      }

      return body;
    });

    // user manipulation
    // DevHttpInterceptor.addResponseInterception('/orgUsers/current', (body, req) => {
    //   if (body['roles']) {
    //     (body['roles'] as any[])?.push('b2bmanagergroup');
    //   }
    //   if (body['orgUnit']?.uid) {
    //     body['orgUnit'].uid = 'mock_2';
    //     body['orgUnit'].name = 'Mock Two';
    //   }
    //   console.log('/orgUsers/current - adding b2bmanagergroup to the user roles and mocking orgUnit', body['roles']);
    //   return body;
    // });

    // B2BUnits request breakout - backend denies access for some user
    // DevHttpInterceptor.addRequestBreakout('/customer-manager/B2BUnits', req => (true), req => {
    //   console.log('/customer-manager/B2BUnits -- ');
    //   return [
    //     {
    //       uid: 'mock_1',
    //       name: 'Mock One'
    //     },
    //     {
    //       uid: 'mock_2',
    //       name: 'Mock Two'
    //     },
    //     {
    //       uid: 'mock_3',
    //       name: 'Mock Three'
    //     }
    //   ];
    // });


    /**
     * Product details
     */
    // DevHttpInterceptor.addResponseInterception('/products/', (body: Occ.Product, req) => {
    //   if (body.code) {
    //     console.log('Product details', body);
    //     (body as unknown as KurzProduct).isStockSaleOnly = false;
    //   }
    //   return body;
    // });

    /**
     * Product search
     */
    // DevHttpInterceptor.addResponseInterception('products/search', (body: Occ.ProductSearchPage, req) => {
    //   if (body?.products?.length) {
    //     body.products.forEach(p => (p as unknown as KurzProduct).isStockSaleOnly = true);
    //     console.log('Product search', body.products);
    //   }
    //   return body;
    // });

    /**
     * Foil Config
     */
    // DevHttpInterceptor.addResponseInterception('/foilConfiguration?baseId', (body: KurzFoilConfig.Data[], req) => {
    //   const row1: KurzFoilConfig.Data = {
      //   core : {active: false, code: 'F', diameterMM: 0, jumbo: false, name: '17 mm Kunststoffkern'},
      //   finishingType: {code: 'S', name: 'Schneiden'},
      //   length: 61,
      //   materialNumber: '10118094',
      //   priceBaseType: 'STANDARD',
      //   systemOfMeasurement: 'M',
      //   width: 500
    //   };
    //   body.push(row1);
    //   return body;
    // });

    /**
     * Stock request
     */
    // DevHttpInterceptor.addResponseInterception('/stock/productStock', (body: KurzStockLevelResponse, req) => {
    //   const entry1: KurzStockLevelResponse['results'][0] = {available: 11, coreSpecification: {code: 'M', name: 'Mock Kern'}, finishingType: {code: 'S'}, width: 11, length: 122, productCode: '10127738', cuttable: false, unitOfArea: 'M2-M'};
    //   body.results.push(entry1)
    //   return body;
    // });

    // delete position for the frontend without deleting it for the backend
    // DevHttpInterceptor.addRequestBreakout('/cart/deleteEntries', req => (true), req => {
    //   console.log('/cart/deleteEntries - request breakout');
    //   return {
    //     "quantity" : 0,
    //     "quantityAdded" : 0,
    //     "statusCode" : "success"
    //   };
    // });

    // DevHttpInterceptor.addRequestGuard('/my-details/my-profile', req => (true), req => {
    //   console.log('addRequestGuard()', req);
    //   return 'error';
    // });

    /**
     * Checkout wcms mocks
     */
    DevHttpInterceptor.addResponseInterception('/pages', (body: SpartacusPagesResponseBody, req) => {

      if (body?.label?.includes('review-order')) {

        body.template = 'CheckoutReviewOrderPageTemplate';

        const bodyContenSlot = body.contentSlots?.contentSlot?.find(slot => slot.position === 'BodyContent');
        const sideContenSlot = body.contentSlots?.contentSlot?.find(slot => slot.position === 'SideContent');

        if (bodyContenSlot) {
          bodyContenSlot.components.component = [
            {
              container: false,
              name: 'Kurz Checkout Review Order Component',
              flexType: 'KurzCheckoutReviewOrderComponent', // name used for the frontend backend mapping
              typeCode: 'CMSFlexComponent' // necessary string
            }
          ];
        }

        if (sideContenSlot) {
          sideContenSlot.components.component = [];
        }

        console.warn(`Pages Response for ${body?.label} intercepted and its body modified to`, body, `Also: Changed Template to "${body.template}"`);

      }

      return body;

    });

    // Order confirmation wcms mocks
    DevHttpInterceptor.addResponseInterception('/pages', (body: SpartacusPagesResponseBody, req) => {

      if (body?.label?.includes('order-confirmation')) {

        body.template = 'CheckoutReviewOrderPageTemplate';

        let bodyContenSlot = body.contentSlots?.contentSlot?.find(slot => slot.position === 'BodyContent');
        if (!bodyContenSlot) {
          bodyContenSlot = {
            components: { component: [] },
            name: 'Body Content Slot',
            position: 'BodyContent',
            slotId: 'BodyContentSlot'
          };
          body.contentSlots.contentSlot.push(bodyContenSlot);
        }

        if (bodyContenSlot) {
          bodyContenSlot.components.component = [
            {
              container: false,
              name: 'Kurz Order Confirmation Component',
              flexType: 'KurzOrderConfirmationComponent', // name used for the frontend backend mapping
              typeCode: 'CMSFlexComponent' // necessary string
            }
          ];
        }

        console.warn(`Pages Response for ${body?.label} intercepted and its body modified to`, body, `Also: Changed Template to "${body.template}"`);

      }

      return body;

    });

    /**
     * FOR COM-1341 wcms mocks
     * - Impressum for DE-Shop uses MKM Data
     */
    DevHttpInterceptor.addResponseInterception('/pages', (body: SpartacusPagesResponseBody, req) => {

      if (body.uid?.includes('impressum_de')) {

        const section2BSlot = body.contentSlots?.contentSlot?.find(slot => slot.position === 'Section2B');

        if (section2BSlot) {
          section2BSlot.components.component = [
            {
              container: false,
              name: 'Impressum for DE Shop',
              flexType: 'ImpressumDECompoment', // name used for the frontend backend mapping
              typeCode: 'CMSFlexComponent'
            }
          ];
        }
        console.warn(`Pages Response for ${body?.label} intercepted and its body modified to`, body, `Also: Changed Template to "${body.template}"`);
      }

      return body;

    });

    // delete position for the frontend without deleting it for the backend
    // DevHttpInterceptor.addRequestBreakout('/cart/delete', req => (true), req => {
    //   console.log('/cart/delete - request breakout');

    //   return {
    //     "quantity" : 0,
    //     "quantityAdded" : 0,
    //     "statusCode" : "success"
    //   };
    // });

    /**
     * intercepting the validate request for new foilConfiguration, the user can make in the pdp or quick order page with graphic products
     * - the request never leaves the browser and is responded with saying that the request is valid
     */
    // DevHttpInterceptor.addRequestBreakout('/foilConfiguration/validate', req => (true), req => {
    //   function num(val: any) {
    //     return typeof val === 'number' ? val : parseFloat(val);
    //   }
    //   const resBody = {
    //     "request" : {
    //       "core" : req.body['core'],
    //       "finishingType" : req.body['finishingType'],
    //       "length" : num(req.body['length']),
    //       "masterRoll" : false,
    //       "materialNumber" : req.body['materialNumber'],
    //       "minimumQuantity" : 1,
    //       "priceBaseType" : "STANDARD",
    //       "systemOfMeasurement" : "M",
    //       "width" : num(req.body['width'])
    //     },
    //     "valid" : true
    //   };
    //   console.log('validate foil', resBody);
    //   return resBody;
    // });

    // DevHttpInterceptor.addResponseInterception('/base-store/messages', (body: ShopMessage[]) => {
    //   body = [
    //     {
    //       message: 'Das ist ein Mock ERROR mit einem sehr langen Text, der vielleicht zu mehreren Zeilenümbrüchen führen kann, wenn die Bildschirmbreite zu klein ist.',
    //       messageType: 'SHOPMESSAGE_ERROR'
    //     },
    //     {
    //       message: 'Das ist eine Mock Info',
    //       messageType: 'SHOPMESSAGE_INFO'
    //     },
    //   ];

    //   console.log('mocked message response', body);

    //   return body;
    // });


    // DevHttpInterceptor.addResponseInterception('/foilConfiguration?', (body: KurzFoilConfig.Data[]) => {
    //   console.log('response interception: foilConfiguration');
    //   body.forEach(row => {
    //     // row.masterRoll = true;
    //     row.cutSteps = [2, 5, 10, 11, 12, 15, 20, 21, 22, 23, 24, 25, 50, 60, 70, 80, 90, 100];
    //   });
    //   return body;
    // });

  }
}
