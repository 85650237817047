import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KurzToastWrapperComponent } from './kurz-toast-wrapper.component';
import { IconButtonModule } from '@util/components/icon-button/icon-button.module';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';



@NgModule({
  declarations: [
    KurzToastWrapperComponent
  ],
  imports: [
    CommonModule,
    IconButtonModule,
    ActivateElementModule
  ]
})
export class KurzToastWrapperModule { }
