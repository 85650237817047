import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomIconTypesMappingModule } from './custom-configuration-modules/custom-icon-types-mapping.module';
import { CustomBackendToFrontendComponentMappingModule } from './custom-configuration-modules/custom-backend-to-frontend-component-mapping.module';
import { CustomLayoutModule } from './custom-configuration-modules/custom-layout-module.module';
import { CustomTranslationsModule } from './custom-configuration-modules/custom-translations.module';
import { CustomLazyLoadingFeaturesModule } from './custom-configuration-modules/custom-lazy-loading-features.module';
import { CustomLazyLoadingCMSComponentsModule } from './custom-configuration-modules/custom-lazy-loading-cms-components.module';
import { CustomOccEndpointsConfigurationModule } from './custom-configuration-modules/custom-occ-endpoints-configuration.module';
import { CustomRoutingModule } from './custom-configuration-modules/custom-routing.module';
import { CustomContextModule } from './custom-configuration-modules/custom-context.module';
import { CustomFeatureConfigModule } from './custom-configuration-modules/custom-feature-config.module';
import { CustomBaseUrlModule } from './custom-configuration-modules/custom-base-url.module';
import { CustomSmartEditFeatureModule } from './custom-configuration-modules/custom-smart-edit-feature.module';
import { CustomOnNavigateModule } from './custom-configuration-modules/custom-on-navigate.module';
import { CustomMetaConfigModule } from './custom-configuration-modules/custom-meta-config.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { OccCartNormalizer } from '@spartacus/cart/base/occ';
import { TranslationService, ProtectedRoutesService, OccProductSearchPageNormalizer, HttpErrorHandler } from '@spartacus/core';
import { ProductVariantsGuard } from '@spartacus/product/variants/components';
import { CmsPageGuard } from '@spartacus/storefront';
import { KurzOccCartNormalizer } from './converter/kurz-occ-cart.normalizer';
import { KurzOccProductSearchPageNormalizer } from './converter/kurz-occ-product-search-page.normalizer';
import { CustomComponentsModule } from './custom-components/custom-components.module';
import { KurzCmsPageGuard } from './guards/kurz-cms-page.guard';
import { B2bUnitInterceptor } from './http-interceptors/b2b-unit.interceptor';
import { CartExistenceInterceptor } from './http-interceptors/cart-existence.interceptor';
import { CustomBadRequestHandlerInterceptor } from './http-interceptors/custom-bad-request-handler.interceptor';
import { EmulatedUserIdInterceptor } from './http-interceptors/emulated-user-id.interceptor';
import { KurzB2bUnitChooserModule } from './kurz-components/kurz-b2b-unit-chooser/kurz-b2b-unit-chooser.module';
import { KurzLegalDocumentModule } from './kurz-components/kurz-legal-document/kurz-legal-document.module';
import { KurzToastWrapperModule } from './kurz-components/shared/kurz-toast/kurz-toast-wrapper.module';
import { CustomCsAgentAuthService } from './services/custom-csagent-auth.service';
import { CustomProductVariantsGuard } from './services/custom-product-variant.guards';
import { KurzI18nextTranslationService } from './services/kurz-i18next-translation.service';
import { KurzProtectedRoutesService } from './services/kurz-protected-routes.service';
import { OccApiCacheInterceptor } from './http-interceptors/occ-api-cache.interceptor';

@NgModule({
  imports: [
    CommonModule,
    CustomComponentsModule,
    // project specific configurations
    CustomIconTypesMappingModule,
    CustomBackendToFrontendComponentMappingModule,
    CustomLayoutModule,
    CustomTranslationsModule,
    CustomLazyLoadingFeaturesModule,
    CustomLazyLoadingCMSComponentsModule,
    CustomOccEndpointsConfigurationModule,
    CustomRoutingModule,
    CustomContextModule,
    CustomFeatureConfigModule,
    CustomBaseUrlModule,
    CustomSmartEditFeatureModule,
    CustomOnNavigateModule,
    CustomMetaConfigModule,
    KurzToastWrapperModule,
    KurzLegalDocumentModule,
    KurzB2bUnitChooserModule,
  ],
  providers: [
    {
      provide: TranslationService,
      useExisting: KurzI18nextTranslationService
    },
    {
      provide: CsAgentAuthService,
      useClass: CustomCsAgentAuthService
    },
    {
      provide: ProductVariantsGuard,
      useClass: CustomProductVariantsGuard
    },
    {
      provide: ProtectedRoutesService,
      useClass: KurzProtectedRoutesService
    },
    {
      provide: CmsPageGuard,
      useClass: KurzCmsPageGuard
    },
    {
      provide: OccProductSearchPageNormalizer,
      useClass: KurzOccProductSearchPageNormalizer
    },
    {
      provide: OccCartNormalizer,
      useClass: KurzOccCartNormalizer
    },
    {
      provide: HttpErrorHandler,
      useExisting: CustomBadRequestHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CartExistenceInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EmulatedUserIdInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: B2bUnitInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OccApiCacheInterceptor,
      multi: true,
    }
  ]
})
export class CustomModule { }
