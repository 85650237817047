import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IfFormGroupValidDirective } from './if-form-group-valid.directive';



@NgModule({
  declarations: [
    IfFormGroupValidDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IfFormGroupValidDirective
  ]
})
export class IfFormGroupValidModule { }
