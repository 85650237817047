
<ng-container *ngIf="activeCart$ | async as cart">

  <div class="overview-top-bar">

    <div>{{ 'cart.mini.miniCartLabel' | cxTranslate }}</div>

    <div class="details-note">{{ 'cart.mini.overview.priceDetailsNote' | cxTranslate }}</div>

    <app-kurz-price-display
      [options]="{
        value: cart.totalPrice?.value,
        explicitCurrency: cart.totalPrice?.currencyIso
      }"
      [label]=" 'cart.summary.total' | cxTranslate "
      size="EXTRA_SMALL"
    ></app-kurz-price-display>

  </div>

  <div class="overview-entries">

    <ng-template #emptyCart>
      <div class="overview-entries-empty">{{ 'cart.empty.isEmpty_quick' | cxTranslate}}</div>
    </ng-template>


    <div class="cart-entries" *ngIf="cart?.totalItems > 0; else emptyCart">

      <ng-container *ngFor="let entry of cart.entries; index as i">
        <app-custom-mini-cart-overview-entry [entry]="entry"></app-custom-mini-cart-overview-entry>
        <ng-container *ngIf="i < (cart.entries.length - 1)">
          <div class="entry-seperator" aria-hidden="true"></div>
        </ng-container>
      </ng-container>

    </div>


  </div>

</ng-container>
