import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { USER_PROFILE_FEATURE } from '@spartacus/user/profile/root';

// https://sap.github.io/spartacus-docs/lazy-loading-guide/#configuration-of-lazy-loaded-modules

@NgModule({
  declarations: [],
  providers: [
    provideConfig({
      featureModules: {
        [USER_PROFILE_FEATURE]: {
          module: () => import('../custom-features/custom-user-profile/custom-user-profile.module').then(m => m.CustomUserProfileModule)
        }
      }
    }),
  ]
})
export class CustomLazyLoadingFeaturesModule {}
