import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CookieBannerAndGtmService } from '../../services/cookie-banner-and-gtm.service';

export type FooterStyle = 'reduced' | 'full';

@Injectable({
  providedIn: 'root'
})
export class CustomFooterService implements OnDestroy {

  private cookieBannerAndGtmService = inject(CookieBannerAndGtmService);

  private routerEventsSubscription: Subscription;
  private styleBehaviourSubject = new BehaviorSubject<FooterStyle>('full');

  style$ = this.styleBehaviourSubject.asObservable() as Observable<string>;

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
  }

  showCookieBanner() {
    this.cookieBannerAndGtmService.showMKMBanner();
  }

}
