import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule} from '@spartacus/core';
import {BannerComponent, ParagraphComponent} from '@spartacus/storefront';
import {CustomNavigationComponent} from '../custom-components/custom-navigation/custom-navigation.component';
import {CustomFooterNavigationComponent} from '../custom-components/custom-footer-navigation/custom-footer-navigation.component';
import {CustomLoginFormComponent} from '../custom-components/custom-login-form/custom-login-form.component';
import {CustomMiniCartComponent} from '../custom-components/custom-mini-cart/custom-mini-cart.component';
import {CustomResetPasswordComponent} from '../custom-components/custom-reset-password/custom-reset-password.component';
import {CustomSiteContextSelectorComponent} from '../custom-components/custom-site-context-selector/custom-site-context-selector.component';
import {CustomUpdatePasswordComponent} from '../custom-components/custom-update-password/custom-update-password.component';

const cmsConfig: CmsConfig = {
  cmsComponents: {
    LargeCMSParagraphComponent: {
      component: ParagraphComponent
    },
    // ------------------------------------ Homepage
    TripleComponent: {
      component: () => import('../kurz-components/kurz-hompage/kurz-welcome-image/kurz-welcome-image.component')
        .then(c => c.KurzWelcomeImageComponent)
    },
    HomepageIndustriesComponent: {
      component: () => import('../kurz-components/kurz-hompage/kurz-hompage-industries/kurz-hompage-industries.component')
        .then(c => c.KurzHompageIndustriesComponent)
    },
    HomepageBottomComponent: {
      component: () => import('../kurz-components/kurz-hompage/kurz-homepage-bottom/kurz-homepage-bottom.component')
        .then(c => c.KurzHomepageBottomComponent)
    },
    // -------------------------------------
    KurzOrderDetailsComponent: {
      component: () => import('../kurz-components/kurz-order-history/kurz-order-details/kurz-order-details.component')
        .then(c => c.KurzOrderDetailsComponent)
    },
    MultiHeadingComponent: {
      component: () => import('../kurz-components/kurz-hompage/kurz-multi-heading-component/kurz-multi-heading.component')
        .then(c => c.KurzMultiHeadingComponent)
    },
    LastVisitedProductsComponent: {
      component: () => import('../kurz-components/kurz-hompage/kurz-last-visited-products/kurz-last-visited-products.component')
        .then(c => c.KurzLastVisitedProductsComponent)
    },
    PromotedProductsComponent: {
      component: () => import('../kurz-components/kurz-hompage/kurz-promoted-products/kurz-promoted-products.component')
        .then(c => c.KurzPromotedProductsComponent)
    },
    NavigationComponent: {
      component: CustomNavigationComponent
    },
    //
    FooterNavigationComponent: {
      component: CustomFooterNavigationComponent
    },
    //
    CategoryNavigationComponent: {
      component: () => import('../kurz-components/kurz-category-navigation/kurz-category-navigation.component')
        .then(c => c.KurzCategoryNavigationComponent)
    },
    //
    MiniCartComponent: {
      component: CustomMiniCartComponent
    },
    //
    CMSSiteContextComponent: {
      component: CustomSiteContextSelectorComponent
    },
    ReturningCustomerLoginComponent: {
      component: CustomLoginFormComponent
    },
    ResetPasswordComponent: {
      component: CustomResetPasswordComponent
    },
    UpdatePasswordComponent: {
      component: CustomUpdatePasswordComponent
    },
    SearchBoxComponent: {
      component: () => import('../custom-components/custom-searchbox/custom-searchbox.component')
        .then(c => c.CustomSearchboxComponent)
    },
    StandardCutComponent: {
      component: () => import('../kurz-components/kurz-standard-cut-sizes/kurz-standard-cut-sizes-item/kurz-standard-cut-sizes-item.component')
        .then(c => c.KurzStandardCutSizesItemComponent)
    },
    CutSizesComponentContainer: {
      component: () => import('../kurz-components/kurz-standard-cut-sizes/kurz-standard-cut-sizes-container/kurz-standard-cut-sizes-container.component')
        .then(c => c.KurzStandardCutSizesContainerComponent)
    },
    //
    VideoComponentContainer: {
      component: () => import('../kurz-components/kurz-video-tutorials/kurz-video-tutorials-container/kurz-video-tutorials-container.component')
        .then(c => c.KurzVideoTutorialsContainerComponent)
    },
    VideoBox: {
      component: () => import('../kurz-components/kurz-video-tutorials/kurz-video-tutorial-list-item/kurz-video-tutorial-list-item.component')
        .then(c => c.KurzVideoTutorialListItemComponent)
    },
    //
    KurzAccountNavigationComponent: {
      component: () => import('../kurz-components/kurz-my-account/kurz-my-details/kurz-my-details.component')
        .then(c => c.KurzMyDetailsComponent),
    },
    KurzMyProfileComponent: {
      component: () => import('../kurz-components/kurz-my-account/kurz-my-profile/kurz-my-profile.component')
        .then(c => c.KurzMyProfileComponent),
    },
    KurzDeliveryAddressComponent: {
      component: () => import('../kurz-components/kurz-my-account/kurz-delivery-address/kurz-delivery-address.component')
        .then(c => c.KurzDeliveryAddressComponent),
    },
    KurzDeliveryConditionComponent: {
      component: () => import('../kurz-components/kurz-my-account/kurz-delivery-condition/kurz-delivery-condition.component')
        .then(c => c.KurzDeliveryConditionComponent),
    },
    KurzPaymentAddressComponent: {
      component: () => import('../kurz-components/kurz-my-account/kurz-payment-address/kurz-payment-address.component')
        .then(c => c.KurzPaymentAddressComponent),
    },
    KurzPaymentConditionComponent: {
      component: () => import('../kurz-components/kurz-my-account/kurz-payment-condition/kurz-payment-condition.component')
        .then(c => c.KurzPaymentConditionComponent),
    },
    KurzManageUsersComponent: {
      component: () => import('../kurz-components/kurz-my-account/kurz-manage-users/kurz-manage-users.component')
        .then(c => c.KurzManageUsersComponent),
    },
    ManageUsersListComponent: {
      component: () => import('../kurz-components/kurz-my-account/kurz-manage-users/kurz-manage-users-list/kurz-manage-users-list.component')
        .then(c => c.KurzManageUsersListComponent)
    },
    KurzPricingUnitsComponent: {
      component: () => import('../kurz-components/kurz-my-account/kurz-pricing-units/kurz-pricing-units.component')
        .then(c => c.KurzPricingUnitsComponent),
    },
    // PDP
    KurzProductOrderFormComponent: {
      component: () => import('../kurz-components/kurz-product-details/kurz-product-order-form/kurz-product-order-form.component')
        .then(c => c.KurzProductOrderFormComponent)
    },
    KurzProductTabBarComponent: {
      component: () => import('../kurz-components/kurz-product-details/kurz-product-tab-bar/kurz-product-tab-bar.component')
        .then(c => c.KurzProductTabBarComponent)
    },
    // override product reference component
    ProductReferencesComponent: {
      component: () => import('../custom-components/custom-product-references/custom-product-references.component')
        .then(c => c.CustomProductReferencesComponent)
    },
    // checkout - review - order
    KurzCheckoutReviewOrderComponent: {
      component: () => import('../kurz-components/kurz-checkout-review-order/kurz-checkout-review-order.component').then(c => c.KurzCheckoutReviewOrderComponent)
    },
    // order-confirmation
    KurzOrderConfirmationComponent: {
      component: () => import('../kurz-components/kurz-order-confirmation/kurz-order-confirmation.component').then(c => c.KurzOrderConfirmationComponent)
    },
    // Cart
    KurzCartHeaderComponent: {
      component: () => import('../kurz-components/kurz-cart/kurz-cart-header/kurz-cart-header.component')
        .then(c => c.KurzCartHeaderComponent)
    },
    KurzCartComponent: {
      component: () => import('../kurz-components/kurz-cart/kurz-cart/kurz-cart.component')
        .then(c => c.KurzCartComponent)
    },
    // Saved Carts
    KurzSavedCartsComponent: {
      component: () => import('../kurz-components/kurz-saved-carts/kurz-saved-carts.component').then(c => c.KurzSavedCartsComponent)
    },
    // Price List
    KurzPriceListComponent: {
      component: () => import('../kurz-components/kurz-pricelist/kurz-pricelist/kurz-pricelist.component')
        .then(c => c.KurzPricelistComponent)
    },
    KurzContractsComponent: {
      component: () => import('../kurz-components/kurz-contracts/kurz-contracts.component').then(c => c.KurzContractsComponent)
    },
    KurzStockSaleComponent: {
      component: () => import('../kurz-components/kurz-stock-sale/kurz-stock-sale.component').then(c => c.KurzStockSaleComponent)
    },
    // Order History
    AccountOrderHistoryComponent: {
      component: () => import('../kurz-components/kurz-order-history/kurz-order-history.component')
        .then(c => c.KurzOrderHistoryComponent)
    },
    KurzTermsAndConditionsComponent: {
      component: () => import('../kurz-components/kurz-legal-document/kurz-terms-and-conditions/kurz-terms-and-conditions.component')
        .then(c => c.KurzTermsAndConditionsComponent)
    },
    KurzTermsOfUseComponent: {
      component: () => import('../kurz-components/kurz-legal-document/kurz-terms-of-use/kurz-terms-of-use.component')
        .then(c => c.KurzTermsOfUseComponent)
    },
    KurzPrivacyPolicyComponent: {
      component: () => import('../kurz-components/kurz-legal-document/kurz-privacy-policy/kurz-privacy-policy.component')
        .then(c => c.KurzPrivacyPolicyComponent)
    },
    // product list - Produktsortiment
    CMSProductListComponent: {
      component: () => import('../kurz-components/kurz-product-list/kurz-product-list.component').then(c => c.KurzProductListComponent),
      data: {
        composition: {
          inner: ['ProductAddToCartComponent'],
        },
      },
    },
    // search result - same as product list
    SearchResultsListComponent: {
      component: () => import('../kurz-components/kurz-product-list/kurz-product-list.component').then(c => c.KurzProductListComponent),
      data: {
        composition: {
          inner: ['ProductAddToCartComponent'],
        },
      },
    },
    CMSImageComponent: {
      component: BannerComponent
    },
    KurzQuickOrderComponent: {
      component: () => import('../kurz-components/kurz-quick-order/kurz-quick-order.component').then(c => c.KurzQuickOrderComponent)
    },
    KurzQuickOrderHeaderComponent: {
      component: () => import('../kurz-components/kurz-quick-order/kurz-quick-order-header/kurz-quick-order-header.component').then(c => c.KurzQuickOrderHeaderComponent)
    },
    DWTripleComponent:  {
      component: () => import('../kurz-components/kurz-workflow-solutions/kurz-workflow-solutions-card/kurz-workflow-solutions-card.component').then(c => c.KurzWorkflowSolutionsCardComponent)
    },
    MultiBannerComponent: {
      component: () => import('../kurz-components/kurz-workflow-solutions/kurz-workflow-solutions-homepage/kurz-workflow-solutions-homepage.component').then(c => c.KurzWorkflowSolutionsHomepageComponent)
    },
    HomepageVideoComponentContainer: {
      component: () => import('../kurz-components/kurz-hompage/kurz-videos/kurz-videos.component').then(c => c.KurzVideosComponent)
    },
    TradeFairContainer: {
      component: () => import('../kurz-components/kurz-hompage/kurz-trade-fairs/kurz-trade-fairs.component').then(c => c.KurzTradeFairsComponent)
    },
    ImpressumDECompoment: {
      component: () => import('../kurz-components/kurz-impressum/kurz-impressum-de/kurz-impressum-de.component').then(c => c.KurzImpressumDeComponent)
    },
    // Product Finder
    ProductFinderComponent: {
      component: () => import('../kurz-components/kurz-product-finder/kurz-product-finder.component').then(c => c.KurzProductFinderComponent)
    },
    ProductComparisonComponent: {
      component: () => import('../kurz-components/kurz-product-comparison/kurz-product-comparison.component').then(c => c.KurzProductComparisonComponent)
    },
    RecosysPageComponent: {
      component: () => import('../kurz-components/kurz-recosys/kurz-recosys.component').then(c => c.KurzRecosysComponent)
    }
  },
};

@NgModule({
  declarations: [],
  imports: [
    ConfigModule.withConfig(cmsConfig),
  ],
  exports: []
})
export class CustomBackendToFrontendComponentMappingModule { }
