import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { CmsNavigationComponent } from '@spartacus/core';
import { CmsComponentData, NavigationService } from '@spartacus/storefront';
import { MenuComponentOptions } from '@util/components/menu/menu.component';
import { ActivateElementOptions } from '@util/directives/activate-element/activate-element.directive';
import { ServicesCustomNavigationInsertComponent } from './menu-inserts/services/services-custom-navigation-insert.component';
import { UserFeaturesCustomNavigationInsertComponent } from './menu-inserts/user-features/user-features-custom-navigation-insert.component';
import { UserCustomNavigationInsertComponent } from './menu-inserts/user/user-custom-navigation-insert.component';

@Component({
  selector: 'app-custom-navigation',
  templateUrl: './custom-navigation.component.html',
  styleUrls: ['./custom-navigation.component.scss']
})
export class CustomNavigationComponent implements OnInit, OnDestroy {

  navigationNode$ = this.navigationService.getNavigationNode(this.componentData.data$);

  menuOptions: MenuComponentOptions = {
    inserts: [
      {component: UserCustomNavigationInsertComponent},
      {component: ServicesCustomNavigationInsertComponent, data: {node$: this.navigationNode$}},
      {component: UserFeaturesCustomNavigationInsertComponent},
    ],
    noAutomaticTranslations: false,
    closesOnOutsideClick: true,
    menuCSSPosition: 'absolute'
  };

  activateElementOptions: ActivateElementOptions = {
    events: ['keydown']
  };

  constructor(
    private componentData: CmsComponentData<CmsNavigationComponent>,
    private navigationService: NavigationService,
    private elementRef: ElementRef
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
