
<ng-container *ngIf="injectedData.node$ | async as nodes">

  <ng-container *ngFor="let node of nodes.children">

    <ng-container *ngIf="node?.url">

      <cx-generic-link *ngIf="node.url"
        [url]="node.url"
        [target]="node?.target || null"
        [ngClass]="['kurz-service-style']"
      >
        {{ node.title }}
      </cx-generic-link>

    </ng-container>

  </ng-container>

</ng-container>
