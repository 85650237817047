import { AfterViewInit, Component, HostBinding, OnDestroy, Optional, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService, BaseSiteService, CmsService, SiteContextActions, SiteContextParamsService, WindowRef } from '@spartacus/core';
import { UtilElementHelper } from '@util/functions/elements';
import { KurzShopContextElements, StatusCodeHttpInterceptor } from '@util/iterceptors/status-code-http-interceptor/status-code-http.interceptor';
import { UtilCustomCSSPropertyService } from '@util/services/util-custom-css-property.service';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AllowedBaseSite } from 'src/environments/kurz-env.type';
import { languageFallbackMap } from './custom/custom-configuration-modules/custom-translations.module';
import { KurzI18nextTranslationService } from './custom/services/kurz-i18next-translation.service';
import { KurzMyAccountService } from './custom/services/kurz-my-account.service';
import { CookieBannerAndGtmService } from './custom/services/cookie-banner-and-gtm.service';
import { DevConfigService } from './dev/dev-config.service';
import { UtilNavigationRule, UtilNavigationService } from './util/services/util-navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {

  private readonly utilNavigationService = inject(UtilNavigationService);
  private readonly authService = inject(AuthService);
  private readonly cmsService = inject(CmsService);
  private readonly kurz = inject(KurzI18nextTranslationService);
  protected siteContextParamsService = inject(SiteContextParamsService);
  private kurzMyAccountService = inject(KurzMyAccountService);
  private baseSiteService = inject(BaseSiteService);
  private readonly store = inject(Store);
  private utilCustomCSSPropertyService = inject(UtilCustomCSSPropertyService);
  private windowRef = inject(WindowRef);
  private cookieBannerAndGtmService = inject(CookieBannerAndGtmService);
  @Optional() private readonly devConfigService = inject(DevConfigService);

  isUserLoggedIn = false;
  page = 'page-label-undefined';

  @HostBinding('class')
  get clazzes(): string[] {
    const arr: string[] = [
      this.isUserLoggedIn ? 'authenticated-context' : 'not-authenticated-context',
      this.page
    ];

    return arr;
  }

  subscriptions: Subscription;

  slotInfo: any;

  constructor() {

    this.baseSiteService.get().subscribe(data => {
      environment.currentBaseSite = data.uid as AllowedBaseSite;
    });

    this.kurz.setFallbackLanguageMap(languageFallbackMap);

    this.subscriptions = new Subscription();

    this.subscriptions.add(
      this.authService.isUserLoggedIn().subscribe(result => {
        this.isUserLoggedIn = result;

        /*
          This is to assure that the user gets the correct languages in case a country switch occured beforehand
          Without this (or a manual refresh), the language will have been requested with the url before the country switch
        */
        this.store.dispatch(new SiteContextActions.LoadLanguages());
      })
    );

    this.subscriptions.add(
      this.cmsService.getCurrentPage().subscribe(page => {
        const unwantedPrefix = '/';
        const sliceLength = unwantedPrefix.length;
        let label = page?.label || page?.pageId || '';
        label = label.startsWith(unwantedPrefix) ? label.slice(sliceLength) : label;
        label = label.replaceAll(unwantedPrefix, '-');
        if (label) {
          this.page = 'page-label-' + label;
          this.slotInfo = page?.slots;
        }
      })
    );

    this.subscriptions.add(
      StatusCodeHttpInterceptor.getRedirectHeaders()
        .subscribe(headers => this.redirectContext(headers))
    );

    this.kurzMyAccountService.adaptToUserBackendLanguage();

    fillNavigationRuleMap(this.utilNavigationService);

    const debounceDelay = 1000 * 2;

    /**
     * listens to navigation changes and parameter changes and adds a virtual page view events for the gtm
     * adding a debounce time for cases where the algorithm navigates or changes parameter a multiple times but
     * only one persived action should be send to gtm
     * this is a quick and dirty solution but it works - better solution would be to remove the debounceTime and wait a certain time if multiple events with the same url are triggered then
     * send only the most informative page view event (last url with the most query parameters)
     */
    let lastUrl = '';
    const virtPageViewTagsSubscription = this.utilNavigationService.history$.pipe(filter(item => !!item), debounceTime(debounceDelay)).subscribe(item => {
      const url = item.url;
      const title = this.windowRef.nativeWindow.document.title;
      this.cookieBannerAndGtmService.addPageViewEvent(url, title, lastUrl);
      lastUrl = url;
    });

    this.subscriptions.add(virtPageViewTagsSubscription);

  }

  private redirectContext(headers: KurzShopContextElements): void {
    this.siteContextParamsService.setValue('baseSite', headers.baseSite);
    this.siteContextParamsService.setValue('language', headers.language);
    this.siteContextParamsService.setValue('currency', headers.currencyIso);
  }

  ngAfterViewInit(): void {
    const header = this.windowRef.document.querySelector('app-root cx-storefront header') as HTMLElement;
    this.subscriptions.add(
      UtilElementHelper.onElementResize(header).subscribe(_ => {
        if (header) {
          const box = header.getBoundingClientRect();
          this.utilCustomCSSPropertyService.setValue('--kurz-current-header-height', (box.top + box.height) + 'px' );
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
  }

  // comment in to see if there are too many change detection cycles
  //
  // ngAfterViewChecked(): void {
  //   console.log('AfterViewChecked');
  // }


}


function fillNavigationRuleMap(utilNavigationService: UtilNavigationService) {

  enum KurzPageId {
    Homepage = 'homepage',
    Cart = 'cartPage',
    CheckoutReviewOrder = 'CheckoutReviewOrder',
    SearchResult = 'search',
    ProductDetailsPage = 'productDetails',
    ProductList = 'productList',
    QuickOrder = 'quick-order',
    OrderHistory = 'orders',
    OrderDetailsPage = 'orderDetails',
    StockSale = 'stock-sale',
    Contracts = 'contracts',
    PriceList = 'price-list',
    MyAccount = 'my-details',
    StandardCutSizes = 'standard-cut-sizes',
    VideoTutorials = 'video-tutorials',
    Login = 'login',
    Contact = 'contactUs',
    ImpressumDE = 'impressum_de',
    ImpressumUS = 'impressum_us',
    ImpressumFR = 'impressum_fr',
    ImpressumCH = 'impressum_ch',
    ImpressumMY = 'impressum_my',
    ImpressumUK = 'impressum_uk',
    ImpressumNL = 'impressum_nl',
    ImpressumAU = 'impressum_au',
    ImpressumNZ = 'impressum_nz',
    TermsAndConditions = 'termsAndConditions',
    TermsOfUse = 'termsOfUse',
    DataProtection = 'dataProtection',
    NotFound = 'notFound'
  }

  const pageIdToNavigationRuleMap = new Map<string, UtilNavigationRule>([
    [KurzPageId.Homepage, UtilNavigationRule.None],
    [KurzPageId.Cart, UtilNavigationRule.PageBack],
    [KurzPageId.CheckoutReviewOrder, UtilNavigationRule.None],
    [KurzPageId.SearchResult, UtilNavigationRule.None],
    [KurzPageId.ProductDetailsPage, UtilNavigationRule.PageBack],
    [KurzPageId.ProductList, UtilNavigationRule.None],
    [KurzPageId.QuickOrder, UtilNavigationRule.PageBack],
    [KurzPageId.OrderHistory, UtilNavigationRule.None],
    [KurzPageId.OrderDetailsPage, UtilNavigationRule.ToOrderHistory],
    [KurzPageId.StockSale, UtilNavigationRule.None],
    [KurzPageId.Contracts, UtilNavigationRule.None],
    [KurzPageId.PriceList, UtilNavigationRule.None],
    [KurzPageId.MyAccount, UtilNavigationRule.None],
    [KurzPageId.StandardCutSizes, UtilNavigationRule.None],
    [KurzPageId.VideoTutorials, UtilNavigationRule.None],
    [KurzPageId.Login, UtilNavigationRule.None],
    [KurzPageId.Contact, UtilNavigationRule.PageBack],
    [KurzPageId.ImpressumDE, UtilNavigationRule.PageBack],
    [KurzPageId.ImpressumUS, UtilNavigationRule.PageBack],
    [KurzPageId.ImpressumFR, UtilNavigationRule.PageBack],
    [KurzPageId.ImpressumCH, UtilNavigationRule.PageBack],
    [KurzPageId.ImpressumMY, UtilNavigationRule.PageBack],
    [KurzPageId.ImpressumUK, UtilNavigationRule.PageBack],
    [KurzPageId.ImpressumNL, UtilNavigationRule.PageBack],
    [KurzPageId.ImpressumAU, UtilNavigationRule.PageBack],
    [KurzPageId.ImpressumNZ, UtilNavigationRule.PageBack],
    [KurzPageId.TermsAndConditions, UtilNavigationRule.PageBack],
    [KurzPageId.TermsOfUse, UtilNavigationRule.PageBack],
    [KurzPageId.DataProtection, UtilNavigationRule.PageBack],
    [KurzPageId.NotFound, UtilNavigationRule.OnlyHomepage]
  ]);

  utilNavigationService.addNavigationRuleMap(pageIdToNavigationRuleMap);

}
