/* eslint-disable @angular-eslint/component-max-inline-declarations */
import { Component, Injector, OnInit, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicMenuInsertComponent } from '@util/components/menu/basic-menu-insert.component';
import { KurzMyAccountService } from 'src/app/custom/services/kurz-my-account.service';
import { KurzMyProfile } from 'src/app/custom/kurz-components/kurz-my-account/kurz-my-profile/kurz-my-profile.component';


@Component({
  templateUrl: './user-custom-navigation-insert.component.html',
  styleUrls: ['./user-custom-navigation-insert.component.scss']
})
export class UserCustomNavigationInsertComponent extends BasicMenuInsertComponent<{ text: string; }> implements OnInit {

  user$: Observable<KurzMyProfile> = this.kurzMyAccountService.getProfile();

  constructor(@Optional() injector: Injector, private readonly kurzMyAccountService: KurzMyAccountService) {
    super(injector);
  }

  ngOnInit(): void { }

}

