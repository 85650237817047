<app-custom-language-selector></app-custom-language-selector>

<app-button
  [label]="'common.logout' | cxTranslate"
  [colorTheme]="['BLACK']"
  buttonSize="SMALL"
  iconType="LOGOUT"
  (activateElement)="logout()"
  flex
></app-button>
