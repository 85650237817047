import { Component, inject } from '@angular/core';
import { CmsNavigationComponent } from '@spartacus/core';
import { CmsComponentData, NavigationService } from '@spartacus/storefront';
import { map } from 'rxjs/operators';
import { CustomFooterService } from './custom-footer.service';

@Component({
  selector: 'app-custom-footer-navigation',
  templateUrl: './custom-footer-navigation.component.html',
  styleUrls: ['./custom-footer-navigation.component.scss']
})
export class CustomFooterNavigationComponent {

  private componentData = inject(CmsComponentData<CmsNavigationComponent>);
  private navigationService = inject(NavigationService);
  private customFooterService = inject(CustomFooterService);

  links$ = this.navigationService.getNavigationNode(this.componentData.data$).pipe(
    map(data => (data?.children?.[0]))
  );

  style$ = this.customFooterService.style$;

  openCookieBanner() {
    this.customFooterService.showCookieBanner();
  }

}
