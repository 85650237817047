import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule, provideConfig} from '@spartacus/core';
import {CmsStructureOptions, LayoutConfig, provideCmsStructure} from '@spartacus/storefront';

const layoutConfig: LayoutConfig = {
  // deferredLoading: {
  //   strategy: DeferLoadingStrategy.DEFER,
  //   intersectionMargin: '50px',
  // },
  layoutSlots: {
    header: {
      lg: {
        slots: [
          'ShopMessage', // Created by the FE
          'PreHeader', // Created by the FE
          'SearchBox',
          'MiniCart',
          'CountrySelector', // Created by the FE
          'CustomSiteLogo',
        ],
      },
      slots: ['ShopMessage','PreHeader', 'SearchBox', 'MiniCart', 'CountrySelector', 'CustomSiteLogo'],
    },
    navigation: {
      lg: { slots: ['NavigationBar', 'KurzBreadcrumb', 'KurzUserRedirect'] },
      slots: ['NavigationBar', 'KurzBreadcrumb', 'KurzUserRedirect'],
    },
    // footer: {
    //   slots: ['Footer'],
    // },
    // LandingPage2Template: {
    //   pageFold: 'Section2B',
    //   slots: [
    //     'Section1',
    //     'Section2A',
    //     'Section2B',
    //     'Section2C',
    //     'Section3',
    //     'Section4',
    //     'Section5',
    //   ],
    // },
    // ./standard-cut-sizes
    StandardCutSizesPageTemplate: { slots: ['TableSlot'] },
    // ./video-tutorials
    VideoTutorialsPageTemplate: { slots: ['VideoPageDescriptionSlot'] },
    UnitsOfMeasurementPageTemplate: { slots: ['BodyContent'] },
    NewOrderPageTemplate: {
      slots: ['BodyContent']
    },
    CartPageTemplate: {
      // one of these two gets excluded by Spartacus' CartPageLayoutHandler, depending on the totalItems
      slots: ['TopContent', 'EmptyCartMiddleContent'],
    },
    PriceListPageTemplate: {
      slots: ['BodyContent']
    },
    StockSalePageTemplate: {
      slots: ['TopHeaderSlot', 'BodyContent']
    },
    QuickOrderPageTemplate: {
      slots: [
        'Section1',
        'Section3',
      ],
    },
    CheckoutReviewOrderPageTemplate: {
      slots: ['BodyContent', 'SideContent']
    },
    OrderConfirmationPageTemplate: {
      slots: ['BodyContent']
    },
    ProductListPageTemplate: {
      slots: [/*'ProductLeftRefinements', */'ProductListSlot']
    },
    SearchResultsListPageTemplate: {
      slots: [/*'ProductLeftRefinements', */'SearchResultsListSlot']
    },
    LoginPageTemplate: {
      slots: ['LeftContentSlot', 'BottomHeaderSlot', 'RightContentSlot']
    },
    ImprintPageTemplateDE: {
      slots: ['BodyContent', 'Section2B']
    },
    ImprintPageTemplateUS: {
      slots: ['BodyContent', 'Section2B']
    },
    ImprintPageTemplateFR: {
      slots: ['BodyContent', 'Section2B']
    },
    ImprintPageTemplateCH: {
      slots: ['BodyContent', 'Section2B']
    },
    ImprintPageTemplateMY: {
      slots: ['BodyContent', 'Section2B']
    },
    ImprintPageTemplateUK: {
      slots: ['BodyContent', 'Section2B']
    },
    ImprintPageTemplateNL: {
      slots: ['BodyContent', 'Section2B']
    },
    ImprintPageTemplateAU: {
      slots: ['BodyContent', 'Section2B']
    },
    ImprintPageTemplateNZ: {
      slots: ['BodyContent', 'Section2B']
    },
    ProductFinderTemplate: {
      slots: ['BodyContent', 'Section3']
    }
  }
};

@NgModule({
  declarations: [],
  imports: [
    ConfigModule.withConfig(layoutConfig),
  ],
  providers: [
    //#region - mapping a component to a mocked content slot
    // provideCmsStructure({
    //   componentId: 'MockProductOrderFormComponent',
    //   pageSlotPosition: 'ProductSummarySlotMock'
    // }),
    // provideConfig({
    //   cmsComponents: {
    //     MockProductOrderFormComponent: {
    //       component: () => import('../kurz-components/kurz-product-details/kurz-product-order-form/kurz-product-order-form.component')
    //                         .then(c => c.KurzProductOrderFormComponent)
    //     }
    //   }
    // } as CmsConfig)
    //#endregion
    provideCmsStructure(
      {
        componentId: 'CustomSiteLogoComponent',
        pageSlotPosition: 'CustomSiteLogo'
      } as CmsStructureOptions,
    ),
    provideConfig({
      cmsComponents: {
        CustomSiteLogoComponent: {
          component: () => import('../custom-components/custom-site-logo/custom-site-logo.component')
            .then(c => c.CustomSiteLogoComponent)
        }
      }
    } as CmsConfig),

    //
    provideCmsStructure(
      {
        componentId: 'KurzShopMessageComponent',
        pageSlotPosition: 'ShopMessage'
      } as CmsStructureOptions,
    ),
    provideConfig({
      cmsComponents: {
        KurzShopMessageComponent: {
          component: () => import('../kurz-components/kurz-shop-message/kurz-shop-message.component')
            .then(c => c.KurzShopMessageComponent)
        }
      }
    } as CmsConfig),

    //
    provideCmsStructure(
      {
        componentId: 'CustomPreHeaderComponent',
        pageSlotPosition: 'PreHeader'
      } as CmsStructureOptions,
    ),
    provideConfig({
      cmsComponents: {
        CustomPreHeaderComponent: {
          component: () => import('../custom-components/custom-pre-header/custom-pre-header.component')
            .then(c => c.CustomPreHeaderComponent)
        }
      }
    } as CmsConfig),

    // FE-only country selector in the header
    provideCmsStructure(
      {
        componentId: 'KurzCountrySelectorSlotComponent',
        pageSlotPosition: 'CountrySelector'
      } as CmsStructureOptions,
    ),
    provideConfig({
      cmsComponents: {
        KurzCountrySelectorSlotComponent: {
          component: () => import('../kurz-components/shared/kurz-country-selector-slot/kurz-country-selector-slot.component')
            .then(c => c.KurzCountrySelectorSlotComponent)
        }
      }
    } as CmsConfig),

    // FE-only breadcrumb component
    provideCmsStructure(
      {
        componentId: 'KurzBreadcrumbComponent',
        pageSlotPosition: 'KurzBreadcrumb'
      } as CmsStructureOptions,
    ),
    provideConfig({
      cmsComponents: {
        KurzBreadcrumbComponent: {
          component: () => import('../kurz-components/kurz-breadcrumb/kurz-breadcrumb.component')
            .then(c => c.KurzBreadcrumbComponent)
        }
      }
    } as CmsConfig),
    provideConfig({
      cmsComponents: {
        'KurzBreadcrumbComponent': {
          module: () => import('../kurz-components/kurz-breadcrumb/kurz-breadcrumb.module').then(m => m.KurzBreadcrumbModule),
          cmsComponents: [
            'KurzBreadcrumbComponent'
          ]
        },
      }
    } as CmsConfig),

    // --
    provideCmsStructure(
      {
        componentId: 'KurzUserRedirectListComponent',
        pageSlotPosition: 'KurzUserRedirect'
      } as CmsStructureOptions,
    ),
    provideConfig({
      cmsComponents: {
        KurzUserRedirectListComponent: {
          component: () => import('../kurz-components/kurz-hompage/kurz-user-redirect-list/kurz-user-redirect-list.component')
            .then(c => c.KurzUserRedirectListComponent)
        }
      }
    } as CmsConfig),
    provideConfig({
      featureModules: {
        'KurzUserRedirectListComponent': {
          module: () => import('../kurz-components/kurz-hompage/kurz-user-redirect-list/kurz-user-redirect-list.module').then(m => m.KurzUserRedirectListModule),
          cmsComponents: [
            'KurzUserRedirectListComponent'
          ]
        }
      }
    })
    // --
  ],
  exports: []
})
export class CustomLayoutModule { }
