/* eslint-disable @angular-eslint/component-max-inline-declarations */
import { Component, Injector, OnInit, Optional } from '@angular/core';
import { CheckboxDefaultRenderingComponent } from '@util/components/checkbox/checkbox-renderings/checkbox-default-rendering.component';


@Component({
  template: `
    <p>{{checkboxRef.checked ? '(x)' : '(_)' }}: {{raw}}<p>
  `,
  styles: [':host { display: block; margin-left: 5px; margin-right: 5px; }']
})
export class DevCheckboxRenderingComponent extends CheckboxDefaultRenderingComponent<{raw: string;}> implements OnInit {

  ngOnInit(): void {}

  get raw(): string {
    return this.injectedData.raw;
  }

}
