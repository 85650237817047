import { Injectable } from '@angular/core';
import { BasePageMetaResolver } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class CustomBaseMetaResolver extends BasePageMetaResolver {

  protected override description$ = this.translation.translate('metaTags.description');

  protected override title$ = this.translation.translate('metaTags.title');

}
