import { Injectable, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { AsmAuthStorageService, CsAgentAuthService } from '@spartacus/asm/root';
import { AuthService, UserIdService, OAuthLibWrapperService, AuthRedirectService, FeatureConfigService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { UserProfileFacade } from '@spartacus/user/profile/root';


@Injectable({providedIn: 'root'})
export class CustomCsAgentAuthService extends CsAgentAuthService {

  constructor(
    authService: AuthService,
    authStorageService: AsmAuthStorageService,
    userIdService: UserIdService,
    oAuthLibWrapperService: OAuthLibWrapperService,
    store: Store,
    userAccountFacade: UserAccountFacade,
    protected authRedirectService: AuthRedirectService,
    @Optional() featureConfig?: FeatureConfigService,
  ) {
    super(authService, authStorageService, userIdService, oAuthLibWrapperService, store, userAccountFacade);
  }

  override startCustomerEmulationSession(customerId: string): void {
    // starting a new CustomerEmulationSession
    super.startCustomerEmulationSession?.(customerId);
    // redirect like in AuthService.loginWithCredentials() (a.k.a. the normal user login)
    // See COM-128
    this.authRedirectService.redirect();
  }

}

