import { Component, inject } from '@angular/core';
import { LanguageService } from '@spartacus/core';
import { Observable, switchMap } from 'rxjs';
import { KurzBaseStoreCompanyProperties } from 'src/app/custom/kurz-components/shared/types/kurz-base-store-company-properties.interface';
import { KurzMyAccountService } from 'src/app/custom/services/kurz-my-account.service';

@Component({
  selector: 'app-custom-over-footer-information',
  templateUrl: './custom-over-footer-information.component.html',
  styleUrl: './custom-over-footer-information.component.scss'
})
export class CustomOverFooterInformationComponent {

  private myAccountService = inject(KurzMyAccountService);
  private languageService = inject(LanguageService);

  baseStoreProps$: Observable<KurzBaseStoreCompanyProperties | null> = this.languageService.getActive()
  .pipe(
    switchMap(_ => this.myAccountService.getBaseStoreCompanyProperties())
  );

}
