
<!-- <app-custom-navigation-ui [node]="node$ | async" [ngClass]="styleClass$ | async"></app-custom-navigation-ui> -->

<ng-container *ngIf="true">

  <app-icon-button
    iconType="USER"
    [colorTheme]="['NONE']"
    size="MEDIUM"
    (activateElement)="menu.toggle()"
  ></app-icon-button>

  <app-menu [options]="menuOptions" #menu="app-menu"></app-menu>

</ng-container>

