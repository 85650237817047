import { NgModule } from '@angular/core';
import { FeaturesConfig, provideConfig } from '@spartacus/core';
import { environment } from '../../../environments/environment';


@NgModule({
  providers: [
    provideConfig({
      features: {
        level: environment.spartacusVersion
      }
    } as FeaturesConfig),
  ],
  exports: []
})
export class CustomFeatureConfigModule { }
