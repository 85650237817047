import { NgModule } from '@angular/core';
import { provideConfig, RoutingConfig } from '@spartacus/core';
import { environment } from 'src/environments/environment';


@NgModule({
  providers: [
    provideConfig({
      routing: {
        // flag, whether all routes in general needs the user to be logged in
        // if this flag is false, the "protected" flag of the single routes is ignored
        protected: false,
        routes: {
          home: { // override
            paths: [''],
            protected: false
          },
          contact: {
            paths: ['contact'],
            protected: false
          },
          impressum: {
            paths: ['impressum'],
            protected: false
          },
          termsAndConditions: {
            paths: ['terms-and-conditions'],
            protected: false
          },
          termsOfUse: {
            paths: ['terms-of-use'],
            protected: false
          },
          dataProtection: {
            paths: ['data-protection'],
            protected: false
          },
          landing: {
            paths: ['landing'],
            protected: false
          },
        }
      }
    } as RoutingConfig),
  ],
  exports: []
})
export class CustomRoutingModule { }
