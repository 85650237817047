import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';


@Injectable()
export class EmulatedUserIdInterceptor implements HttpInterceptor {

  isEmulated: boolean;
  emulatedUserId: string;

  constructor(
    private occEndpoints: OccEndpointsService,
    protected userIdService: UserIdService,
  ) {
    this.userIdService.isEmulated().subscribe(isE => this.isEmulated = isE);
    this.userIdService.getUserId().subscribe(id => this.emulatedUserId = id);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.isEmulated && request.url.includes(this.occEndpoints.getBaseUrl())) {
      request = request.clone({
        setParams: {
          emulatedUserId: this.emulatedUserId
        },
      });
    }

    return next.handle(request);
  }

}
