import { AfterViewInit, Component, ElementRef, OnDestroy, inject } from '@angular/core';
import { AuthService, WindowRef } from '@spartacus/core';
import { AdaptiveTemplateLayout } from '@util/components/adaptive-template-server/adaptive-template-server.component';
import { UtilCustomCSSPropertyService } from '@util/services/util-custom-css-property.service';
import { Observable, Subscription, combineLatest, fromEvent } from 'rxjs';
import { distinctUntilChanged, map, skip, startWith, take, tap, throttleTime } from 'rxjs/operators';
import { KurzCartService } from '../../services/kurz-cart.service';
import { Router } from '@angular/router';
import { CustomMiniCartService } from './custom-mini-cart.service';
import { CustomPreHeaderService } from '../custom-pre-header/custom-pre-header.service';

@Component({
  selector: 'app-custom-mini-cart',
  templateUrl: './custom-mini-cart.component.html',
  styleUrls: ['./custom-mini-cart.component.scss']
})
export class CustomMiniCartComponent implements AfterViewInit, OnDestroy {

  protected kurzCartService = inject(KurzCartService);
  protected utilCustomCSSPropertyService = inject(UtilCustomCSSPropertyService);
  protected elementRef = inject(ElementRef);
  protected windowRef = inject(WindowRef);
  private readonly authService = inject(AuthService);
  protected router = inject(Router);
  private customPreHeaderService = inject(CustomPreHeaderService);

  protected customMiniCartService = inject(CustomMiniCartService);

  private mouseTrackingSub: Subscription;
  private elementTrackingSub: Subscription;


  public labelKey: Observable<'miniCartLabel' | 'toCartPageButton'>;

  adaptiveTemplateLayout: AdaptiveTemplateLayout = {
    default: ['iconButton'],
    breakpoints: {
      xl: ['labelButton'],
      lg: ['iconButton'],
    },
    breakpointRule: 'allowSmaller'
  };

  totalItems$: Observable<number> = this.kurzCartService.getActiveCart().pipe(
    map(cart => (cart?.totalItems || 0))
  );

  open$ = this.customMiniCartService.open$;

  loggedIn$ = this.authService.isUserLoggedIn().pipe(
    distinctUntilChanged(),
    tap(isUserL => {
      // eslint-disable-next-line no-magic-numbers
      this.customMiniCartService.overviewWidth = isUserL ? 600 : 400; // NOSONAR
      this.cartButtonTracking();
    })
  );

  constructor() {
    this.labelKey = this.open$.pipe(map(isOpen => isOpen ? 'toCartPageButton' : 'miniCartLabel'));
  }

  ngAfterViewInit(): void {

    this.customMiniCartService.setNativeMiniCartElement(this.elementRef.nativeElement);

    this.elementTrackingSub = combineLatest([
      this.open$,
      fromEvent(this.windowRef.nativeWindow, 'resize').pipe(
        startWith(null), // making sure that the fromEvent observable has a value and avoid the main gotcha behavior of combineLatest()
        throttleTime(250)
      )
    ]).subscribe(([isOpen, _]) => {
      if (isOpen) {
        this.cartButtonTracking();
      }
    });
  }

  ngOnDestroy(): void {
    this.elementTrackingSub?.unsubscribe();
    this.mouseTrackingSub?.unsubscribe();
  }

  goToCart() {
    this.customMiniCartService.close();
    this.kurzCartService.goToCartPage();
  }

  mouseEnter(e: MouseEvent) {

    if (this.isSearchBoxActive()) {
      return;
    }

    let cartItems = 0;
    this.totalItems$.pipe(take(1)).subscribe(num => cartItems = num);

    // no need to open if user has no items in the cart or is already on the cart page or checkout page or the screen is too small
    if (
      cartItems === 0 ||
      this.kurzCartService.onCheckoutReviewOrderPage ||
      this.kurzCartService.onCartPage ||
      this.customMiniCartService.isViewportTooSmallToOpen()
    ) {
      return;
    }

    this.customMiniCartService.open();

  }

  goToLogin() {
    this.customMiniCartService.close();
    this.customPreHeaderService.toggle();
  }

  mouseEnterLogin(e: MouseEvent) {

    // no need to open if user has no items in the cart or is already on the cart page or checkout page or the screen is too small
    if (this.customMiniCartService.isViewportTooSmallToOpen()) {
      return;
    }

    this.customMiniCartService.open();

  }

  private cartButtonTracking() {

    const host = this.elementRef.nativeElement as HTMLElement;
    const button = host.querySelector('app-icon-button,app-button') as HTMLElement;

    const rect = button?.getBoundingClientRect() || {left: 0, width: 100, top: 0, height: 50};
    const vp = this.windowRef.nativeWindow.visualViewport;

    // calculate x and y coordinate of the overview box
    let x = rect.left + (rect.width / 2) - (this.customMiniCartService.overviewWidth / 2);
    const y = rect.top + rect.height;

    // default max height and overviewWidth
    let maxHeight = this.customMiniCartService.maxHeight;
    let overviewWidth = this.customMiniCartService.overviewWidth;

    // if is negative it would display the box offscreen on the left
    if (x < 0) {
      x = 0;
    }

    // calculate if the overview box would be too wide and would display partially offscreen on the right
    const rightOffscreenX = (x + overviewWidth) - vp.width;
    // if so, reduce x
    if (rightOffscreenX > 0) {
      x -= rightOffscreenX;
    }

    // calculate if the overview box would be too long and would display partially off screen
    const offscreenY = (y + maxHeight) - vp.height;
    // if so, maxheight
    if (offscreenY > 0) {
      maxHeight -= offscreenY;
    }

    this.utilCustomCSSPropertyService.setValue('--_x', x +'px', host);
    this.utilCustomCSSPropertyService.setValue('--_y', y +'px', host);
    this.utilCustomCSSPropertyService.setValue('--_overview-width', overviewWidth +'px', host);
    this.utilCustomCSSPropertyService.setValue('--_overview-max-height', maxHeight +'px', host);

  }

  private isSearchBoxActive(): boolean {
    return document.body.classList.contains('searchbox-is-active');
  }

}
