import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OccEndpointsService } from '@spartacus/core';
import { KurzB2bUnitChooserService } from '../kurz-components/kurz-b2b-unit-chooser/kurz-b2b-unit-chooser.service';

@Injectable()
export class B2bUnitInterceptor implements HttpInterceptor {

  private kurzB2BUnitChooserService: KurzB2bUnitChooserService;

  constructor(
    private occEndpoints: OccEndpointsService,
    private injector: Injector
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.kurzB2BUnitChooserService = this.injector.get(KurzB2bUnitChooserService);

    if (this.kurzB2BUnitChooserService.currentB2BUnit && request.url.includes(this.occEndpoints.getBaseUrl())) {
      request = request.clone({
        setParams: {
          b2bUnit: this.kurzB2BUnitChooserService.currentB2BUnit
        }
      });
    }

    return next.handle(request);
  }
}
