
<app-custom-over-footer-information></app-custom-over-footer-information>

<div class="footer-navigation-bar" [ngClass]="(style$ | async)">
  <div class="copyright-label">© KURZ</div>

  <div class="footer-links">
    <div role="button" class="pseudo-link" (activateElement)="openCookieBanner()" tabindex="0">Cookie Banner</div>

    @if (links$ | async; as links) {
      @for (link of links.children; track $index) {
          <cx-generic-link *ngIf="link.url"
            [url]="link.url"
            [target]="link.target"
            [ngClass]="['kurz-footer-style']"
          >
            {{ link.title }}
          </cx-generic-link>
      }
    }
  </div>

</div>
