<div class="omnipresent-floating-contact-button" [ngClass]="(contactMenu.open$ | async) ? '':'is-closed'" *ngIf="menuOptions$ | async as options">
  <app-menu [options]="options" #contactMenu="app-menu"></app-menu>
  <app-button
    [label]="'helpMenu.helpLabel' | cxTranslate"
    [buttonSize]="'MEDIUM'"
    [iconType]="'HELP'"
    (activateElement)="contactMenu.toggle()"
    [colorTheme]="['PRIMARY', 'BASE_LIGHT']"
  ></app-button>
</div>
