
<ng-container *ngIf="entry$ | async as entry">

  <cx-media
    class="entry-product-cx-media"
    [container]="entry?.product?.thumbnail"
    format="thumbnail"
    [alt]="entry?.product?.name"
  ></cx-media>

  <div class="entry-product-name">{{ entry.product.name }}</div>

  <div class="entry-configuration">
    <app-kurz-configuration-display [configData]="configData$ | async" [commonElementMatcher]="'.cart-entries'"></app-kurz-configuration-display>
  </div>

  <div class="entry-secondary-information">
    <app-kurz-configuration-display [configData]="secondaryConfigData$ | async" [commonElementMatcher]="'.cart-entries'"></app-kurz-configuration-display>
  </div>

  <div class="entry-price">
    <app-kurz-differently-styled-value
      [valueObjects]="(formattedTotalPrice$ | async) | differentlyStyledFirstNumber : [null, 1, {fontWeight: 'bold'}] : [null, 0.8] "
    ></app-kurz-differently-styled-value>
  </div>

</ng-container>
