/* eslint-disable @angular-eslint/component-max-inline-declarations */
import { Component, Injector, Optional, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@spartacus/core';
import { BasicMenuInsertComponent } from '@util/components/menu/basic-menu-insert.component';


@Component({
  templateUrl: './user-features-custom-navigation-insert.component.html',
  styleUrls: ['./user-features-custom-navigation-insert.component.scss']
})
export class UserFeaturesCustomNavigationInsertComponent extends BasicMenuInsertComponent<void> {

  private authService = inject(AuthService);
  private router = inject(Router);

  constructor(@Optional() injector: Injector) {
    super(injector);
  }

  async logout() {
    await this.router.navigateByUrl('/logout');
    // await this.authService.coreLogout();
  }

}

