import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { SmartEditConfig } from '@spartacus/smartedit/root';
import { environment } from '../../../environments/environment';

@NgModule({
  providers: [
    provideConfig(<SmartEditConfig>{
      smartEdit: environment.smartEdit
    })
  ]
})
export class CustomSmartEditFeatureModule { }
