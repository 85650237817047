import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMiniCartComponent } from './custom-mini-cart.component';
import { IconButtonModule } from '@util/components/icon-button/icon-button.module';
import { MenuModule } from '@util/components/menu/menu.module';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';
import { I18nModule } from '@spartacus/core';
import { ButtonModule } from '@util/components/button/button.module';
import { MediaModule } from '@spartacus/storefront';
import { AdaptiveTemplateServerModule } from '@util/components/adaptive-template-server/adaptive-template-server.module';
import { SlidingContentModule } from '@util/components/sliding-content/sliding-content.module';
import { CustomMiniCartOverviewComponent } from './custom-mini-cart-overview/custom-mini-cart-overview.component';
import { KurzCartSideMenuEntryComponent } from './custom-mini-cart-overview/custom-mini-cart-overview-entry/custom-mini-cart-overview-entry.component';
import { KurzPriceDisplayModule } from '../../kurz-components/shared/kurz-price-display/kurz-price-display.module';
import { KurzConfigurationDisplayModule } from '../../kurz-components/shared/kurz-configuration-display/kurz-configuration-display.module';
import { KurzDifferentlyStyledValueModule } from '../../kurz-components/shared/kurz-differently-styled-value/kurz-differently-styled-value.module';
import { DifferentlyStyledStringParserPipesModule } from '../../kurz-components/shared/kurz-differently-styled-value/string-parser/pipes/differently-styled-string-parser-pipes.module';
import { KurzCountrySelectorModule } from '../../kurz-components/shared/kurz-country-selector/kurz-country-selector.module';
import { CustomLoginFormModule } from '../custom-login-form/custom-login-form.module';



@NgModule({
  declarations: [
    CustomMiniCartComponent,
    CustomMiniCartOverviewComponent,
    KurzCartSideMenuEntryComponent
  ],
  imports: [
    CommonModule,
    IconButtonModule,
    ButtonModule,
    MenuModule,
    MediaModule,
    I18nModule,
    ActivateElementModule,
    AdaptiveTemplateServerModule,
    SlidingContentModule,
    KurzPriceDisplayModule,
    KurzConfigurationDisplayModule,
    KurzDifferentlyStyledValueModule,
    DifferentlyStyledStringParserPipesModule,
    KurzCountrySelectorModule,
    CustomLoginFormModule
  ]
})
export class CustomMiniCartModule { }
