/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CmsActivatedRouteSnapshot, CmsService, ProtectedRoutesGuard, RouteLoadStrategy, RoutingConfigService, RoutingService, isNotUndefined } from '@spartacus/core';
import { CmsPageGuard, CmsPageGuardService } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { filter, first, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KurzCmsPageGuard extends CmsPageGuard {

  constructor(
    routingService: RoutingService,
    cmsService: CmsService,
    protectedRoutesGuard: ProtectedRoutesGuard,
    service: CmsPageGuardService,
    routingConfig: RoutingConfigService
  ) {
    super(routingService, cmsService, protectedRoutesGuard, service, routingConfig);
  }

  // override canActivate(route: CmsActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

  //   const path = route?.url?.join('/');
  //   // console.log('KurzCmsPageGuard - path:', path);

  //   const ret = this.protectedRoutesGuard.canActivate(route).pipe(
  //     switchMap((canActivate) => {

  //       // console.log('KurzCmsPageGuard - canActivate', canActivate);

  //       if (canActivate === true) {

  //         return this.routingService.getNextPageContext().pipe(
  //           filter(isNotUndefined),
  //           take(1),
  //           switchMap(pageContext => {

  //             // console.log('KurzCmsPageGuard - pageContext', pageContext);

  //             return this.cmsService.getPage(pageContext, this.shouldReload2()).pipe(
  //               first(),
  //               switchMap(pageData => {

  //                 // console.log('KurzCmsPageGuard - pageData', pageData);

  //                 if (pageData) {
  //                   return this.service.canActivatePage(pageContext, pageData, route, state);
  //                 } else {
  //                   return this.service.canActivateNotFoundPage(pageContext, route, state);
  //                 }
  //               }),
  //               tap(res => {
  //                 // console.log('res:::', res);
  //               })
  //             );
  //           })
  //         );

  //       } else {
  //         return of(canActivate);
  //       }
  //     })
  //   );

  //   return ret;

  // }

  /**
   * Returns whether we should reload the CMS page data, even when it was loaded before.
   */
  // private shouldReload2(): boolean {
  //   return this.routingConfig.getLoadStrategy() !== RouteLoadStrategy.ONCE;
  // }
}
