import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';
import { KurzOmnipresentContactButtonComponent } from './kurz-omnipresent-contact-button.component';
import { ButtonModule } from '@util/components/button/button.module';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';
import { MenuModule } from '@util/components/menu/menu.module';
import { IconModule } from '@util/components/icon/icon.module';
import { KurzOmnipresentContactInsertComponent } from './kurz-omnipresent-contact-insert/kurz-omnipresent-contact-insert.component';



@NgModule({
  declarations: [
    KurzOmnipresentContactButtonComponent,
    KurzOmnipresentContactInsertComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    ButtonModule,
    ActivateElementModule,
    MenuModule,
  ],
  exports: [
    KurzOmnipresentContactButtonComponent
  ]
})
export class KurzOmnipresentContactButtonModule { }
