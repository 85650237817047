import { ChangeDetectionStrategy, Component, inject, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GlobalMessageService, TranslationService } from '@spartacus/core';
import { InputValueDebouncer } from '@util/classes/value-debouncer.class';
import { AdaptiveTemplateLayout } from '@util/components/adaptive-template-server/adaptive-template-server.component';
import { KurzButtonSize } from '@util/components/button/button.component';
import { CheckboxGroupDataSource } from '@util/components/checkbox-button-group/checkbox-group-data-source';
import { CheckboxRenderingData } from '@util/components/checkbox/checkbox-renderings/checkbox-default-rendering.types';
import { CustomTreeDataSource } from '@util/components/custom-tree/custom-tree-data-source';
import { BasicCustomTreeAction, CheckboxGroupCustomTreeNodeData, CustomTreeNode } from '@util/components/custom-tree/custom-tree-shared.types';
import { CheckboxGroupCustomTreeNodeInsertComponent } from '@util/components/custom-tree/inserts/checkbox-group-custom-tree-node-insert/checkbox-group-custom-tree-node-insert.component';
import { KurzIconButtonType } from '@util/components/icon-button/icon-button.component';
import { KurzIconSize } from '@util/components/icon/icon.component';
import { InputDefaultRenderingComponent } from '@util/components/input/input-default-rendering.component';
import { InputRenderingData } from '@util/components/input/input-rendering.types';
import { CustomPanelPartObject } from '@util/components/panel/panel.component';
import { BehaviorSubject, filter, map, Observable, tap } from 'rxjs';
import { KurzIconType } from '../custom/custom-configuration-modules/custom-icon-types-mapping.module';
import { DevCheckboxRenderingComponent } from './dev-checkbox-rendering/dev-checkbox-rendering.component';
import { DevPanelFooterComponent } from './dev-panel-footer/dev-panel-footer.component';
import { UtilOmnipresentFormGroupService } from '@util/services/util-omnipresent-form-group.service';

@Component({
  selector: 'app-dev',
  templateUrl: './dev.component.html',
  styleUrls: ['./dev.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevComponent implements OnInit {

  private utilOmnipresentFormGroupService = inject(UtilOmnipresentFormGroupService);

  isMockInvalid$ = this.utilOmnipresentFormGroupService.getFormGroupError$('mock').pipe(
    tap(errors => {
      console.log('getFormGroupError$(mock)', errors);
    }),
    filter(errors => !!errors),
    map(errors => errors.invalid)
  );

  inputText = 'abcdefghijklmnopqrstuvwxyz';

  inputRenderingData: InputRenderingData = {
    component: InputDefaultRenderingComponent,
    data: {
      test: 'TESTING'
    }
  };

  KurzIconButtonType = KurzIconButtonType;

  KurzIconType = KurzIconType;
  kurzIconTypeArray = Object.keys(KurzIconType);
  KurzIconSize = KurzIconSize;

  KurzButtonSize = KurzButtonSize;


  panelObject: CustomPanelPartObject = {
    footerComponent: DevPanelFooterComponent,
    data: {
      injectedData: {text: 'Hello! This is Footer'}
    }
  };

  testCheckboxRenderingData: CheckboxRenderingData<any> = {
    component: DevCheckboxRenderingComponent
  };

  rad = new CheckboxGroupDataSource([
    {
      label: 'Test 1', value: 1, injectedRenderingData: {raw: 'This is one point'}
    },
    {
      label: 'Test 2 aber etwas längerer Text', value: 2, injectedRenderingData: {raw: 'These are two points'}
    },
    {
      label: 'Test 3', value: 3, injectedRenderingData: {raw: 'That ain\'t 4 but 3 points'}
    },
    {
      label: 'Test 4 ...', value: 4, injectedRenderingData: {raw: '4 Points this one'}
    },
  ], this.testCheckboxRenderingData);

  private testBehaviorSubsubject = new BehaviorSubject<any>({});
  get testObservable(): Observable<any> {
    return this.testBehaviorSubsubject.asObservable();
  }

  adaptiveTemplateLayout: AdaptiveTemplateLayout = {
    default: ['message', 'berta'],
    breakpoints: {
      lg: ['albert', 'berta', 'message'],
      md: ['berta', 'albert'],
      sm: ['berta']
    }
  };

  _messageTypeTooltip: TemplateRef<any>;

  @ViewChild('messageTypeTooltip')
  set messageTypeTooltip(value: TemplateRef<any>) {
    console.log('messageTypeTooltip', value);
    this._messageTypeTooltip = value;
  }

  messageText = '';

  gridPersonVariable = {
    surname: '',
    lastname: '',
    age: '',
    uid: ''
  };

  checkboxCustomTreeDataSource = new CustomTreeDataSource({
    roots: [
      {
        value: 'Was isst du gerne?',
        children: [
          {
            value: 'food_items',
            component: CheckboxGroupCustomTreeNodeInsertComponent,
            data: <CheckboxGroupCustomTreeNodeData> {
              items: [
                {label: 'Brot', value: 'bread', selected: false},
                {label: 'Nudeln', value: 'noodles', selected: false},
                {label: 'Fleisch', value: 'meat', selected: false},
              ],
              isRadioButtonGroup: false
            }
          }
        ]
      },
      {
        value: 'Lieblingsfarbe?',
        children: [
          {
            value: 'color_items',
            component: CheckboxGroupCustomTreeNodeInsertComponent,
            data: <CheckboxGroupCustomTreeNodeData> {
              items: [
                {label: 'Rot', value: 'Red', selected: false},
                {label: 'Grün', value: 'Green', selected: false},
                {label: 'Blau', value: 'Blue', selected: false},
              ],
              isRadioButtonGroup: true
            }
          }
        ]
      }
    ]
  });

  booleanDebouncer = new InputValueDebouncer<boolean>(true, Boolean);

  constructor() {

    this.checkboxCustomTreeDataSource.registerActionType(BasicCustomTreeAction.CLICK).subscribe(action => {
      console.log('clicked on this node', action.data);
    });

    this.checkboxCustomTreeDataSource.registerActionType(BasicCustomTreeAction.CHECKBOX_SELECTION_CHANGE).subscribe(action => {
      console.log('selection changed', action.data);
    });

    this.booleanDebouncer.subscription = this.booleanDebouncer.getDebouncingObservable(1000).subscribe(val => {
      console.log('booleanDebouncer', val);
    });

  }

  ngOnInit(): void {}

  toggleLeft() {
  }

  toggleRight() {
  }

  selectCountry(item: any, origin: string) {
    console.log('selected a new country for ' + origin, item);
  }

  customIconAction(value: string) {
    console.log('customIconAction:', value);
  }


  filterCheckboxCustomTreeDataSource(term: string) {

    const sFn = (term: string, node: CustomTreeNode<CheckboxGroupCustomTreeNodeData>): boolean => {
      if (node?.data?.dataSource) {
        node.data.dataSource?.search?.(term);
      }
      return true;
    };

    this.checkboxCustomTreeDataSource.searchNodes(term, sFn);
  }

  luck() {
    console.log('Nothing implemented');
  }

  makeVacation(event?: any) {
    console.log('make Vacation', event);
  }

}
