import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CmsComponent, ContentSlotData } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { getRandomString, splitString } from '@util/functions/strings';

export interface SpartacusPagesResponseComponent {
  container?: boolean;
  flexType?: string;
  name?: string;
  typeCode?: string;
  [attr:string]: any;
}

export interface SpartacusPagesResponseContentSlot {
  components: { component: SpartacusPagesResponseComponent[]; };
  name?: string;
  position?: string;
  slotId?: string;
  [attr:string]: any;
}

export interface SpartacusPagesResponseBody {
  contentSlots: {contentSlot: SpartacusPagesResponseContentSlot[];};
  label?: string;
  name?: string;
  template?: string;
  title?: string;
  typeCode?: string;
  [attr:string]: any;
}

interface HttpResponseInterception {
  urlPart: string | RegExp;
  manipulaterFn: (body: any, req?: HttpRequest<any>) => any;
  take: number;
}

interface HttpRequestInterception {
  urlPart?: string | RegExp;
  manipulaterFn: (req?: HttpRequest<any>) => HttpRequestInterceptionResult;
  take: number;
}

// interface HttpRequestBreakout2 {
//   urlPart?: string | RegExp;
//   breakoutFn: (req?: HttpRequest<any>) => null | Observable<HttpResponse<any>>;
// }

interface HttpRequestBreakout {
  urlPart?: string | RegExp;
  identifyRequestFn: (req?: HttpRequest<any>) => boolean;
  responseBodyFn: (req?: HttpRequest<any>) => any;
}

interface HttpRequestGuard {
  urlPart: string | RegExp;
  identifyRequestFn: (req?: HttpRequest<any>) => boolean;
  errorMessageResponseFn: (req?: HttpRequest<any>) => string;
}

export interface HttpRequestInterceptionResult {
  changes?: {
    url?: string;
    body?: any;
    method?: string;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    withCredentials?: boolean;
  },
  block?: boolean;
}

export interface ReassurmentDestinguishingPageResponsesObject {
  uid?: string | RegExp;
  template?: string | RegExp;
  typeCode?: string | RegExp;
  name?: string | RegExp;
}

export enum FieldReassurmentRequestType {
  ProductDetails = '.*\/products\/(?!search|suggestions).*\?', // several negative lookaheads = (?!x|y|z)
  Cart = '.*\/carts\/.*\?',
  Component = '.*\/components\/.*\?'
}

export interface FieldsReassurement {
  /**
   * reg exp will be used to test if the wanted field already exists. If not set the string in 'field' will be used
   */
  match?: RegExp | string;
  /**
   * string will be in the request
   */
  value: string;
}

export interface ReassurmentPageResponseObject {
  destinguishes: ReassurmentDestinguishingPageResponsesObject;
  changes?: {
    path: string;
    value: any;
  }[];
}

export interface ReassuredComponent extends Partial<Omit<CmsComponent, 'modifiedTime'>> {
  modifiedTime?: string;
  uuid?: string;
  flexType: string;
  /**
   * reassures state (default: 'exists')
   */
  reassuredState?: 'exists' | 'removed';
  /**
   * splice(x, 0, this) will be used instead of just push(this)
   */
  spliceNumber?: number;
}

export interface SlotsComponentsReassurement extends Partial<ContentSlotData> {
  reassuredComponents: ReassuredComponent[];
  components?: never;
  slotId?: string;
  position: string;
  name?: string;
  [attr: string]: any;
}

@Injectable()
export class DevHttpInterceptor implements HttpInterceptor {


  private static _responseInterceptions: HttpResponseInterception[] = [];

  private static _requestInterceptions: HttpRequestInterception[] = [];

  private static _requestBreakout: HttpRequestBreakout[] = [];

  private static _requestGuard: HttpRequestGuard[] = [];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req) {

      const foundBreakout = DevHttpInterceptor._requestBreakout?.find(rb => {
        let yes = false;
        if (rb.urlPart instanceof RegExp) {
          yes = rb.urlPart.test(req.url);
        }
        if (typeof rb.urlPart === 'string') {
          yes = req.url?.includes(rb.urlPart);
        }
        return yes && !!(rb.identifyRequestFn(req));
      });

      if (foundBreakout) {
        const body = foundBreakout.responseBodyFn(req);
        console.warn('Request Breakout: Intercepting Request with hardcoded response', req, body);
        return of(new HttpResponse({ status: 200, body}));
      }

      const foundGuard = DevHttpInterceptor._requestGuard.find(rg => {
        let yes = false;
        if (rg.urlPart instanceof RegExp) {
          yes = rg.urlPart.test(req.url);
        }
        if (typeof rg.urlPart === 'string') {
          yes = req.url?.includes(rg.urlPart);
        }
        return yes && !!(rg.identifyRequestFn(req));
      });

      if (foundGuard) {
        const guardErrorMessage = foundGuard.errorMessageResponseFn(req);
        console.warn('Request Guard: Failing Request with hardcoded error message', req, guardErrorMessage);
        const res = {
          errors: [ {
            message: guardErrorMessage,
            type: 'RequestGuardFailure'
          }]
        }
        return of(new HttpResponse({status: 500, body: res}));
      }

      const foundAll = DevHttpInterceptor._requestInterceptions?.filter(ri => {
        let yes = false;
        if (ri.urlPart instanceof RegExp) {
          yes = ri.urlPart.test(req.url);
        }
        if (typeof ri.urlPart === 'string') {
          yes = req.url?.includes(ri.urlPart);
        }
        return yes;
      });

      foundAll.forEach(found => {
        if (found && found.take > 0) {
          const res = found.manipulaterFn(req) || {};
          if (res?.changes) {
            req = req.clone({...res.changes});
          }
          found.take -= 1;
          if (res.block) {
            return next.handle(req).pipe(take(0));
          }
        }
      });
    }

    return next.handle(req).pipe(map(
      (event: HttpEvent<any>) => {

        let clone: HttpEvent<any> = event;

        if (event?.type === HttpEventType.Response) {

          const allFound = DevHttpInterceptor._responseInterceptions?.filter(ri => {
            let yes = false;
            if (ri.urlPart instanceof RegExp) {
              yes = ri.urlPart.test(req.url);
            }
            if (typeof ri.urlPart === 'string') {
              yes = req.url?.includes(ri.urlPart);
            }
            return yes;
          });

          if (allFound.length) {
            let body = event.body;

            allFound.forEach(entry => {
              body = (entry?.take > 0) ? (entry?.manipulaterFn(event.body, req) || body) : body;
              if (entry) {
                entry.take -= 1;
              }
            });
            clone = event.clone({ body: body });

          }

        }

        return clone;
      }
    ));
  }

  static addResponseInterception(urlPart: string, manipulaterFn: (body: any, req?: HttpRequest<any>) => any, take = Infinity) {
    this._responseInterceptions.push({urlPart, manipulaterFn, take});
  }

  static addRequestBreakout(urlPart: string, identifyRequestFn: (req?: HttpRequest<any>) => boolean, responseBodyFn: (req?: HttpRequest<any>) => any) {
    this._requestBreakout.push({urlPart, identifyRequestFn, responseBodyFn});
  }

  /**
   * @param urlPart: can be used for quick filtering
   * @param errorMessageResponseFn: can be used for deep down filtering; return falsy if you want request to send to target ressource
   * else return error message
   */
  static addRequestGuard(urlPart: string, identifyRequestFn: (req?: HttpRequest<any>) => boolean, errorMessageResponseFn: (req?: HttpRequest<any>) => string) {
    this._requestGuard.push({urlPart, identifyRequestFn, errorMessageResponseFn})
  }

  static addRequestInterception(urlPart: string, manipulaterFn: (req: HttpRequest<any>) => HttpRequestInterceptionResult, take = Infinity) {
    this._requestInterceptions.push({urlPart, manipulaterFn, take});
  }

  static addSlotsComponentsReassurement(resObj: ReassurmentPageResponseObject, reassurements: SlotsComponentsReassurement[], take = Infinity) {

    // typeCode is necessary but it almost everytime is 'CMSFlexComponent', so it optional for the function call but
    // it needs to be set in the manipulaterFn()

    reassurements?.forEach(rea => {
      rea?.reassuredComponents?.forEach(cmp => {
        cmp.typeCode = cmp.typeCode || 'CMSFlexComponent';
        cmp.name = cmp.name || cmp.flexType;
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        cmp.uuid = cmp.uuid || getRandomString(32);
      });
    });

    const urlPart = '/pages';
    const manipulaterFn: (body: any, req?: HttpRequest<any>) => any = body => {

      const keys = Object.keys(resObj?.destinguishes || {}).filter(key => !!(resObj.destinguishes)[key]);
      const noMatch = keys.map(key => {
        const matcher = typeof (resObj.destinguishes)[key] === 'string' ? new RegExp((resObj.destinguishes)[key]) : (resObj.destinguishes)[key];
        return matcher instanceof RegExp ? matcher.test(body[key]): false;
      }).some(res => res === false);

      // if keys has the length of 0 then every pages response will be checked
      if (keys.length === 0 || !noMatch) {

        // changes?
        if (resObj?.changes && Array.isArray(resObj.changes)) {
          resObj?.changes.forEach(change => {
            let obj = body;
            // manipulates
            if (obj[change.path] !== change.value) {
              console.warn(`changed body's "${change.path}"`, ' from ', obj[change.path], ' to ', change.value);
              obj[change.path] = change.value;
            }
          });
        }

        const slotsRef = body.contentSlots.contentSlot as any[];

        reassurements.forEach(rea => {

          const foundSlot = slotsRef.find(slot => slot.position === rea.position);
          // const addedComponents = rea?.reassuredComponents?.map(cmp => ({...cmp}));
          const now = new Date();
          const addedComponents = rea?.reassuredComponents?.map(cmp => {
            cmp.modifiedTime = now.toISOString();
            return Object.assign({}, cmp);
          });

          const getSlotName = (slot: any): string => {
            return slot.name ? ('"' + slot.name + '"') : ('Slot with position "' + slot.position + '"');
          };
          const getCmpName = (cmp: any): string => {
            return cmp.name ? ('"' + cmp.name + '"') : ('Component with flexType "' + cmp.flexType + '"');
          };

          if (foundSlot) {

            addedComponents.forEach((cmp, i) => {
              const foundCmp = (foundSlot.components?.component as any[])?.find(innerCmp => innerCmp.flexType === cmp.flexType);

              if (!foundCmp) {

                if (!Array.isArray(foundSlot.components.component)) {
                  foundSlot.components = foundSlot.components || { component: [] };
                }

                if (typeof cmp.spliceNumber === 'number') {
                  (foundSlot.components.component as any[]).splice(cmp.spliceNumber, 0, cmp);
                } else {
                  (foundSlot.components.component as any[]).push(cmp);
                }

                console.warn(getCmpName(cmp) + ' added to ' + getSlotName(foundSlot), foundSlot);
              }

            });

          } else {

            const addedSlot = {
              components: {component: [...addedComponents]},
              name: rea.name || rea.position,
              position: rea.position,
              slotShared: false,
              slotId: rea.slotId || rea.position,
              slotUuid: 'intercepted_and_modified_slot'
            };
            slotsRef.push(addedSlot);
            console.warn(getSlotName(addedSlot) + ' not found but added to the slots', slotsRef);
          }

        });
      }
      return body;
    };

    this._responseInterceptions.push({urlPart, manipulaterFn, take});
  }

}

