@if (baseStoreProps$ | async; as props) {

  <div class="over-footer-information">

    <div class="contact-column">
      <h3>{{ 'helpMenu.footerHelpTitle' | cxTranslate }}</h3>
      <div class="info-container">
        <div>
          {{ props.companyName }}
          <div [innerHTML]="props.companyAddress"></div>
        </div>
        <div class="contact-mail-and-telephone">
          <a *ngIf="props.companyWebsite" [href]="'https://' + props.companyWebsite" target="_blank"> {{ props.companyWebsite }}</a>
          <a *ngIf="props.companyEmail" [href]="'mailto:' + props.companyEmail"> {{ props.companyEmail }}</a>
          <span *ngIf="props.companyTelephone"> Tel.: {{ props.companyTelephone }}</span>
        </div>
      </div>
    </div>
    <div class="social-media-container">
      <ul>
        <li *ngIf="props.linkedIn">
          <a [href]="props.linkedIn" target="_blank">
            <img src="/assets/images/social_media/linkedIn-original_blue.svg" alt="linkedIn" class="social-media-icon">
          </a>
        </li>
        <li *ngIf="props.twitter">
          <a [href]="props.twitter" target="_blank">
            <img src="/assets/images/social_media/twitter-original_blue.svg" alt="twitter" class="social-media-icon">
          </a>
        </li>
        <li *ngIf="props.instagram">
          <a [href]="props.instagram" target="_blank">
            <img src="/assets/images/social_media/instagram-original_blue.svg" alt="instagram" class="social-media-icon">
          </a>
        </li>
        <li *ngIf="props.facebook">
          <a [href]="props.facebook" target="_blank">
            <img src="/assets/images/social_media/facebook-original_blue.svg" alt="facebook" class="social-media-icon">
          </a>
        </li>
        <li *ngIf="props.pinterest">
          <a [href]="props.pinterest" target="_blank">
            <img src="/assets/images/social_media/pinterest-original_blue.svg" alt="pinterest" class="social-media-icon">
          </a>
        </li>
        <li *ngIf="props.youtube">
          <a [href]="props.youtube" target="_blank">
            <img src="/assets/images/social_media/youtube-original_blue.svg" alt="youtube" class="social-media-icon">
          </a>
        </li>
      </ul>
    </div>


  </div>

}
