import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StatusCodeHttpInterceptor } from './status-code-http.interceptor';


@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StatusCodeHttpInterceptor,
      multi: true
    },
  ]
})
export class StatusCodeHttpInterceptorModule { }

