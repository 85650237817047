import { NgModule } from '@angular/core';
import { OccConfig, provideConfig } from '@spartacus/core';
import { buildFieldsValueFromObject, FieldsValueObject, getKurzSpecificCartFields, getKurzSpecificOrderEntryFields, getKurzSpecificProductFields } from './custom-occ-endpoints-fields-value-function';


const cartFieldValueObject: FieldsValueObject = { // NOSONAR
  preConfiguredSet: 'FULL',
  fields: [
    {
      name: 'entries',
      fields: [
        {
          name: 'totalPrice',
          fields: ['value']
        },
        {
          name: 'product',
          fields: [
            { name: 'images', preConfiguredSet: 'FULL' },
            { name: 'stock', preConfiguredSet: 'FULL' },
            ...getKurzSpecificProductFields()
          ]
        },
        {
          name: 'basePrice',
          fields: ['value']
        },
        ...getKurzSpecificOrderEntryFields()
      ]
    },
    {
      name: 'totalPrice', fields: ['value']
    },
    'totalItems',
    {
      name: 'totalPriceWithTax', fields: ['value']
    },
    {
      name: 'totalDiscounts', fields: ['value']
    },
    {
      name: 'subTotal', fields: ['value']
    },
    'deliveryItemsQuantity',
    {
      name: 'deliveryCost', fields: ['value']
    },
    {
      name: 'totalTax', fields: ['value']
    },
    ...getKurzSpecificCartFields(),
    'pickupItemsQuantity', 'net',

  ],
};

const cartsFieldValueObject: FieldsValueObject = { // NOSONAR
  fields: [
    {
      name: 'carts',
      preConfiguredSet: 'DEFAULT',
      fields: [
        'potentialProductPromotions',
        'appliedProductPromotions',
        'potentialOrderPromotions',
        'appliedOrderPromotions',
        {
          name: 'entries',
          fields: [
            { name: 'totalPrice', fields: ['currencyIso', 'value'] },
            {
              name: 'product',
              fields: [
                { name: 'images', preConfiguredSet: 'FULL' },
                { name: 'stock', preConfiguredSet: 'FULL' },
                ...getKurzSpecificProductFields()
              ]
            },
            {
              name: 'basePrice', fields: ['currencyIso', 'value']
            },
            ...getKurzSpecificOrderEntryFields()
          ]
        },
        ...getKurzSpecificCartFields(),
        { name: 'totalPrice', fields: ['currencyIso', 'value'] },
        'totalItems',
        { name: 'totalPriceWithTax', fields: ['currencyIso', 'value'] },
        { name: 'totalDiscounts', fields: ['currencyIso', 'value'] },
        { name: 'subTotal', fields: ['currencyIso', 'value'] },
        'deliveryItemsQuantity',
        { name: 'deliveryCost', fields: ['currencyIso', 'value'] },
        { name: 'totalTax', fields: ['currencyIso', 'value'] },
        'pickupItemsQuantity',
        'net',
        'appliedVouchers',
        { name: 'productDiscounts', fields: ['currencyIso', 'value'] },
      ]
    }
  ]
};

const orderDetail: FieldsValueObject = { // NOSONAR
  preConfiguredSet: 'FULL'
};

const productSearchFieldsValueObject: FieldsValueObject = { // NOSONAR
  fields: [
    {
      name: 'products',
      preConfiguredSet: 'FULL',
      fields: [
        'code', 'classifications', 'name', 'summary',
        {
          name: 'price',
          preConfiguredSet: 'FULL'
        },
        {
          name: 'images',
          preConfiguredSet: 'FULL'
        },
        {
          name: 'stock',
          preConfiguredSet: 'FULL'
        },
        ...getKurzSpecificProductFields()
      ]
    },
    'facets', 'breadcrumbs', 'freeTextSearch', 'currentQuery', /*'sapProductHierarchy',*/
    {
      name: 'pagination',
      preConfiguredSet: 'DEFAULT'
    },
    {
      name: 'sorts',
      preConfiguredSet: 'DEFAULT'
    }
  ]
};

const productDetailsFieldsValueObject: FieldsValueObject = { // NOSONAR
  fields: [
    'classifications',
    'technicalSpecification',
    'deinkability',
    'compostability',
    {
      name: 'stock',
      preConfiguredSet: 'DEFAULT'
    },
    'description', 'code', 'url',
    {
      name: 'price',
      preConfiguredSet: 'FULL'
    },
    {
      name: 'categories',
      preConfiguredSet: 'FULL'
    },
    {
      name: 'images',
      preConfiguredSet: 'FULL'
    },
    'sapMaterialGroup',
    'sapProductHierarchy',
    'productReferences',
    ...getKurzSpecificProductFields()
  ]
};

const userFieldsValueObject: FieldsValueObject = { // NOSONAR
  preConfiguredSet: 'FULL',
  fields: [
    {
      name: 'orgUnit',
      preConfiguredSet: 'FULL',
      fields: [
        // 'completeDeliveryAsDefault'
      ]
    }
  ]
};

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    provideConfig({
      backend: {
        occ: {
          endpoints: {
            product: {
              // sonarqube does not like the ${} within a single or double quotation marks for string but this is needed as spartacus simulates to interpret the content of ${} in their code
              details: 'products/${productCode}?fields=' + buildFieldsValueFromObject(productDetailsFieldsValueObject), // NOSONAR
            },
            cart: 'users/${userId}/carts/${cartId}?fields=' + buildFieldsValueFromObject(cartFieldValueObject), // NOSONAR
            carts: 'users/${userId}/carts?fields=' + buildFieldsValueFromObject(cartsFieldValueObject), // NOSONAR
            orderDetail: 'users/${userId}/orders/${orderId}?fields=' + buildFieldsValueFromObject(orderDetail), // NOSONAR
            productSearch: 'products/search?fields=' + buildFieldsValueFromObject(productSearchFieldsValueObject),
            user: 'orgUsers/${userId}?fields=' + buildFieldsValueFromObject(userFieldsValueObject), // NOSONAR
          }
        }
      }
    } as OccConfig)
  ],
  exports: []
})
export class CustomOccEndpointsConfigurationModule {
}
