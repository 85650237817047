import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { ProductVariantsGuard } from '@spartacus/product/variants/components';
import { Observable, of } from 'rxjs';
/**
 * Guard that will redirect to purchasable variant of product, if the navigation
 * is for the non-purchasable one
 */
@Injectable({
  providedIn: 'root',
})
export class CustomProductVariantsGuard extends ProductVariantsGuard {

  override canActivate(activatedRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    // no further checks (if the specific product or another product variant in the list)
    return of(true);
    // if (!productCode) {
    //   return of(true);
    // }
    // return this.productService.get(productCode, ProductScope.VARIANTS).pipe(
    //   filter((p) => !!p),
    //   switchMap((product: Product) => {
    //     if (!product.purchasable) {
    //       const purchasableCode = this.findPurchasableProductCode(product);
    //       if (purchasableCode) {
    //         return this.productService
    //           .get(purchasableCode, ProductScope.LIST)
    //           .pipe(
    //             filter((p) => !!p),
    //             take(1),
    //             map((_product: Product) => {
    //               return this.router.createUrlTree(
    //                 this.semanticPathService.transform({
    //                   cxRoute: 'product',
    //                   params: _product,
    //                 })
    //               );
    //             })
    //           );
    //       }
    //     }
  //       return of(true);
  //     })
  //   );
  }
}
