import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSearchboxComponent } from './custom-searchbox.component';
import { RouterModule } from '@angular/router';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { I18nModule, UrlModule } from '@spartacus/core';



@NgModule({
  declarations: [
    CustomSearchboxComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    IconModule,
    UrlModule,
    I18nModule,
  ],
  exports: [ CustomSearchboxComponent ]
})
export class CustomSearchboxModule { }
