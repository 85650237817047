import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { UtilOmnipresentFormGroupService } from '@util/services/util-omnipresent-form-group.service';
import { Subscription } from 'rxjs';


@Directive({
  selector: '[ifFormGroupValid]'
})
export class IfFormGroupValidDirective implements OnDestroy {

  private formGroupName: string;
  private sub: Subscription;

  @Input()
  set ifFormGroupValid(value: string) {

    this.sub?.unsubscribe();
    this.formGroupName = value;

    if (typeof this.formGroupName === 'string') {

      this.sub = this.utilOmnipresentFormGroupService.getFormGroupError$(this.formGroupName).subscribe(errors => {
        if (errors?.invalid) {
          this.viewContainerRef.clear();
        } else {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      });

    }

  }

  constructor(
      private readonly utilOmnipresentFormGroupService: UtilOmnipresentFormGroupService,
      private readonly viewContainerRef: ViewContainerRef,
      private readonly templateRef: TemplateRef<any>
  ) {}

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

}
