import { NgModule } from '@angular/core';
import { ConfigFactory, provideConfigFactory } from '@spartacus/core';
import { environment } from '../../../environments/environment';



export const getBaseUrlFactory: ConfigFactory = () => {

  const metaEl = document.head.querySelector('meta[name="occ-backend-base-url"]') as HTMLMetaElement;
  const metaBaseUrl = metaEl?.getAttribute('content') || '';
  const foundInMeta = metaBaseUrl.startsWith('http');

  let baseUrl: string;

  if (foundInMeta) {
    baseUrl = metaBaseUrl;
    environment.baseUrl = metaBaseUrl;
  } else {
    baseUrl = environment.baseUrl;
    metaEl?.setAttribute('content', environment.baseUrl);
  }

  console.log('base url found in ' + (foundInMeta ? 'meta tag' : 'environment object'));

  return {
    backend: { occ: { baseUrl } }
  };
};

@NgModule({
  providers: [
    provideConfigFactory(getBaseUrlFactory),
  ],
  exports: []
})
export class CustomBaseUrlModule { }
