

<ng-container *ngIf="user$ | async as user">

  <div class="user-items">

    <div class="user-info">

      <div class="user-name">{{user.name}}</div>
      <div class="user-email">{{user.mail}}</div>

    </div>

    <div class="user-icon">

      <app-icon
        iconType="USER"
        iconSize="EXTRA_LARGE"
        colorType="BASE"
      ></app-icon>

    </div>

  </div>

</ng-container>
