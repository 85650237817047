import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { extractDiscountsAndSurchargesFromEntry } from 'src/app/custom/functions/kurz-cart-entry.functions';
import { KurzCartEntry, KurzDetailedCartEntry } from 'src/app/custom/kurz-components/shared/types/kurz-cart.interface';
import { KurzConfigurationDisplayData } from 'src/app/custom/kurz-components/shared/types/kurz-foil-configuration-list.interface';
import { KurzAreaUnitCode, KurzUnitService } from 'src/app/custom/services/kurz-unit.service';

@Component({
  selector: 'app-custom-mini-cart-overview-entry',
  templateUrl: './custom-mini-cart-overview-entry.component.html',
  styleUrls: ['./custom-mini-cart-overview-entry.component.scss']
})
export class KurzCartSideMenuEntryComponent implements OnInit {

  private _configDataSubject = new BehaviorSubject<KurzConfigurationDisplayData>(null);
  private _secondaryConfigDataSubject = new BehaviorSubject<KurzConfigurationDisplayData>(null);
  private _entrySubject = new BehaviorSubject<KurzCartEntry>(null);

  formattedTotalPrice$ = new BehaviorSubject<string>(null);

  @Input()
  set entry(value: KurzCartEntry) {

    const obj = extractDiscountsAndSurchargesFromEntry(value as KurzDetailedCartEntry);

    const fTotalPrice = this.kurzUnitService.getFormattedPrice(obj.totalPrice, {explicitCurrency: obj.currencyIso});
    this.formattedTotalPrice$.next(fTotalPrice);

    this._configDataSubject.next({
      configType: value.product.productDisplayType === 'graphics' ? 'GRAPHICS' : 'PLASTICS',
      core: value.coreSpecification,
      finishingType: value.finishingType,
      width: value.foilWidth,
      widthUnitCode: (['MMM', 'M2'].includes(value.priceBaseInfo.unit)) ? 'mm' : 'in',
      length: value.foilLength,
      lengthUnitCode: (['MMM', 'M2'].includes(value.priceBaseInfo.unit)) ? 'm' : 'ft'
    });

    this._secondaryConfigDataSubject.next({
      quantity: value.quantity,
      totalArea: value?.foilArea,
      areaUnitCode: value.priceBaseInfo.unit as KurzAreaUnitCode
    });

    this._entrySubject.next(value);
  }

  get entry(): KurzCartEntry {
    return this._entrySubject.value;
  }

  get entry$(): Observable<KurzCartEntry> {
    return this._entrySubject.asObservable();
  }

  get configData$(): Observable<KurzConfigurationDisplayData> {
    return this._configDataSubject.asObservable();
  }

  get secondaryConfigData$(): Observable<KurzConfigurationDisplayData> {
    return this._secondaryConfigDataSubject.asObservable();
  }

  constructor(private kurzUnitService: KurzUnitService) { }

  ngOnInit(): void {
  }

}
