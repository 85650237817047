import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@util/components/icon/icon.module';
import { CustomLanguageSelectorModule } from '../custom-language-selector/custom-language-selector.module';
import { CustomSiteContextSelectorComponent } from './custom-site-context-selector.component';

@NgModule({
  declarations: [
    CustomSiteContextSelectorComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    CustomLanguageSelectorModule
  ],
  exports: [
    CustomSiteContextSelectorComponent
  ]
})
export class CustomSiteContextSelectorModule { }
