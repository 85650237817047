import { Component, Injector, Optional } from '@angular/core';
import { BasicMenuInsertComponent } from '@util/components/menu/basic-menu-insert.component';

@Component({
  selector: 'app-kurz-omnipresent-contact-insert',
  templateUrl: './kurz-omnipresent-contact-insert.component.html',
  styleUrls: ['./kurz-omnipresent-contact-insert.component.scss']
})
export class KurzOmnipresentContactInsertComponent extends BasicMenuInsertComponent {

  constructor(@Optional() injector: Injector) {
    super(injector);
  }

}
