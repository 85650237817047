import { Component, HostListener, Injector, OnInit } from '@angular/core';
import { CustomBasicPanelPartComponent } from '@util/components/panel/custom-title-basic.component';
import { PanelComponent } from '@util/components/panel/panel.component';

@Component({
  selector: 'app-dev-panel-footer',
  templateUrl: './dev-panel-footer.component.html',
  styleUrls: ['./dev-panel-footer.component.scss']
})
export class DevPanelFooterComponent extends CustomBasicPanelPartComponent<{text: string;}> implements OnInit {

  panelComponent: PanelComponent;

  constructor(injector: Injector) {
    super(injector);
  }

  get text(): string {
    return this.data?.injectedData?.text || '';
  }

  ngOnInit(): void {
    this.panelComponent = this.data?.instance;
  }

  @HostListener('click', ['$event'])
  eventKiller(e: UIEvent) {
    e.stopPropagation();
    this.panelComponent.toggle();
  }

}
