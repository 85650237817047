
import { Injectable, inject } from '@angular/core';
import { Config } from '@spartacus/core';
import { fromEvent } from 'rxjs';
import { ObjectTraverser } from '@util/classes/traverser';
import { getUrlOfEndpoint, splitString } from '@util/functions/strings';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { UtilNavigationService } from '@util/services/util-navigation.service';

interface EndpointObject {
  fields: string[],
  params: string[],
  createEndpoint: () => string;
}

@Injectable({
  providedIn: 'root',
})
export class DevConfigService {

  private config = inject(Config);
  private httpClient = inject(HttpClient);
  private utilNavigationService = inject(UtilNavigationService);

  testRequestEndpoint = '/users/current/carts';
  testRequestMethod = 'GET';
  testBody = '{}';

  constructor() {

    fromEvent<KeyboardEvent>(window, 'keyup').subscribe(event => {

      if (['INPUT', 'TEXTAREA'].includes(document?.activeElement?.tagName || 'NO-ACTIVE-EL')) {
        return;
      }

      if (event.key === '?') {
        console.log('SPARTACUS Config', this.config);
      }
      if (event.key === '=') {
        this.sendTestRequest();
      }
      // if (event.key === 'H') {
      //   return this.logHistory();
      // }

    });

  }

  logHistory() {
    const history = this.utilNavigationService['history'].getHistory();
    console.log(history);
  }

  sendTestRequest(endpoint?: string, method?: string, body?: string) {

    let url: string;
    const supportedMethods = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'];
    const bodySupportedMethods = ['POST', 'PUT', 'PATCH'];
    let json: JSON;

    const onSuccessFn = (result: any, jsonBody?: JSON) => {
      const table = {url, endpoint, method };
      console.table(table);
      if (jsonBody) {
        console.log('Body:\n', jsonBody);
        this.testBody = body;
      }
      console.log('Result:\n', result);
      this.testRequestEndpoint = endpoint;
      this.testRequestMethod = method;
    };

    const onErrorFn = (err: any, jsonBody?: JSON) => {
      onSuccessFn(null, jsonBody);
    };

    if (!endpoint) {
      endpoint = prompt('What is the endpoint?', this.testRequestEndpoint);
    }

    if (!method) {
      method = prompt('What is the Method? (' + supportedMethods.join(',') + ')', this.testRequestMethod);
    }

    if (!body && bodySupportedMethods.includes(method)) {
      body = prompt('What is the body (JSON) ?', this.testBody);
    }

    if (body && bodySupportedMethods.includes(method)) {
      try {
        json = JSON.parse(body);
      } catch(e) {
        console.warn(e);
        return;
      }

    }


    if (endpoint && method && (!bodySupportedMethods.includes(method) || json)) {

      url = getUrlOfEndpoint(endpoint);

      if (method === 'GET') {
        this.httpClient.get(url).pipe(take(1)).subscribe({
          next: result => onSuccessFn(result, json),
          error: err => onErrorFn(err, json)
        });
      }

      if (method === 'POST') {
        this.httpClient.post(url, json).pipe(take(1)).subscribe({
          next: result => onSuccessFn(result, json),
          error: err => onErrorFn(err, json)
        });
      }

      if (method === 'PUT') {
        this.httpClient.put(url, json).pipe(take(1)).subscribe({
          next: result => onSuccessFn(result, json),
          error: err => onErrorFn(err, json)
        });
      }

      if (method === 'DELETE') {
        this.httpClient.delete(url).pipe(take(1)).subscribe({
          next: result => onSuccessFn(result, json),
          error: err => onErrorFn(err, json)
        });
      }

      if (method === 'PATCH') {
        this.httpClient.patch(url, json).pipe(take(1)).subscribe({
          next: result => onSuccessFn(result, json),
          error: err => onErrorFn(err, json)
        });
      }

      // TODO - support more methods
      if (!supportedMethods.includes(method)) {
        console.warn('sendTestRequest() does not support "' + method+ '" only ', supportedMethods);
      }
    }

  }

  ensureFieldsInEndpoint(endpointsPath: string, neededFields: {matcher?: string | RegExp, value: string}[]) {

    const endpoint = ObjectTraverser.getValue<string>(this.config?.backend?.occ?.endpoints, endpointsPath, false);
    let changed = false;

    if (endpoint) {

      const endpointObject: EndpointObject = this.createEndpointObject(endpoint);

      neededFields.forEach(nField => {
        let i = -1;

        if (typeof nField.matcher === 'string') {
          nField.matcher = new RegExp(nField.matcher);
        }
        if (!nField.matcher) {
          nField.matcher = new RegExp(nField.value);
        }

        const found = endpointObject.fields?.find((field, index) => {
          if (nField.matcher) {
            const res = (nField.matcher as RegExp).test(field);
            if (res) {
              i = index;
            }
            return res;
          }
        });

        if (found) {
          if (endpointObject.fields[i] !== nField.value) {
            endpointObject.fields.splice(i, 1, nField.value);
            console.warn(`replaced the '${endpointObject.fields[i]}' field with '${nField.value}' in the endpoint of ${endpointsPath}`);
            changed = true;
          }
        } else {
          endpointObject.fields.push(nField.value);
          console.warn(`added the field '${nField.value}' to the endpoint of ${endpointsPath}`);
          changed = true;
        }
      });

      if (changed) {
        const newEndpoint = endpointObject.createEndpoint();
        ObjectTraverser.setValue(this.config.backend.occ.endpoints, endpointsPath, newEndpoint);
      }

    }

  }

  private createEndpointObject(endpoint: string): EndpointObject {

    const qmPos = endpoint.indexOf('?') + 1;
    const preQuery = endpoint.slice(0, qmPos);
    const query = endpoint.slice(qmPos);
    const params = query.split('&');
    const fieldParam = params.find(param => param.startsWith('fields'));

    const fieldsValue = fieldParam.split('=').pop();
    const fields = splitString(fieldsValue, ',', ['(', ')']);

    const fieldsEndpointObject: EndpointObject = {
      fields,
      params,
      createEndpoint: (): string => {
        const newFieldParam = 'fields=' + fields.join(',');
        const newParams = params.map(param => {
          if (param.startsWith('fields')) {
            return newFieldParam;
          } else {
            return param;
          }
        });
        const newQuery = newParams.join('&');
        return preQuery + newQuery;
      }
    };

    return fieldsEndpointObject;
  }

}
